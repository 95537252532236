<template>
  <div
    class="fs-unmask standard-wizard__step standard-wizard__step--personal-information"
    data-test="COB-personalInformationStep">
    <bubble-card>
      <template #card-title>
        <h5 data-test="personalInfoHeaderLabel">
          Personal Information
        </h5>
      </template>

      <div class="standard-wizard__step__form">
        <ssn-input
          v-model="stepData.ssn"
          :disabled="processing"
          label="Social Security Number"
          :verify="verify_ssn"
          class="ssn-input"
          :paste-prevent="true"
          required />

        <ssn-input
          v-model="verify_ssn"
          :disabled="processing"
          label="Verify Social Security Number"
          :verify="stepData.ssn"
          class="ssn-input"
          :paste-prevent="true"
          required />

        <date-input
          v-model="stepData.dob"
          :disabled="processing"
          enforce-dob-check
          label="Date of Birth"
          class="date-input"
          :max="maxDate"
          :min-age="minDob"
          :verify="verify_date"
          :paste-prevent="true"
          required />

        <date-input
          v-model="verify_date"
          :disabled="processing"
          enforce-dob-check
          label="Verify Date of Birth"
          class="date-input"
          :max="maxDate"
          :min-age="minDob"
          :verify="stepData.dob"
          :paste-prevent="true"
          required />

        <pin-input
          v-model="stepData.security_pin"
          :disabled="processing"
          :label="pinInputlabel" />

        <div class="standard-consent-and-input-notation-text" data-test="byClickingLabel">
          By clicking Continue, you attest that the information you are providing is true and
          correct to the best of your knowledge. We reserve the right to verify any information
          provided in this application, including by requesting and obtaining data from third
          parties.
        </div>

        <custom-button
          :disabled="isDisabled"
          full-width
          data-test="continueBtn"
          @click="processApplication">
          Continue
        </custom-button>
      </div>
    </bubble-card>

    <complete-later-button />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import PinInput from '@/components/Inputs/Pin.vue';
import DateInput from '@/components/Inputs/Date.vue';
import SsnInput from '@/components/Inputs/Ssn.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import CreditEngineCheckMixin from '@/mixins/Consumer/LoanApply/CreditEngineCheckMixin';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import validateDate from '@/validators/date';
import securityPin from '@/validators/securityPin';
import validateSsn from '@/validators/ssn';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { saveSensitive } from '@/api/consumer';
import { dateToFormatIso } from '@/helpers/DateTimeHelper';
import DATE_CONSTANTS from '@/constants/DateConstants';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { defineComponent } from 'vue';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import GetProcessing from '@/mixins/GetProcessing';
import GetErrors from '@/mixins/GetErrors';

export default defineComponent({
  name: 'PersonalInformationStep',
  components: {
    BubbleCard,
    SsnInput,
    DateInput,
    PinInput,
    CustomButton,
    CompleteLaterButton,
  },
  mixins: [
    GetErrors,
    GetProcessing,
    CreditEngineCheckMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
    IdleTimeoutMixin,
    HoneypotTrackMixin,
  ],
  data() {
    const stepData: LoanApplyDataInterface = {
      ssn: '',
      dob: '',
      security_pin: '',
    };
    return {
      disabled: false,
      verify_ssn: '',
      verify_date: '',
      stepData,
      pinInputlabel: 'Please create a Four-digit PIN and remember this number. '
        + 'To protect your data, we will use this PIN to verify your identity when you '
        + 'call with questions about your account.',
      ownStep: 6,
      hpEventName: 'Personal Information Page Visit',
      hpStep: 6,
    };
  },
  computed: {
    maxDate() {
      return moment().format(DATE_CONSTANTS.dateFormat);
    },
    consentTypes(): Array<number> {
      return [ConsentTypesEnum.ATTESTATION_OF_APPLICATION_INFORMATION];
    },
    minDob(): number {
      const state = get(this.$store.getters['Consumer/getLoanApplyWizardData'], 'state');

      if (!isEmpty(state) && (state === 'AL' || state === 'NE')) {
        return 19;
      }
      return 18;
    },
    isSsnValid(): boolean {
      return validateSsn(this.stepData.ssn!);
    },
    isDobValid(): boolean {
      const opts = {
        maxDate: this.maxDate,
        dob: true,
        minDob: this.minDob,
      };

      return validateDate(this.stepData.dob, opts).isValid;
    },
    isPinValid(): boolean {
      return securityPin(this.stepData.security_pin!);
    },
    isDisabled(): boolean {
      return !this.isSsnValid
        || !this.isDobValid
        || !this.isPinValid
        || this.stepData.ssn !== this.verify_ssn
        || this.stepData.dob !== this.verify_date
        || this.disabled;
    },
    applicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
  },
  mounted() {
    const {
      ssn = '',
      dob = '',
    } = this.$store.getters['Consumer/getLoanApplyWizardData'];

    this.stepData.ssn = ssn;
    this.stepData.dob = dob;

    this.presentConsent();

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    onSecurityPinUpdated(val: any): void {
      this.stepData.security_pin = val;
    },
    async nextStep() {
      this.goToStep(this.ownStep + 1);
    },
    async processApplication() {
      this.disabled = true;
      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);
      const responseStatusSaveSensitive = await this.sendCustomerSensitive();
      await this.updateConsents();

      if (responseStatusSaveSensitive === 201) {
        await this.nextStep();
      }

      this.disabled = false;
    },
    async sendCustomerSensitive(): Promise<number> {
      if (!this.stepData.dob) return Promise.reject();

      const payload = {
        ssn: this.stepData.ssn,
        dob: dateToFormatIso(this.stepData.dob),
        security_pin: this.stepData.security_pin,
      };
      try {
        const { status } = await saveSensitive(this.applicationId, payload);
        return status;
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
        return Promise.reject();
      }
    },
    presentConsent() {
      const consumer_application_uuid = this.applicationId;

      return postConsentTypes({
        consentTypes: this.consentTypes,
        consumer_application_uuid,
        entity: ConsentEntityTypes.CONSUMER,
      });
    },
    async updateConsents(): Promise<number> {
      return patchAvailableConsents(
        this.consentTypes,
        this.applicationId,
        ConsentEntityTypes.CONSUMER,
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom-transitions";
@import "@/assets/scss/standard-wizard";

.household-income {
  :deep(.standard-input-field) {
    margin-bottom: .5rem;
  }

  :deep(.currency-input) {
    margin-bottom: 2rem !important;
  }
}

.ssn-input, .date-input {
  margin-bottom: 0.625rem !important;
}

.custom-button {
  margin-top: 1rem;
}

.standard-consent-and-input-notation-text {
  color: var(--grayscale-color-1);
}
</style>
