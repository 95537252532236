<template>
  <div class="sidebar-wrapper">
    <div class="sidebar-overlay" @click="onClose" />

    <div class="sidebar" :class="{ open: isOpen }">
      <div class="sidebar--header">
        <h4>Rate Sheet and Offer Code Optimization</h4>

        <v-btn
          icon="mdi-close"
          size="small"
          variant="plain"
          class="close-button"
          color="var(--grayscale-color-1)"
          aria-label="Close"
          data-test="closeIcon"
          @click="onClose" />
      </div>

      <div v-if="showIntro" class="sidebar--intro">
        <div class="icon-text">
          <div class="icon">
            <InvoiceIcon />
          </div>
          <div>
            <p>
              Loan offers are ranked to provide diverse options to consumers,
              improving your approval rate.
            </p>

            <p>
              Based on current Rate Sheet and Offer Code settings, some loan
              products won't be offered. <br />
              Can we fix this for you?
            </p>
          </div>
        </div>
        <CustomButton full-width @click="showIntro = false">
          Improve My Rate Sheet And Offer Codes
        </CustomButton>
      </div>

      <div v-else class="sidebar--content">
        <template v-for="(suggestion, idx) in suggestions">
          <OptimizationSuggestion
            v-if="!suggestion.accepted"
            :key="idx"
            :suggestion="suggestion"
            @success="acceptSuggestion(suggestion)"
            @hide="removeSuggestion(suggestion)" />
          <CustomAlert
            v-else
            :key="`success-${idx}`"
            class="mb-4"
            type="success">
            Done! These changes have been saved.
          </CustomAlert>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/*
  Using setTimeout here to make sure the sidebar is mounted before it slides in to achieve the transition effect.
  Since the parent has to use a v-if to conditionally render the sidebar, the sidebar is not mounted
  immediately.
*/
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import InvoiceIcon from '@/components/Icons/InvoiceIcon.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { RateSheetOptimizationSuggestion } from '@/interfaces/rates/RateSheetOptimization';
import OptimizationSuggestion from './OptimizationSuggestion.vue';

const store = useStore();
const emit = defineEmits(['close', 'suggestionAccepted']);
const isOpen = ref(false);
const showIntro = ref(true);

const suggestions = ref<RateSheetOptimizationSuggestion[]>([]);

onMounted(() => {
  suggestions.value = store
    .getters['MerchantPortal/getRateSheetOptimizationSuggestions']
    .map((suggestion: RateSheetOptimizationSuggestion) => ({ ...suggestion }));

  setTimeout(() => {
    isOpen.value = true;
  }, 0);
});

function removeSuggestion(suggestion: RateSheetOptimizationSuggestion) {
  const idx = suggestions.value.findIndex(s => s === suggestion);
  suggestions.value.splice(idx, 1);
}

function acceptSuggestion(suggestion: RateSheetOptimizationSuggestion) {
  suggestion.accepted = true;
  if (suggestion.offer_code) {
    store.dispatch('MerchantPortal/fetchOfferCodes');
  } else {
    // The parent component will handle the rate sheet refresh
    emit('suggestionAccepted', suggestion);
  }
}

function onClose() {
  isOpen.value = false;
  setTimeout(() => emit('close'), 200); // 200 needed here otherwise it would disappear before transition ends
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/font-variables.scss';
@import '@/assets/scss/mixins/media_queries';

.sidebar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 999;
  }

}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 28.125rem; // 450px
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--grayscale-color-4);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  &.open {
    transform: translateX(0);
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    background-color: var(--grayscale-color-5);

    h4 {
      margin: 0;
      color: var(--grayscale-color-1);
      font-family: $font-family-serif;
      font-weight: 700;
      font-size: 1.125rem;
    }

    .close-button {
      width: 1.5rem;
      height: 1.5rem;
      background: none;
      border: none;
      cursor: pointer;
      color: var(--grayscale-color-1);
      font-size: 1.5rem;
      opacity: 1;
    }
  }

  &--intro {
    padding: 1rem;
    background-color: #FFFFFF;

    .icon-text {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .icon {
        margin-right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    p {
      font-size: 1rem;
    }
  }

  &--content {
    padding: 1rem;
  }
}

@include mobile {
  .sidebar-wrapper {
    .sidebar {
      &--intro {
        .v-btn.button-primary.custom-button {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>
