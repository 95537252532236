<template>
  <div class="fs-unmask standard-wizard__step" data-test="COB-employmentInformationStep">
    <bubble-card>
      <template #card-title>
        <h5 data-test="employmentInfoHeaderLabel">
          Employment Information
        </h5>
      </template>

      <transition mode="out-in" name="slide">
        <div v-if="stepData.employment_information">
          <select-input
            v-model="stepData.employment_information.employment_status"
            :disabled="processing"
            :items="isNewEmploymentFlowEnabled ? employmentStatuses : oldEmploymentStatuses"
            label="Employment Status"
            data-test="employmentStatusField"
            required />

          <div v-if="employmentStatusEmployed">
            <select-input
              v-model="stepData.employment_information.employment_length"
              :disabled="processing"
              :items="employmentLength"
              label="Length of Employment"
              data-test="employmentLengthField"
              :required="isRequiredField" />

            <v-row>
              <v-col cols="8">
                <phone-input
                  v-model="stepData.employment_information.phone_number"
                  show-icon
                  :disabled="processing"
                  :error-messages="errors.phone_number"
                  label="Employer Phone Number"
                  :required="isRequiredField" />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="stepData.employment_information.extension"
                  :disabled="processing"
                  inputmode="numeric"
                  label="Ext."
                  data-test="extInput"
                  variant="outlined" />
              </v-col>
            </v-row>
          </div>

          <div v-if="employmentStatusSelfEmployed">
            <select-input
              v-model="stepData.employment_information.employment_length"
              :disabled="processing"
              :items="employmentLength"
              label="Years In Business"
              :required="isRequiredField" />

            <v-row>
              <v-col cols="8">
                <phone-input
                  v-model="stepData.employment_information.phone_number"
                  :disabled="processing"
                  :error-messages="errors.phone_number"
                  label="Business Phone Number"
                  :required="isRequiredField" />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="stepData.employment_information.extension"
                  :disabled="processing"
                  data-test="extensionInput"
                  label="Ext."
                  variant="outlined" />
              </v-col>
            </v-row>
          </div>

          <annual-gross-input
            v-if="!isNewEmploymentFlowEnabled"
            v-model="stepData.employment_information.gross_income"
            :disabled="processing"
            hint="Alimony, child support, or separate maintenance income need not be revealed if
            you do not wish to have it considered as a basis for repaying this loan obligation."
            class="household-income"
            initial-modal
            show-icon />

          <custom-button
            :disabled="isDisabled"
            full-width
            data-test="continueBtn"
            @click="processApplication">
            Continue
          </custom-button>
        </div>
      </transition>
    </bubble-card>

    <complete-later-button />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import AnnualGrossInput from '@/components/Consumer/LoanApplyWizard/AnnualGrossInput/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import CreditEngineCheckMixin from '@/mixins/Consumer/LoanApply/CreditEngineCheckMixin';
import SelectInput from '@/components/Inputs/Select.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import EmploymentStatus from '@/enums/Consumer/Employment';
import phoneNumberValidator from '@/validators/phone_number';
import { defineComponent } from 'vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import GetProcessing from '@/mixins/GetProcessing';
import GetErrors from '@/mixins/GetErrors';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'EmploymentInformationStep',

  components: {
    BubbleCard,
    AnnualGrossInput,
    CustomButton,
    SelectInput,
    PhoneInput,
    CompleteLaterButton,
  },

  mixins: [
    GetErrors,
    GetProcessing,
    CreditEngineCheckMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
    FeatureFlagsMixin,
  ],

  data() {
    const stepData: LoanApplyDataInterface = {
      employment_information: {
        employment_status: null,
        employment_length: null,
        phone_number: '',
        extension: '',
        gross_income: '',
      },
    };
    return {
      disabled: false,
      verify_ssn: '',
      verify_date: '',
      stepData,
      pinInputlabel: 'Please create a Four-digit PIN and remember this number. '
        + 'To protect your data, we will use this PIN to verify your identity when you '
        + 'call with questions about your account.',
      ownStep: 7,
      hpEventName: 'Employment Information Page Visit',
      hpStep: 7,
      employmentStatuses: [
        EmploymentStatus.SALARIED,
        EmploymentStatus.HOURLY,
        EmploymentStatus.SELF_EMPLOYED,
        EmploymentStatus.RETIRED,
        EmploymentStatus.UNEMPLOYED,
        EmploymentStatus.OTHER,
      ],
      oldEmploymentStatuses: [
        EmploymentStatus.EMPLOYED,
        EmploymentStatus.SELF_EMPLOYED,
        EmploymentStatus.RETIRED,
        EmploymentStatus.UNEMPLOYED,
        EmploymentStatus.OTHER,
      ],
      phoneNumberRequiredStatuses: [
        EmploymentStatus.SALARIED,
        EmploymentStatus.HOURLY,
        EmploymentStatus.SELF_EMPLOYED,
      ],
      employmentLength: [
        'Less than 1 year',
        '1-3 years',
        '3-5 years',
        'Greater than 5 years',
      ],
    };
  },
  computed: {
    isGrossIncomeValid(): boolean {
      return Number(this.stepData.employment_information?.gross_income) > 0;
    },

    isRequiredField(): boolean {
      return [EmploymentStatus.SALARIED, EmploymentStatus.HOURLY, EmploymentStatus.SELF_EMPLOYED, EmploymentStatus.EMPLOYED]
        .includes(this.stepData.employment_information?.employment_status as EmploymentStatus);
    },

    isEmploymentInfoValid(): any {
      return this.isRequiredField
        ? (this.stepData.employment_information?.employment_length
          && this.stepData.employment_information?.phone_number)
        : true;
    },

    isValidPhoneNumber(): boolean {
      return this.phoneNumberRequiredStatuses
        .includes(this.stepData.employment_information?.employment_status as EmploymentStatus)
        ? phoneNumberValidator(this.stepData.employment_information?.phone_number as string)
        : true;
    },

    isDisabled(): boolean {
      return (!this.isNewEmploymentFlowEnabled && !this.isGrossIncomeValid)
        || this.disabled
        || !this.stepData.employment_information?.employment_status
        || !this.isEmploymentInfoValid
        || !this.isValidPhoneNumber;
    },

    employmentStatus() {
      return this.stepData.employment_information?.employment_status;
    },

    employmentStatusEmployed(): boolean {
      const employmentStatus = this.stepData.employment_information?.employment_status;
      return employmentStatus === EmploymentStatus.SALARIED || employmentStatus === EmploymentStatus.HOURLY
        || employmentStatus === EmploymentStatus.EMPLOYED;
    },

    employmentStatusSelfEmployed(): boolean {
      return this.stepData.employment_information?.employment_status
        === EmploymentStatus.SELF_EMPLOYED;
    },

    isNewEmploymentFlowEnabled() {
      return this.isFeatureEnabled(FeatureFlagsConstants.ENABLE_NEW_EMPLOYMENT_FLOW);
    },
  },

  watch: {
    employmentStatus: {
      handler(val: any) {
        if (val && this.stepData.employment_information) {
          this.stepData.employment_information.employment_length = null;
          this.stepData.employment_information.phone_number = '';
          this.stepData.employment_information.extension = '';
        }
      },
    },
  },

  mounted() {
    const {
      employment_information,
    } = this.$store.getters['Consumer/getLoanApplyWizardData'];
    if (this.stepData.employment_information) {
      this.stepData.employment_information.gross_income = employment_information?.annual_income;
    }

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },

  methods: {
    async nextStep() {
      // Feature Flag - Temporary change - if 'enable_new_employment_flow' FF is OFF,
      // we want to skip the next step (individual income) and send user to 'please review' step
      if (!this.isNewEmploymentFlowEnabled) {
        this.goToStep(this.ownStep + 2);
      } else {
        this.goToStep(this.ownStep + 1);
      }
    },

    async setEmploymentInfo(): Promise<number> {
      const { status } = await this.$store.dispatch(
        'Consumer/setEmploymentInformation',
        this.stepData.employment_information,
      );
      return status;
    },

    async processApplication() {
      this.disabled = true;

      const responseStatusEmploymentInfo = await this.setEmploymentInfo();
      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);

      if (responseStatusEmploymentInfo === 200) {
        await this.nextStep();
      } else {
        this.disabled = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom-transitions";
@import '@/assets/scss/mixins/media_queries';
@import "@/assets/scss/standard-wizard";

:deep(.household-income) {
  .standard-input-field {
    margin-bottom: .5rem;
  }
  .currency-input {
    margin-bottom: 2rem !important;
  }
}

.ssn-input, .date-input {
  margin-bottom: 0.625rem !important;
}

.standard-wizard {
  &__step {
    @include standard-wizard-form-inline-split;
  }
}

.standard-input-field.standard-input-otp {
  padding: 0;
  border: none;
  margin-bottom: 0;
}

.custom-button.standard-toggle-edit-form-button.v-btn.v-btn--has-bg.v-theme--light.v-size--default {
  &.button-primary.button-large.standard-toggle-edit-form-button--inactive {
    background-color: white;
    color: black;
  }
}

.edit-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

:deep(.edit-btn-wrapper button:hover) {
  background-color: unset !important;
}

.submit-my-application {
  display: block;
  margin: 3.25rem auto 0;
  max-width: 29.25rem;

  @include tablet {
    max-width: 26.25rem;
  }
}

.card-text__info-group__title {
  font-size: 1rem;
}

.income-input :deep(.standard-input-field__input-group) {
  height: 2.7rem;
}

:deep(.no-underline) {
  .v-input__control::before {
    border: 0 !important;
  }

  .v-label--active {
    color: var(--grayscale-color-1) !important;
  }

  .v-input--dirty, .v-input--is-focused.primary--text {
    color: transparent !important;
  }
}
</style>
