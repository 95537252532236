<template>
  <v-card
    :elevation="elevation"
    class="bubble-card"
    :class="customClass"
    :max-width="maxWidth"
    :ripple="false">
    <v-card-title :class="computedTitleClass">
      <slot name="card-title" />
    </v-card-title>
    <v-card-text class="bubble-card__content">
      <slot />
    </v-card-text>
    <v-card-actions v-if="$slots['card-actions']" class="bubble-card__actions">
      <slot name="card-actions" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BubbleCard',
  props: {
    cardColor: { type: String, default: 'teal' },
    customClass: { type: String, default: '' },
    elevation: { type: Number, default: 5 },
    maxWidth: { type: String, default: null },
  },

  computed: {
    computedTitleClass(): string {
      return `bubble-card__title bubble-card__title--${this.cardColor}`;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import '@/assets/scss/mixins/media_queries';

a.bubble-card, a.bubble-card:focus {
  color: transparent !important;
  background-color: transparent !important;
  outline: none !important;
}

.bubble-card {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 1rem !important;
  max-width: 29.25rem;
  min-height: 5rem;
  margin-bottom: 2.5rem;

  @include tablet {
    max-width: 26.25rem;
  }

  &::before {
    border-radius: inherit;
  }

  &__content {
    overflow: hidden;
    padding: 1rem !important;
  }

  &__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 3rem;
  }
}

:deep(.bubble-card__title) {
  display: flex;
  text-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5rem;
  padding: 0.5rem 1rem;
  background: red;

  h5 {
    margin-bottom: 0 !important;
    margin-top: 0;
    animation: fadeIn 0.3s;
  }

  .bubble-card--welcome & {
    justify-content: center;
    padding: 1rem;
    background: $dark-blue;

    h1 {
      padding: 0;
      font-size: 2rem;
      line-height: 1.2;
      color: $white;
    }
  }
}

:deep(.bubble-card__title--peach) {
  background: $momnt-soft-peach;

  h5 {
    color: $momnt-peach;
  }
}

:deep(.bubble-card__title--teal) {
  background: $momnt-soft-teal;

  h5 {
    color: $momnt-turquoise;
  }
}

:deep(.bubble-card__title--navy) {
  background: $momnt-soft-navy;

  h5 {
    color: var(--secondary-color);
  }
}
</style>
