<template>
  <div class="removal-suggestion" :class="{ loading }">
    <v-progress-circular
      v-if="loading"
      size="64"
      indeterminate
      color="var(--grayscale-color-1)" />

    <template v-else>
      <p>
        More than {{ suggestion.max_visible_in_category }} products are active in
        the <strong>{{ suggestion.group }}</strong> group in {{ location }}.
      </p>
      <p>
        These products will not be offered to qualifying customers:
        <ul>
          <li v-for="product in suggestion.products" :key="product.id">
            {{ product.name }}
          </li>
        </ul>
      </p>

      <p>Would You like to remove these products?</p>

      <CustomAlert
        v-if="error"
        type="error"
        class="mb-4">
        This failed due to a technical error. Please try again.
      </CustomAlert>

      <CustomButton
        full-width
        size="small"
        class="mb-2"
        @click="removeProducts">
        Remove
      </CustomButton>

      <CustomButton
        full-width
        size="small"
        variant="text"
        @click="emit('hide')">
        Decline This Recommendation
      </CustomButton>
    </template>
  </div>
</template>

<script setup lang="ts">
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { RateSheetOptimizationSuggestion } from '@/interfaces/rates/RateSheetOptimization';
import { useMerchantRateSheet } from '@/composables/useMerchantRateSheet';
import { computed, ref } from 'vue';

const props = defineProps({
  suggestion: {
    type: Object as () => RateSheetOptimizationSuggestion,
    required: true,
  },
});

const loading = ref(false);
const error = ref(false);

const emit = defineEmits(['hide', 'success']);

const location = computed(() => {
  return props.suggestion.offer_code ? `offer code ${props.suggestion.offer_code.name}` : 'your Rate Sheet';
});

const { acceptSuggestion } = useMerchantRateSheet();

async function removeProducts() {
  loading.value = true;
  try {
    await acceptSuggestion(props.suggestion);
    emit('success');
  } catch (_) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}
</script>

<style lang="scss" scoped>
.removal-suggestion {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  min-height: 17rem;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style-type: disc;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
}
</style>
