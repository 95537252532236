import axios, { AxiosResponse } from 'axios';
import {
  CreateCPAccountResponse,
  PortalOnboardingDetailsResponse,
} from '@/interfaces/consumer/loanApply/PortalOnboardingInterface';
import { StoreConsumerAddress } from '@/interfaces/consumer/loanApply/Address';
import {
  AXIOS_CONFIG, BASE_PATH, instance, rawClient,
} from './index';

const CONSUMER_PATH = `${BASE_PATH}/consumer`;

export function otpRegister(payload: any) {
  return rawClient.post(`${CONSUMER_PATH}/register/`, payload);
}

/**
 * We pass the authentication JWT token if it exists so that we can get the information
 * if the token is valid, the user is logged in and the user matches the application.
*/
export function getInvitation(invitationId: string) {
  const token = localStorage.getItem('token');
  const auth = token ? `JWT ${token}` : '';
  return axios.get(`${CONSUMER_PATH}/${invitationId}/invitation/`, AXIOS_CONFIG(auth));
}

export function saveSensitive(appId: string, payload: any) {
  return instance.post(`${CONSUMER_PATH}/${appId}/sensitive/`, payload);
}

export function fraudCheck(appId: string, payload: {deviceSessionID: string }) {
  return instance.post(`${CONSUMER_PATH}/${appId}/fraud_check/`, payload);
}

export function onboard(invitationId: string, payload: any) {
  return axios.post(`${CONSUMER_PATH}/${invitationId}/onboard/`, payload);
}

export function saveAllocationDecision(appId: string, payload: any) {
  return instance.post(`${CONSUMER_PATH}/${appId}/allocation_decision/`, payload);
}

export function saveSelectedLoan(appId: string) {
  return instance.post(`${CONSUMER_PATH}/${appId}/save_selected_loan/`);
}

export function saveBasicInfo(appId: string, payload: any) {
  return instance.patch(`${CONSUMER_PATH}/${appId}/basic-info/`, payload);
}

export function saveAddress(appId: string, payload: StoreConsumerAddress) {
  return instance.post(`${CONSUMER_PATH}/${appId}/address/`, payload);
}

export function getApprovedLoans(appId: string, skipEmail: boolean = false) {
  return instance.get(`${CONSUMER_PATH}/${appId}/approved_loans/`, { params: { skip_email: skipEmail } });
}

export function cancelApplication(appId: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/cancel-application/`);
}

export function declineApplication(appId: string) {
  return instance.post(`${CONSUMER_PATH}/${appId}/decline-application/`);
}

export function creditEnginePull(appId: string, pullType: string) {
  return instance.post(`${CONSUMER_PATH}/${appId}/credit-engine/${pullType}/`);
}

export function getLoanAgreement(appId: string) {
  const path = `${CONSUMER_PATH}/documents/${appId}/loan_agreement/`;
  return instance.get(path, { responseType: 'blob' });
}

export function approveAndAccept(appId: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/approved-accepted/`);
}

export function checkAvailableLenders(appId: string, params: any) {
  return instance.get(`${CONSUMER_PATH}/${appId}/check-available-lenders/`, { params });
}

export function getReEntry(appId: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/re-entry/`);
}

export function getApplication(appId: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/app/`);
}

export function reEntryVerify(invitationId: string, payload: any) {
  return axios.post(`${CONSUMER_PATH}/${invitationId}/re-entry-verify/`, payload);
}

export function updateInfo(appId: string, payload: any) {
  return instance.patch(`${CONSUMER_PATH}/${appId}/update-info/`, payload);
}

export function saveIdSensitive(appId: string, payload: any) {
  return instance.patch(`${CONSUMER_PATH}/${appId}/update-ID-sensitive/`, payload);
}

export function getAvailableIdTypes() {
  return instance.get(`${BASE_PATH}/sensitive/id-types/`);
}

export function getAllActiveLenders() {
  return instance.get(`${BASE_PATH}/lender/addresses/`);
}

export function getInvitePortal(appId: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/invite-portal/`);
}

export function getCPOnboardDetails(appId: string):
  Promise<AxiosResponse<PortalOnboardingDetailsResponse>> {
  return instance.get(`${CONSUMER_PATH}/${appId}/onboarding-details/`);
}

export function createCPAccount(appId: string, password: string):
  Promise<AxiosResponse<CreateCPAccountResponse>> {
  return instance.post(`${CONSUMER_PATH}/${appId}/password/`, { password });
}

export function getLoanStacking(appId: string) {
  return instance.get(`${BASE_PATH}/fraud/${appId}/loan-stacking/`);
}

export function setAnnualIncome(appId: string, payload: number) {
  return instance.post(`${CONSUMER_PATH}/${appId}/annual_income/`, { annual_income: payload });
}

export function setEmploymentInformation(appId: string, payload: any) {
  return instance.post(`${CONSUMER_PATH}/${appId}/employment_information/`, payload);
}

export function getEmploymentInformation(appId?: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/employment_information/`);
}

export function startDocumentVerification(appId: string, payload: { redirect_type?: string } = {}) {
  const url = `${BASE_PATH}/fraud/consumer/${appId}/document-verification/`;
  return instance.post(url, payload);
}

export function getDocumentVerificationStatusDetail(appId: string) {
  return instance.get(`${CONSUMER_PATH}/${appId}/status-detail/`);
}

export function skipDocumentVerification(appId: string) {
  return instance.get(`${BASE_PATH}/fraud/consumer/${appId}/skip-document-verification/`);
}
