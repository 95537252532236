<template>
  <v-row
    class="fs-unmask header-link-container"
    no-gutters
    :class="{ 'navigation-align': isUserAdmin }">
    <custom-button
      v-for="(item, index) in merchantHeaderItems"
      :key="index"
      class="header-link"
      :disabled="!item.to"
      :data-test="getDataTestAttribute(item.to)"
      :to="getRouteData(item.to)"
      variant="link">
      <div v-if="item.name === 'Admin' && cannotTransact">
        <v-badge
          location="left"
          class="warning-badge"
          color="var(--error-color)"
          icon="mdi-exclamation-thick">
          {{ item.name }}
        </v-badge>
      </div>
      <div v-else-if="item.name === 'Rate Sheet' && hasRSOptimizationSuggestions">
        <v-badge
          location="left"
          class="warning-badge rate-sheet-badge"
          color="var(--error-color)"
          icon="mdi-exclamation-thick">
          {{ item.name }}
        </v-badge>
      </div>
      <div v-else>
        {{ item.name }}
      </div>
    </custom-button>
    <thinkific-link class="header-link" />
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import PortalHeaderActionsMixin from '@/mixins/PortalHeaderActionsMixin';
import ThinkificLink from '@/components/Merchant/Portal/Header/ThinkificLink.vue';
import SharedUserInfoMixin from '@/components/Merchant/Portal/Header/SharedUserInfoMixin';

export default defineComponent({
  name: 'HeaderActions',
  components: { ThinkificLink, CustomButton },
  mixins: [PortalHeaderActionsMixin, SharedUserInfoMixin],
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/custom-badge";

.header-link-container {
  flex-wrap: nowrap;

  .header-link {
    margin-right: .75rem;

    &.v-btn--active:not(.v-btn--disabled) {
      color: var(--grayscale-color-1) !important;
      background-color: var(--grayscale-color-3);
    }
  }
}

:deep(.v-theme--light.v-btn.custom-button.button-large:hover) {
  box-shadow: none;
}

.navigation-align {
  justify-content: space-between;
}
</style>
