import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45aabad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aan-address" }
const _hoisted_2 = { class: "mb-0 font-italic" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_fs_exclude = _resolveDirective("fs-exclude")!

  return (_openBlock(), _createElementBlock("address", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.brandData.brand_name), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceAddress, (address, i) => {
      return (_openBlock(), _createElementBlock("p", {
        key: i,
        class: "mb-0 font-italic"
      }, [
        _createElementVNode("strong", null, _toDisplayString(address), 1)
      ]))
    }), 128)),
    _createElementVNode("p", _hoisted_3, [
      _withDirectives((_openBlock(), _createElementBlock("a", {
        href: `mailto:${_ctx.brandData.footer_object.support_email}`,
        class: "aan-address__link"
      }, [
        _createTextVNode(_toDisplayString(_ctx.brandData.footer_object.support_email), 1)
      ], 8, _hoisted_4)), [
        [_directive_fs_exclude]
      ])
    ]),
    _createElementVNode("p", null, [
      _withDirectives((_openBlock(), _createElementBlock("a", {
        href: `tel:${_ctx.brandData.footer_object.support_number}`,
        class: "aan-address__link"
      }, [
        _createTextVNode(_toDisplayString(_ctx.brandData.footer_object.support_number), 1)
      ], 8, _hoisted_5)), [
        [_directive_fs_exclude]
      ])
    ])
  ]))
}