import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { ConsumerStateInterface } from '@/interfaces/states/ConsumerStateInterface';
import { get, toLower } from 'lodash';
import { ActionContext } from 'vuex';
import { StoreActionInterface } from '@/interfaces/StoreActionResponse';
import * as consumerApi from '@/api/consumer';
import { getAxiosError } from '@/api/utils';
import { approveAndAccept, getLoanStacking } from '@/api/consumer';
import { EmploymentInformation } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { clearAuthLocalStorage } from '@/store/auth/utils';

type Context = ActionContext<ConsumerStateInterface, any>;

function clearErrors(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('Ui/clearUserFriendlyError', {}, { root: true });
}

export async function otpRegister(context: Context, phone_number: string): Promise<StoreActionInterface> {
  clearErrors(context);
  await context.dispatch('Otp/setOtpSessionToken', '', { root: true });

  const uuid = context.getters.getInvitation?.uuid;
  const postData: any = { phone_number, uuid };
  let statusCode = 0;

  try {
    const { data, status } = await consumerApi.otpRegister(postData);
    statusCode = status;
    await context.dispatch('Otp/setOtpSessionToken', data.session_token, { root: true });
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const response = axiosError.response!;
      statusCode = response.status;
      const mutation = statusCode === 417 ? 'Ui/setUserFriendlyError' : 'Ui/setErrors';
      context.commit(mutation, response, { root: true });
    }
    return { error, status: statusCode };
  } finally {
    await context.dispatch('Otp/setPhoneResendEnabled', statusCode !== 417, { root: true });
  }
}

export async function getInvitation(context: Context, invitation_id: string): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setInvitation', {});

  try {
    const { data, status } = await consumerApi.getInvitation(invitation_id);
    context.commit('setInvitation', data);
    context.commit('setMerchantName', data.merchant_name);
    context.commit('setMerchantId', data.merchant);
    return { data, status };
  } catch (error: any) {
    return { error };
  }
}

export async function fraudCheck(
  context: Context,
  socureSessionId: string,
):Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setFraudCheck', {});
  context.commit('setFraudCheckError', false);

  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.fraudCheck(
      consumerApplicationId,
      { deviceSessionID: socureSessionId },
    );
    context.commit('setFraudCheck', data);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const status = axiosError.response?.status;
      context.commit('setFraudCheckError', status === 418);
      return { error, status };
    }
    return { error };
  }
}

export async function onboard(context: Context, payload: any): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setConsumerApplicationId', '');
  context.commit('setConsumerHumanReadableApplicationId', '');
  context.commit('setExistingApplications', []);

  const invitation_uuid = context.getters.getInvitation?.uuid;

  const postData: any = {
    requested_loan_amount: payload.requestedLoanAmount,
    email: toLower(payload.email),
    phone_number: payload.phoneNumber,
    merchant: payload.merchant,
    session_token: payload.sessionToken,
    security_code: payload.securityCode,
    invitation_uuid,
  };

  try {
    const { data, status } = await consumerApi.onboard(invitation_uuid, postData);
    const {
      consumer_application,
      consumer_application_human_readable_id,
      user_id,
      token,
    } = data;

    context.commit('setConsumerApplicationId', consumer_application);
    context.commit('setConsumerHumanReadableApplicationId', consumer_application_human_readable_id);

    if (token) {
      const user: any = { id: user_id };
      await context.dispatch('Auth/setUserData', { token, user }, { root: true });
    }

    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const response = axiosError.response!;
      const existing_applications = get(response, 'data.existing_applications', []);
      context.commit('setExistingApplications', existing_applications);

      const status = response?.status;
      const mutation = status === 417 ? 'Ui/setUserFriendlyError' : 'Ui/setErrors';
      context.commit(mutation, response, { root: true });
      return { error, status };
    }

    return { error };
  }
}

export async function saveAllocationDecision(context: Context, selectedLoan: RateSheetData) {
  context.commit('setSelectedLoan', selectedLoan);

  const { uuid } = selectedLoan;
  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const { status, data } = await consumerApi.saveAllocationDecision(
      consumerApplicationId,
      { uuid },
    );

    context.commit('saveLenderInfo', data);

    return status;
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const response = axiosError.response!;
      context.commit('Ui/setErrors', response, { root: true });
      return response?.status;
    }
  }
  return 0;
}

export async function saveSelectedLoan(
  context: Context,
): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setBorrowerAgreementSpecificStateError', false);

  const consumerApplicationId = context.getters.getConsumerApplicationId;

  let lenderName = '';
  let statusCode = 0;
  let lenderPrivacyPolicyUrl = '';

  try {
    const { data, status } = await consumerApi.saveSelectedLoan(consumerApplicationId);
    lenderName = data.lender;
    lenderPrivacyPolicyUrl = data.lender_privacy_policy_url;
    statusCode = status;
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
      statusCode = axiosError.response!.status;
    }
    return { error, status: statusCode };
  } finally {
    context.commit('setBorrowerAgreementSpecificStateError', statusCode === 404);
    context.commit('setFetchingLoans', false);
    context.commit('setLenderName', lenderName);
    context.commit('setLenderPrivacyPolicyUrl', lenderPrivacyPolicyUrl);
  }
}

export async function getApprovedLoans(context: Context, skipEmail?: boolean): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setFetchingLoans', true);
  context.commit('setLoanOptions', []);

  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.getApprovedLoans(consumerApplicationId, skipEmail);
    context.commit('setLoanOptions', data);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  } finally {
    context.commit('setFetchingLoans', false);
  }
}

export async function cancelApplication(context: Context, applicationId?: string): Promise<StoreActionInterface> {
  clearErrors(context);

  const consumerApplicationId = applicationId || context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.cancelApplication(consumerApplicationId);
    context.commit('setRemoveExistingApplication', consumerApplicationId);

    if (consumerApplicationId === context.getters.getConsumerApplicationId) {
      context.commit('resetModuleState');
      await clearAuthLocalStorage();

      const merchantName = context.getters.getInvitation.merchant_name;
      context.commit('setApplicationCanceledData', { canceled: true, merchantName });
    }

    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  }
}

export async function declineApplication(context: Context, applicationId?: string): Promise<StoreActionInterface> {
  clearErrors(context);

  const consumerApplicationId = applicationId ?? context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.declineApplication(consumerApplicationId);
    context.commit('setRemoveExistingApplication', consumerApplicationId);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  }
}

export async function creditEnginePull(context: Context, typeOfPull: string): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setProcessingCreditEngine', true);
  context.commit('setCreditEngineCheck', { data: {}, typeOfPull });

  const appId = context.getters.getConsumerApplicationId;
  try {
    const { data, status } = await consumerApi.creditEnginePull(appId, typeOfPull);
    context.commit('setCreditEngineCheck', { data, typeOfPull });
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  } finally {
    context.commit('setProcessingCreditEngine', false);
  }
}

export async function getLoanAgreement(context: Context): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setLoanAgreement', null);

  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.getLoanAgreement(consumerApplicationId);
    context.commit('setLoanAgreement', data);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  }
}

export async function getAvailableLenders(context: Context, params: any): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setLoanAgreement', null);

  const appId = context.getters.getConsumerApplicationId;
  try {
    const { data, status } = await consumerApi.checkAvailableLenders(appId, params);

    context.commit('setAreLendersAvailable', data?.detail === 'Lenders available.');

    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  }
}

export async function reEntry(context: Context): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setLoanAgreement', null);

  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.getReEntry(consumerApplicationId);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const { status } = axiosError.response!;
      const mutation = status === 417 ? 'Ui/setUserFriendlyError' : 'Ui/setErrors';
      context.commit(mutation, axiosError.response, { root: true });
      return { error, status };
    }
    return { error };
  }
}

export async function getExistingApplication(context: Context): Promise<StoreActionInterface> {
  clearErrors(context);
  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const { data, status } = await consumerApi.getApplication(consumerApplicationId);
    const loanApplyData = {
      ...data,
      ...data.user,
      ...data.address,
      ...data.sensitive,
      ...data.offer,
    };

    context.commit('setConsumerHumanReadableApplicationId', data.human_readable_id);
    context.commit('setSelectedLoan', data.offer);
    context.commit('setLoanApplyWizardData', loanApplyData);
    context.commit('setLenderName', data.lender || '');
    context.commit('setLenderPrivacyPolicyUrl', data.lender_privacy_policy_url || '');
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const { status } = axiosError.response!;

      context.commit('Ui/setUserFriendlyError', axiosError.response, { root: true });
      if (status === 400) {
        context.commit('setLoanApplyWizardData', null);
      }
      return { error, status };
    }
    return { error };
  }
}

export async function reEntryVerify(context: Context, payload: any): Promise<StoreActionInterface> {
  clearErrors(context);
  context.commit('setConsumerApplicationId', '');
  context.commit('setConsumerHumanReadableApplicationId', '');
  context.commit('setExistingApplications', []);

  const invitation_uuid = context.getters.getInvitation?.uuid;
  const postData: any = {
    phone_number: payload.phoneNumber,
    session_token: payload.sessionToken,
    security_code: payload.securityCode,
    invitation_uuid,
  };

  try {
    const { data, status } = await consumerApi.reEntryVerify(invitation_uuid, postData);
    const { consumer_application, token } = data;

    context.commit('setConsumerApplicationId', consumer_application);
    if (token) {
      await context.dispatch('Auth/setUserData', { token }, { root: true });
    }

    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      const { status } = axiosError.response!;
      const mutation = status === 417 ? 'Ui/setUserFriendlyError' : 'Ui/setErrors';
      context.commit(mutation, axiosError.response, { root: true });

      return { error, status };
    }
    return { error };
  }
}

export async function setApplicationValid(context: Context, valid: boolean) {
  context.commit('setConsumerApplicationValid', valid);
}

export async function setShowDisclaimerPage(context: Context, showDisclaimerPage: boolean) {
  context.commit('setDisclaimerPage', showDisclaimerPage);
}

export async function getAvailableIdTypes(context: Context) {
  try {
    const { data } = await consumerApi.getAvailableIdTypes();
    context.commit('setIdTypes', data);
  } catch (error: any) {
    context.commit('Ui/setErrors', error.response, { root: true });
  }
}

export async function getActiveLenders(context: Context) {
  try {
    const { data } = await consumerApi.getAllActiveLenders();
    return data;
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return [];
  }
}

export async function getInviteToConsumerPortal(context: Context, payload: string) {
  let consumerApplicationId;

  if (payload) {
    consumerApplicationId = payload;
  } else {
    consumerApplicationId = context.getters.getConsumerApplicationId;
  }

  try {
    const { data, status } = await consumerApi.getInvitePortal(consumerApplicationId);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  }
}

export async function getOnboardingDetails(context: Context) {
  const appId = context.getters.getConsumerApplicationId;

  try {
    const response = await consumerApi.getCPOnboardDetails(appId);
    context.commit('setOnboardingDetails', response.data);
    return response.status;
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      return axiosError.response?.status;
    }
    return 0;
  }
}

export async function acceptApplication(context: Context) {
  const appId = context.getters.getConsumerApplicationId;
  await approveAndAccept(appId);
  context.commit('setApplicationCompleted', true);
}

export async function loanStacking(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('Ui/clearUserFriendlyError', {}, { root: true });

  const appId = context.getters.getConsumerApplicationId;

  try {
    const resp = await getLoanStacking(appId);
    return resp;
  } catch (error: any) {
    return error.response.status;
  }
}

export async function completeLaterClicked(context: Context, completeLaterClicked: boolean) {
  context.commit('setCompleteLaterClicked', completeLaterClicked);
  await clearAuthLocalStorage();
}

export async function setAnnualIncome(
  context: Context,
  annualIncome: number,
) {
  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const {
      data,
      status,
    } = await consumerApi.setAnnualIncome(consumerApplicationId, annualIncome);

    context.commit('setAnnualIncome', data);

    return { data, status };
  } catch (error: any) {
    context.commit('Ui/setErrors', error.response, { root: true });
    return { error };
  }
}

export async function setEmploymentInformation(
  context: Context,
  employmentInformation: EmploymentInformation,
) {
  const consumerApplicationId = context.getters.getConsumerApplicationId;

  const payload = {
    employment_length: employmentInformation.employment_length,
    employment_status: employmentInformation.employment_status,
    phone_number: employmentInformation.phone_number,
    extension: employmentInformation.extension,
    annual_income: employmentInformation.gross_income,
  };

  try {
    const {
      data,
      status,
    } = await consumerApi.setEmploymentInformation(consumerApplicationId, payload);

    context.commit('setEmploymentInformation', data);

    return { data, status };
  } catch (error: any) {
    context.commit('Ui/setErrors', error.response, { root: true });
    return { error };
  }
}

export async function getEmploymentInformation(
  context: Context,
) {
  const consumerApplicationId = context.getters.getConsumerApplicationId;

  try {
    const {
      data,
      status,
    } = await consumerApi.getEmploymentInformation(consumerApplicationId);

    context.commit('setEmploymentInformation', data);

    return { data, status };
  } catch (error: any) {
    context.commit('Ui/setErrors', error.response, { root: true });
    return { error };
  }
}

export function setConsumerApplicationId(context: Context, id: string) {
  context.commit('setConsumerApplicationId', id);
}
