<template>
  <div>
    <alert-modal
      :dialog="!otpEnabled"
      type="error"
      max-width="420"
      data-test="maxCard"
      hide-close>
      <p class="mb-0">
        Sorry, we cannot verify your mobile number and you cannot proceed.
      </p>
    </alert-modal>

    <transition name="fade">
      <v-form class="fs-unmask standard-input-otp" @submit.prevent>
        <custom-alert
          v-for="(error, index) in combinedErrors"
          :key="index"
          type="error">
          {{ error }}
        </custom-alert>

        <div class="standard-input-otp__label">
          <span :class="isMob ? 'bigger-header' : ''">{{ bodyText }}</span>
        </div>

        <div class="standard-input-otp__single-input">
          <otp-input
            :ref="`${inputNameString}Single`"
            v-model="singleInputCode"
            :disabled="!otpEnabled"
            required
            single-input
            :autofocus="autofocusEnabled" />
          <custom-button
            :disabled="disableSubmit"
            full-width
            class="mt-6 mb-4"
            @click="verifyOtpSingleInput">
            Submit
          </custom-button>
        </div>

        <resend-otp-soft-pull-modal
          v-if="isSoftPullApiInitiated"
          :invitation-uuid="invitationUuid"
          :phone-number="phoneNumber" />

        <standard-input-otp-resend-otp-modal
          v-else
          :invitation-uuid="invitationUuid"
          :merchant-user-email="merchantUserEmail"
          :on-phone-number-updated="phoneNumberUpdated"
          :phone-number="phoneNumber"
          :otp-message="otpMessage"
          :consumer-otp="consumerOtp"
          :is-mob="isMob" />
      </v-form>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import OtpInput from '@/components/Inputs/Otp.vue';
import StandardInputOtpResendOtpModal from '@/components/Inputs/Otp/ResendOtpModal.vue';
import ResendOtpSoftPullModal from '@/components/Inputs/Otp/ResendOtpSoftPullModal.vue';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import GetErrors from '@/mixins/GetErrors';
import OtpMixin from '@/mixins/OtpMixin';
import SetCustomErrorValueMixin from '@/mixins/SetCustomErrorValueMixin';
import ValidationTypesMixin from '@/mixins/ValidationTypesMixin';
import otp from '@/validators/otp';
import otpSingleInput from '@/validators/otp_single_input';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import AlertModal from '@/components/Dialogs/AlertModal.vue';

export default defineComponent({
  name: 'StandardInputOtp',

  components: {
    AlertModal,
    CustomButton,
    CustomAlert,
    OtpInput,
    StandardInputOtpResendOtpModal,
    ResendOtpSoftPullModal,
  },

  mixins: [
    GetErrors,
    ValidationTypesMixin,
    OtpMixin,
    SetCustomErrorValueMixin,
  ],

  props: {
    singleInput: { type: Boolean, default: false },
    invitationUuid: { type: String, default: '' },
    isSoftPullApiInitiated: { type: Boolean, default: false },
    merchantUserEmail: { type: String, default: '' },
    sessionToken: { type: String, required: true },
    phoneNumber: { type: String, required: true },
    verifyCode: { type: Function, required: true },
    onPhoneNumberUpdated: { type: Function, required: true },
    consumerOtp: { type: Boolean, default: false },
    showHeader: { type: Boolean },
    otpMessage: { type: String, default: '' },
    autofocusEnabled: { type: Boolean },
    disableBtn: { type: Boolean },
    isMob: { type: Boolean },
    bodyText: { type: String, default: 'Please enter the six digit code we just sent to your mobile device.' },
  },

  data() {
    const code: any = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
    };
    return {
      singleInputCode: '',
      code,
      inputNameString: 'otpInput',
      numberOfInputs: 6,
    };
  },

  computed: {
    combinedErrors(): Array<ErrorsInterface> {
      const errors: any = [];
      if (!this.otpEnabled) {
        return errors;
      }

      if (Object.values(this.errors).length > 0 && Object.values(this.errors)[0].length > 0) {
        if (Array.isArray(Object.values(this.errors)[0])) {
          errors.push(...Object.values(this.errors)[0]);
        } else {
          errors.push(Object.values(this.errors)[0]);
        }
      }

      return errors;
    },

    securityCode(): string {
      return `${this.code[1]}${this.code[2]}${this.code[3]}${this.code[4]}${this.code[5]}${this.code[6]}`;
    },

    disableSubmit() {
      return !otpSingleInput(this.singleInputCode) || !this.otpEnabled || this.disableBtn;
    },
  },

  watch: {
    code: {
      deep: true,
      handler(code: any) {
        for (const i of Object.keys(code)) {
          if (!otp(code[i])) {
            return;
          }
        }
        this.verifyOtp();
      },
    },
  },

  methods: {
    /**
    * Called on each input change,
    * will start OTP validation when all inputs are filled
    * @param index
    */
    onKeyup(index: number) {
      if (!this.$props.singleInput && index > 0 && index <= this.numberOfInputs) {
        (this.$refs[`${this.inputNameString}${index}`] as any)[0].$el.querySelector('input').focus();
      }
    },

    verifyOtp() {
      if (this.otpEnabled) {
        this.verifyCode(this.securityCode);
      }
    },

    verifyOtpSingleInput() {
      if (this.otpEnabled) {
        this.verifyCode(this.singleInputCode);
      }

      this.$emit('verifyInput');
    },

    phoneNumberUpdated(phoneNumber: string) {
      this.onPhoneNumberUpdated(phoneNumber);

      this.setCustomErrorValue('non_field_errors', '', true);
      this.setCustomErrorValue('detail', '', true);

      this.clearOtpValues();
    },

    clearOtpValues() {
      this.singleInputCode = '';
      this.code = {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-input-otp";

.standard-input-otp__label {
  color: var(--grayscale-color-1);
  margin-bottom: 1.5rem;
  .bigger-header {
    font-size: 1rem;
  }
}

.verification-code-title {
  color: #183057;
}
</style>
