<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--address" data-test="COB-addressStep">
    <bubble-card :custom-class="!displayHomeAddressCard ? 'hide-home-address-card' : ''">
      <template #card-title>
        <h5 data-test="consumerAddressHeaderLabel">
          Home Address
        </h5>
        <button v-if="hasPropertyAddress" @click="displayHomeAddressCard = !displayHomeAddressCard;">
          <v-icon>{{ !displayHomeAddressCard ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
        </button>
      </template>

      <transition mode="out-in" name="slide">
        <consumer-loan-apply-no-lenders-available-msg v-if="noLendersAvailable" />

        <div v-else>
          <v-form @submit.prevent="openAddressValidation('home')">
            <address-input
              ref="inputLocation"
              v-model="autocompleteHomeLocation"
              address1-hint="Use the address where you reside
              (no PO Boxes or commercial addresses)"
              address1-hint-persistent
              address1-label="Street Address"
              address2-label="Apt / Unit"
              ask-confirmation
              :disabled="processing"
              :error-messages="[addressValidationError]"
              required
              :show-confirmation="showAddressConfirmation"
              :validated-address="validatedAddress"
              @confirmation:accepted="checkAvailableLenders('home')"
              @confirmation:declined="hideDialog" />

            <div v-if="isNewEmploymentFlowEnabled" class="property-type-wrap">
              <span>Do you rent or own this property?</span>
              <div>
                <v-radio-group
                  v-model="homeAddressPropertyType"
                  inline
                  class="property-type-wrap__radio-group"
                  required>
                  <v-radio
                    label="Own"
                    :value="homeAddressOwnValue"
                    class="radio-button"
                    data-test="ownRadioBtn" />
                  <v-radio
                    label="Rent"
                    :value="homeAddressRentValue"
                    class="radio-button"
                    data-test="rentRadioBtn" />
                  <v-radio
                    label="Other"
                    :value="homeAddressOtherValue"
                    class="radio-button"
                    data-test="otherRadioBtn" />
                </v-radio-group>
              </div>
            </div>

            <div v-if="isMerchantProgramHI && isNewEmploymentFlowEnabled" class="property-home-address-wrap">
              <span><strong>Is the work being done at your home address?</strong></span>
              <div>
                <v-radio-group
                  v-model="propertyHomeAddress"
                  inline
                  class="property-home-address-wrap__radio-group"
                  required
                  @update:model-value="displayPropertyAddressCard = true">
                  <v-radio
                    label="Yes"
                    :value="isPropertyHomeAddressValue"
                    class="radio-button"
                    data-test="yesRadioBtn" />
                  <v-radio
                    label="No"
                    :value="notPropertyHomeAddressValue"
                    class="radio-button"
                    data-test="noRadioBtn" />
                </v-radio-group>
              </div>
            </div>

            <custom-button
              v-if="!hasPropertyAddress"
              :disabled="isDisabled"
              full-width
              data-test="continueBtn"
              type="submit">
              Continue
            </custom-button>
          </v-form>
        </div>
      </transition>
    </bubble-card>

    <bubble-card
      v-if="hasPropertyAddress"
      :custom-class="`property-address-bubble-card ${displayPropertyAddressCard ? '' : 'hide-property-address-card'}`">
      <template #card-title>
        <h5 data-test="consumerPropertyAddressHeaderLabel">
          Property Address
        </h5>
        <button @click="displayPropertyAddressCard = !displayPropertyAddressCard;">
          <v-icon>{{ !displayPropertyAddressCard ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
        </button>
      </template>

      <transition mode="out-in" name="slide">
        <v-form @submit.prevent="openAddressValidation('property')">
          <address-input
            ref="inputLocation"
            v-model="autocompletePropertyLocation"
            address1-hint-persistent
            address1-label="Street Address"
            address2-label="Apt / Unit"
            ask-confirmation
            :disabled="processing"
            :error-messages="[propertyAddressValidationError]"
            required
            :show-confirmation="showAddressConfirmation"
            :validated-address="validatedAddress"
            :property-address="validatedPropertyAddress"
            :has-property-address="hasPropertyAddress"
            :show-review-address-modal="showReviewAddressModal"
            @confirmation:accepted="checkAvailableLenders('property')"
            @confirmation:declined="hideDialog"
            @address:confirmed="checkAvailableLenders('home')" />

          <div class="property-type-wrap">
            <span>Do you rent or own this property?</span>
            <div>
              <v-radio-group
                v-model="propertyAddressPropertyType"
                inline
                class="property-type-wrap__radio-group"
                required>
                <v-radio
                  label="Own"
                  :value="propertyAddressOwnValue"
                  class="radio-button"
                  data-test="ownRadioBtn" />
                <v-radio
                  label="Rent"
                  :value="propertyAddressRentValue"
                  class="radio-button"
                  data-test="rentRadioBtn" />
                <v-radio
                  label="Other"
                  :value="propertyAddressOtherValue"
                  class="radio-button"
                  data-test="otherRadioBtn" />
              </v-radio-group>
            </div>
          </div>

          <custom-button
            :disabled="isDisabledForProperty"
            full-width
            data-test="continueBtn"
            type="submit">
            Continue
          </custom-button>
        </v-form>
      </transition>
    </bubble-card>

    <complete-later-button v-if="!noLendersAvailable" />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import {
  AddressDataInterface,
  ValidatedAddressDataInterface,
} from '@/interfaces/addressValidation/AddressDataInterface';
import AddressInput from '@/components/Inputs/Address/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyNoLendersAvailableMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/NoLendersAvailableMsg.vue';
import zipCodeValidator from '@/validators/zip_code';
import { isEmpty } from 'lodash';
import AddressTypes from '@/enums/AddressTypes';
import { saveAddress } from '@/api/consumer';
import FullStoryLogging from '@/logging/FullStory';
import ConsumerHumanReadableApplicationId
  from '@/mixins/Consumer/LoanApply/ConsumerHumanReadableApplicationId';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { defineComponent } from 'vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import ValidateAddress from '@/mixins/ValidateAddress';
import GetProcessing from '@/mixins/GetProcessing';
import { PageTypesShorthand } from '@/enums/PageTypes';
import { StoreConsumerAddress } from '@/interfaces/consumer/loanApply/Address';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'AddressStep',
  components: {
    BubbleCard,
    AddressInput,
    CustomButton,
    ConsumerLoanApplyNoLendersAvailableMsg,
    CompleteLaterButton,
  },
  mixins: [
    GetProcessing,
    ValidateAddress,
    NavigatesStepsMixin,
    ConsumerHumanReadableApplicationId,
    IdleTimeoutMixin,
    HoneypotTrackMixin,
    FeatureFlagsMixin,
  ],
  data() {
    const autocompleteHomeLocation: ValidatedAddressDataInterface = {
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: null,
      zipCode: '',
      housing_status: '',
      address_verification_response: {
        analysis: {},
        components: {},
        metadata: {},
      },
    };
    const autocompletePropertyLocation: ValidatedAddressDataInterface = {
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: null,
      zipCode: '',
      housing_status: '',
      address_verification_response: {
        analysis: {},
        components: {},
        metadata: {},
      },
    };
    return {
      autocompleteHomeLocation,
      autocompletePropertyLocation,
      disabled: false,
      ownStep: 5,
      noLendersAvailable: false,
      hpEventName: 'Address Page Visit',
      hpStep: 5,
      homeAddressOwnValue: 'Own',
      homeAddressRentValue: 'Rent',
      homeAddressOtherValue: 'Other',
      propertyAddressOwnValue: 'Own',
      propertyAddressRentValue: 'Rent',
      propertyAddressOtherValue: 'Other',
      homeAddressPropertyType: '',
      propertyAddressPropertyType: '',
      propertyHomeAddress: '',
      isPropertyHomeAddressValue: 'yes',
      notPropertyHomeAddressValue: 'no',
      displayHomeAddressCard: true,
      displayPropertyAddressCard: false,
      showReviewAddressModal: false,
    };
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled
        || isEmpty(this.autocompleteHomeLocation.address1)
        || isEmpty(this.autocompleteHomeLocation.city)
        || isEmpty(this.autocompleteHomeLocation.state)
        || !zipCodeValidator(this.autocompleteHomeLocation.zipCode ?? '')
        || (this.isNewEmploymentFlowEnabled && !this.homeAddressPropertyType)
        || (this.isNewEmploymentFlowEnabled && this.isMerchantProgramHI && !this.propertyHomeAddress);
    },

    isDisabledForProperty(): boolean {
      return this.isDisabled
        || isEmpty(this.autocompletePropertyLocation.address1)
        || isEmpty(this.autocompletePropertyLocation.city)
        || isEmpty(this.autocompletePropertyLocation.state)
        || !zipCodeValidator(this.autocompletePropertyLocation.zipCode ?? '')
        || !this.propertyAddressPropertyType;
    },

    hasPropertyAddress(): boolean {
      return this.propertyHomeAddress === this.notPropertyHomeAddressValue;
    },

    isMerchantProgramHI(): boolean {
      return this.$store.getters['Consumer/getInvitation'].merchant_program === 'Home Improvement';
    },

    isDuplicateAddress(): boolean {
      const home = this.autocompleteHomeLocation;
      const property = this.autocompletePropertyLocation;

      return (
        home.address1 === property.address1
        && home.address2 === property.address2
        && home.city === property.city
        && home.state === property.state
        && home.zipCode === property.zipCode
        && home.housing_status === property.housing_status
      );
    },

    isNewEmploymentFlowEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.ENABLE_NEW_EMPLOYMENT_FLOW);
    },
  },
  mounted() {
    const {
      address_1, address_2, zip_code, city, state,
    } = this.$store.getters['Consumer/getInvitation'];

    if (address_1) {
      this.autocompleteHomeLocation.address1 = address_1;
    }
    if (address_2) {
      this.autocompleteHomeLocation.address2 = address_2;
    }
    if (city) {
      this.autocompleteHomeLocation.city = city;
    }
    if (state) {
      this.autocompleteHomeLocation.state = state;
    }
    if (zip_code) {
      this.autocompleteHomeLocation.zipCode = zip_code;
    }

    const firstName = this.$store.getters['Consumer/getLoanApplyWizardData'].first_name;
    const lastName = this.$store.getters['Consumer/getLoanApplyWizardData'].last_name;
    FullStoryLogging.setUserVars({
      displayName: firstName ? `${firstName} ${lastName}` : undefined,
      invitationId: this.consumerHumanReadableApplicationIdLabel,
    });

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    async checkAvailableLenders(addressType: string): Promise<void> {
      this.autocompleteHomeLocation = Object.assign(this.autocompleteHomeLocation, this.validatedAddress);
      this.autocompletePropertyLocation = Object.assign(this.autocompletePropertyLocation, this.validatedPropertyAddress);

      const address = this.autocompleteHomeLocation;

      let addressSaved = true;
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      const addressPayload: StoreConsumerAddress = {
        home_address: {
          address_type: AddressTypes.HOME,
          address_1: address.address1,
          address_2: address.address2,
          city: address.city,
          state: address.state,
          zip_code: address.zipCode,
          housing_status: this.homeAddressPropertyType,
        },
        address_verification_response: this.validatedAddress.address_verification_response,
      };

      const propertyAddressPayload = {
        home_address: {
          address_type: AddressTypes.HOME,
          address_1: address.address1,
          address_2: address.address2,
          city: address.city,
          state: address.state,
          zip_code: address.zipCode,
          housing_status: this.homeAddressPropertyType,
        },
        property_address: {
          address_type: AddressTypes.OTHER,
          address_1: this.autocompletePropertyLocation.address1,
          address_2: this.autocompletePropertyLocation.address2,
          city: this.autocompletePropertyLocation.city,
          state: this.autocompletePropertyLocation.state,
          zip_code: this.autocompletePropertyLocation.zipCode,
          housing_status: this.propertyAddressPropertyType,
        },
        address_verification_response: this.validatedPropertyAddress.address_verification_response,
      };

      if (addressType === 'home' || this.isDuplicateAddress) {
        await saveAddress(appId, addressPayload).catch((error: any) => {
          addressSaved = false;
          this.$store.dispatch('Ui/setErrors', error.response);
          console.log('error');
        });
      } else {
        await saveAddress(appId, propertyAddressPayload).catch((error: any) => {
          addressSaved = false;
          this.$store.dispatch('Ui/setErrors', error.response);
        });
      }

      const { countyName, zipShort, state } = address;
      const payload: any = {
        state_abbreviation: state,
        county_name: countyName,
        zipcode: zipShort,
      };

      const { data, status } = await this.$store.dispatch('Consumer/getAvailableLenders', payload);
      const lendersAvailable = data?.detail === 'Lenders available.';
      if (status === 200 && lendersAvailable) {
        this.$store.commit('Consumer/setLoanApplyWizardData', addressPayload);
        this.$store.commit('Consumer/setLoanApplyWizardData', propertyAddressPayload);

        if (addressSaved) {
          this.goToStep(this.ownStep + 1);
        }
      } else {
        this.noLendersAvailable = true;
        this.showAddressConfirmation = false;
      }

      this.disabled = false;
    },
    async openAddressValidation(addressType: string) {
      this.disabled = true;

      const address: AddressDataInterface = {
        address1: this.autocompleteHomeLocation.address1,
        address2: this.autocompleteHomeLocation.address2,
        city: this.autocompleteHomeLocation.city,
        state: this.autocompleteHomeLocation.state,
        zipCode: this.autocompleteHomeLocation.zipCode,
      };

      const propertyAddress: AddressDataInterface = {
        address1: this.autocompletePropertyLocation.address1,
        address2: this.autocompletePropertyLocation.address2,
        city: this.autocompletePropertyLocation.city,
        state: this.autocompletePropertyLocation.state,
        zipCode: this.autocompletePropertyLocation.zipCode,
      };

      const isValid = await this.validateAddress(address);
      let isPropertyValid = false;
      if (addressType === 'property') isPropertyValid = await this.validatePropertyAddress(propertyAddress);
      if (!isValid || (this.hasPropertyAddress && !isPropertyValid)) {
        this.disabled = false;
        return;
      }

      if (this.isDuplicateAddress) {
        this.showReviewAddressModal = true;
      } else {
        this.showAddressConfirmation = true;
      }
    },

    hideDialog() {
      this.showAddressConfirmation = false;
      this.showReviewAddressModal = false;
      this.disabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.standard-wizard {
  &__step {
    :deep(.card .card-body) {
      .card-text__info-group:first-child {
        margin-bottom: 0;

        .standard-input-field {
          margin-bottom: .5rem;
        }
      }
    }

    &--three {
      @include standard-wizard-form-inline-split;
    }
  }
}

.property-type-wrap {
  span {
    color: var(--grayscale-color-1);
    font-size: 1rem;
  }

  &__radio-group  {
    display: block;
    text-align: left;
    margin-top: 1rem;
    width: 100%;

    :deep(.v-selection-control-group) {
      display: block;
      margin: 0 auto;
      width: 100%;

      .radio-button {
        color: var(--grayscale-color-1);
        width: 30%;
      }
    }
  }
}

.property-home-address-wrap {
  span {
    color: var(--grayscale-color-1);
    font-size: 1rem;
  }

  &__radio-group {
    margin-top: 1rem;

    .radio-button {
      color: var(--grayscale-color-1);
      width: 30%;
    }
  }
}

.property-address-bubble-card {
  margin-top: 2rem !important;
}

.hide-home-address-card {
  min-height: 3rem;

  :deep(.bubble-card__content) {
    display: none;
  }
}

.hide-property-address-card {
  min-height: 3rem;

  :deep(.bubble-card__content) {
    display: none;
  }
}
</style>
