import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_custom_dialog = _resolveComponent("custom-dialog")!

  return (_openBlock(), _createBlock(_component_custom_dialog, {
    dialog: true,
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(() => [
      _createVNode(_component_text_input, {
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        class: "offer-code-name--input",
        label: "Enter Offer Code Name",
        hint: "We recommend a short name that is easy to share with your sales team.",
        "persistent-hint": "",
        rules: [() => _ctx.isValidName]
      }, null, 8, ["modelValue", "rules"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_custom_button, {
        class: "full-width",
        "data-test": "saveOfferCodeName",
        disabled: _ctx.saveDisabled,
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.editing ? 'Save' : 'Next'), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _: 1
  }))
}