import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-570b9c2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mobile-title"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "card-text__info-group" }
const _hoisted_4 = { class: "card-text__info-group" }
const _hoisted_5 = { class: "card-text__info-group" }
const _hoisted_6 = { class: "card-text__info-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_address_input = _resolveComponent("address-input")!
  const _component_phone_input = _resolveComponent("phone-input")!
  const _component_email_input = _resolveComponent("email-input")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "business-name-and-address inline-inputs",
    elevation: _ctx.cardElevation
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, {
        class: "py-3",
        "data-test": "businessNameSubHeader"
      }, {
        default: _withCtx(() => [
          (_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("h6", _hoisted_1, " Business Name, Address and Contact "))
            : (_openBlock(), _createElementBlock("h5", _hoisted_2, " Business Name, Address and Contact ")),
          _createVNode(_component_custom_button, {
            class: "edit-save-button",
            disabled: _ctx.saveDisabled,
            variant: "text",
            size: "small",
            onClick: _ctx.toggleEdit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.editing ? 'Save' : 'Edit'), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "pa-4" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_text_input, {
              modelValue: _ctx.userVerifyData.businessName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userVerifyData.businessName) = $event)),
              disabled: _ctx.formInputsDisabled,
              "in-place": "",
              required: true,
              "data-test": "businessInput",
              label: "Business Name",
              type: "text"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_address_input, {
              modelValue: _ctx.userAddressData,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userAddressData) = $event)),
              "ask-confirmation": "",
              "address1-data-test": "address1Input",
              "address1-label": "Business Street Address",
              "address2-data-test": "address2Input",
              "address2-label": "Business Suite / Room / Floor",
              "confirmation-modal-title": "Please Verify Your Business Address",
              "city-data-test": "cityInput",
              disabled: _ctx.formInputsDisabled,
              "is-fs-excluded": false,
              "in-place": "",
              required: "",
              "state-data-test": "stateSelect",
              "show-confirmation": _ctx.showAddressConfirmation,
              "validated-address": _ctx.validatedAddress,
              "error-messages": [_ctx.addressValidationError],
              "onConfirmation:accepted": _ctx.confirmAddress,
              "onConfirmation:declined": _ctx.declineAddress
            }, null, 8, ["modelValue", "disabled", "show-confirmation", "validated-address", "error-messages", "onConfirmation:accepted", "onConfirmation:declined"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_phone_input, {
              modelValue: _ctx.allAttributes.merchant_phone,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.allAttributes.merchant_phone) = $event)),
              "data-test": "businessPhoneInput",
              disabled: _ctx.formInputsDisabled,
              "in-place": "",
              label: "Business Phone Number",
              required: ""
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_email_input, {
              modelValue: _ctx.userVerifyData.emailAddress,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userVerifyData.emailAddress) = $event)),
              "data-test": "emailInput",
              disabled: _ctx.formInputsDisabled,
              "in-place": "",
              label: "Business Email Address",
              required: ""
            }, null, 8, ["modelValue", "disabled"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["elevation"]))
}