enum EmploymentStatus {
  EMPLOYED = 'Employed',
  SALARIED = 'Salaried',
  HOURLY = 'Hourly',
  SELF_EMPLOYED = 'Self-Employed',
  RETIRED = 'Retired',
  UNEMPLOYED = 'Unemployed',
  OTHER = 'Other',
}

export default EmploymentStatus;
