<template>
  <div class="simple-card__body-text" data-app="true">
    <custom-expansion-panel :expanded="isExpanded" :can-collapse="onlyOnActivityCenter || !isMobile">
      <template #title>
        <div class="section-header">
          <h3 class="subheader">
            {{ title }}
          </h3>
          <v-spacer />
          <div class="search-refresh-wrapper section-header__actions">
            <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              label="Search"
              class="table-search-bar"
              variant="underlined"
              width="14rem"
              single-line
              hide-details />
            <refresh-button
              v-if="!isMobile"
              data-test="recentRefreshBtn"
              :active="refreshingFromParent"
              @click="refreshTable" />
          </div>
        </div>
      </template>

      <template #text>
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="Search"
          flat
          bg-color="transparent"
          variant="underlined"
          class="table-search-bar--mobile"
          single-line
          hide-details
          data-test="portalTableSearch" />

        <v-data-table-server
          :page="page"
          v-bind="options"
          :headers="filteredTableHeaders"
          :items="items"
          :search="search"
          :disable-sort="isMobile"
          :mobile="isMobile"
          :items-per-page-options="itemsPerPageOptions"
          items-per-page-text="Rows Displayed"
          item-key="date_time"
          :items-length="itemsLength"
          class="merchant-portal-recent-activity__data-table"
          @update:page="$emit('update:page', $event)"
          @update:options="updatePage">
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.consumer_first_name="{ item }">
            <span v-private>{{ item.consumer_first_name }}</span>
          </template>

          <template v-slot:item.consumer_last_name="{ item }">
            <span v-private>{{ item.consumer_last_name }}</span>
          </template>

          <template v-slot:item.payment_request_status="{ item }">
            {{ item.payment_request_status }}
          </template>

          <template v-slot:item.request_date="{ item }">
            {{ DateTimeFormatShort(item.request_date) }}
          </template>

          <template v-slot:item.consumer_charge_amount="{ item }">
            <span v-private>{{ CurrencyFormatLong(item.consumer_charge_amount) }}</span>
          </template>

          <template v-slot:item.ach_amount="{ item }">
            <span v-private>{{ CurrencyFormatLong(item.ach_amount) }}</span>
          </template>

          <template v-slot:item.requested_by="{ item }">
            <span v-private>{{ item.requested_by }}</span>
          </template>
          <!-- eslint-enable -->
        </v-data-table-server>
      </template>
    </custom-expansion-panel>
  </div>
</template>

<script lang="ts">
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import DateTimeFormatShort from '@/filters/DateTimeFormatShort';
import RefreshButton from '@/components/Buttons/DataTableRefreshButton.vue';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import CustomExpansionPanel from '@/components/CustomExpansionPanel.vue';
import debounce from 'lodash/debounce';
import { defineComponent } from 'vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';

export default defineComponent({
  name: 'TransactionTable',
  components: {
    RefreshButton,
    CustomExpansionPanel,
  },

  mixins: [MerchantPermissionsMixin],

  props: {
    title: { type: String, default: 'All Transactions' },
    itemsPerPage: { type: Number, default: 5 },
    itemsPerPageOptions: { type: Array<number>, default: () => [15, 25, 35] },
    itemsLength: { type: Number, default: null },
    page: { type: Number, default: null },
    items: { type: Array<any>, default: () => [] },
    refreshingFromParent: { type: Boolean, default: false },
    onlyOnActivityCenter: { type: Boolean, default: false },
    /**
     * Determines if the table can be collapsed
     */
    isMobile: { type: Boolean, default: false },
    /**
     * Should the expansion panel be expanded by default, works only if isMobile = true
     */
    expanded: { type: Boolean, default: false },
  },

  data() {
    const headers: Array<TableHeaderInterface> = [
      {
        title: 'First Name',
        sortable: true,
        value: 'consumer_first_name',
      },
      {
        title: 'Last Name',
        sortable: true,
        value: 'consumer_last_name',
      },
      {
        title: 'Transaction Description',
        sortable: false,
        value: 'transaction_description',
      },
      {
        title: 'Transaction Amount',
        align: 'end',
        sortable: false,
        value: 'consumer_charge_amount',
      },
      {
        title: 'Status',
        sortable: false,
        value: 'payment_request_status',
      },
      {
        title: 'ACH Amount',
        sortable: false,
        align: 'end',
        value: 'ach_amount',
      },
      {
        title: 'Date/Time',
        sortable: true,
        value: 'request_date',
      },
      {
        title: 'Submitted by',
        sortable: true,
        value: 'requested_by',
      },
    ];
    return {
      headers,
      search: '',
      searchActivated: false,
      options: {
        sortBy: [{ key: 'date_time', order: 'asc' }],
        itemsPerPage: this.itemsPerPage,
      },
      debouncedSearchHandler: null as any,
    };
  },
  computed: {
    isExpanded(): boolean {
      return this.isMobile ? this.expanded : true;
    },

    filteredTableHeaders(): Array<TableHeaderInterface> {
      return this.headers.filter(header => {
        // Exclude 'ACH Amount' if neither isCorporateAdmin nor isAdmin is true
        if (header.value === 'ach_amount' && !(this.isCorporateAdmin || this.isAdmin)) {
          return false;
        }
        return true;
      });
    },
  },
  watch: {
    search(val: string) {
      this.debouncedSearchHandler(val);
    },
  },
  created() {
    this.debouncedSearchHandler = debounce(this.searchHandler, 500);
  },
  methods: {
    DateTimeFormatShort,
    CurrencyFormatLong,
    async searchHandler(val: any) {
      const that = this as any;
      if (val.length < 1 && that.searchActivated) {
        that.$emit('updateSearchPage', { pageNumber: 1 });
      } else if (val && val.length >= 3) {
        that.searchActivated = true;
        that.$emit('updateSearchPage', { searchString: val, pageNumber: 1 });
      }
    },
    fetchRecentTransactions(): Promise<void> {
      return this.$store.dispatch('MerchantPortal/dispatchFetchRecentTransactions', { pageNumber: 1, itemsPerPage: 5 });
    },
    fetchPaymentRequests(): Promise<void> {
      return this.$store.dispatch('MerchantPortal/dispatchFetchPaymentRequest', { pageNumber: 1, pageSize: 15 });
    },
    async refreshTable(): Promise<void> {
      this.search = '';
      this.options.sortBy = [{ key: 'date_time', order: 'asc' }];
      this.options.itemsPerPage = this.itemsPerPage;

      this.$emit('refreshTable', { ...this.options, page: 1 });
    },
    updatePage(val: any) {
      if (this.refreshingFromParent) return;

      if (this.search.length > 0) {
        this.$emit('updateSearchPage', { pageNumber: val.page, itemsPerPage: val.itemsPerPage, searchString: this.search });
      } else {
        this.$emit('updatePage', val);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table-default";
@import '@/assets/scss/portal-section';

:deep() {
  .expansion-panel .v-item-group  .v-expansion-panel .v-expansion-panel-title {
    padding-left: 0;
  }

  .v-table__mobile-row__header {
    text-align: left;
  }

  .v-data-footer {
    justify-content: end;
    height: 3.5rem;
  }

  .v-table-header tr th span {
    color: var(--grayscale-color-1);
  }

  .v-table__wrapper tbody tr td {
    color: var(--grayscale-color-1);
  }
}

.section-header {
  border-bottom: none !important;

  &__title {
    color: var(--grayscale-color-1);
  }
}

.table-search-bar :deep() .v-label,
.table-search-bar--mobile :deep() .v-label {
  font-size: var(--momnt-input-placeholder-font-size);
}

.table-search-bar--mobile {
  display: none;
  margin-top: 0;
  padding: 0.5rem 1rem;
  background: var(--grayscale-color-5);
}

@include mobile {
  .table-search-bar {
    display: none;

    &--mobile {
      display: grid;
    }
  }

  :deep(.expansion-panel .v-item-group  .v-expansion-panel .v-expansion-panel-title) {
    padding-left: 1rem;
  }
}
</style>
