<template>
  <div class="momnt-spinner">
    <svg
      :width="props.width"
      :height="props.height"
      viewBox="0 0 48 48"
      class="momnt-spinner__icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.1448 13.0924C31.738 14.927 35.4126 18.8464 35.4126 24.0167V24.4336C40.8409
        15.5942 37.7509 6.33774 36.1642 3.33565C32.5732 1.25087 28.481 0 23.9714 0C12.3631
        0 2.67567 8.25574 0.42083 19.2634C0.479058 19.089 0.821467 18.7929 1.10839
        18.5448L1.1084 18.5448C1.23299 18.4371 1.34712 18.3384 1.42298 18.2627C1.59
        18.0959 1.7779 17.9291 1.9658 17.7624C2.15371 17.5956 2.34162 17.4288
        2.50864 17.262C3.34377 16.5949 4.2624 15.9277 5.18104 15.2606C7.26886
        13.9263 9.5237 12.8423 11.9456 12.1751C14.7015 11.3412 17.6244 11.1744
        20.5474 11.4246C22.7187 11.7582 25.057 12.2585 27.1448 13.0924ZM12.8654
        26.6844C12.1973 21.7643 13.784 16.6774 18.2102 14.0923C18.2937 14.0089
        18.4608 13.9255 18.6278 13.9255C8.27222 13.6753 1.75824 20.9304 0.00447769
        23.8491C-0.0790349 28.0186 1.00663 32.1882 3.17796 36.0242C9.02384 46.1145
        21.0496 50.3675 31.6557 46.6983C31.4811 46.7564 31.0628 46.6119 30.7123
        46.4908L30.7123 46.4907C30.5601 46.4381 30.4207 46.39 30.3195 46.3647C30.069
        46.2813 29.8394 46.1979 29.6097 46.1146L29.6097 46.1145L29.6096 46.1145C29.38
        46.0311 29.1503 45.9477 28.8998 45.8644C28.2318 45.642 27.5637 45.3455
        26.8956 45.049L26.8955 45.049C26.5615 44.9007 26.2274 44.7525 25.8934
        44.6135C23.722 43.5294 21.6342 42.1118 19.8805 40.3605C17.7927 38.3591
        16.1224 35.9408 14.9532 33.3557C13.8676 31.1875 13.1995 28.9359 12.8654
        26.6844ZM18.2925 33.9393C22.7187 36.5244 27.8965 35.2735 31.8216 32.2715C33.5753
        30.8538 35.1621 29.1026 36.5818 27.3514C38.3355 24.933 39.5047 22.3479
        40.2563 19.5126C40.8409 17.1776 41.0914 14.6759 40.9244 12.1742C40.8409
        11.0901 40.6739 10.0061 40.5069 8.92204L40.5069 8.92189C40.4651 8.67172
        40.4025 8.42155 40.3398 8.17139C40.2772 7.92121 40.2146 7.67103 40.1728
        7.42085C40.1475 7.31985 40.1222 7.17299 40.0946 7.01266C40.0311 6.64346
        39.9552 6.20287 39.8388 6.08659C48.2735 13.425 50.6119 25.9337 44.766
        36.0241C42.5947 39.8601 39.4212 42.8621 35.8302 44.8635C32.4896 44.7801
        22.9692 42.7788 17.9585 33.6891C18.0002 33.7308 18.0628 33.7725 18.1255
        33.8142C18.1881 33.8559 18.2507 33.8976 18.2925 33.9393Z"
        fill="#EF8874" />
    </svg>
  </div>
</template>

<script setup lang="ts">
interface MomntSpinnerProps {
  animationDuration?: string
  width?: number
  height?: number
}

const props = withDefaults(defineProps<MomntSpinnerProps>(), {
  animationDuration: '6s',
  width: 48,
  height: 48,
});
</script>

<style lang="scss" scoped>
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.momnt-spinner {
  padding: 1rem 0;

  &__icon {
    display: block;
    margin: 0 auto;
    animation: rotate360 v-bind('animationDuration') linear infinite;
    transform-origin: center;
  }
}
</style>
