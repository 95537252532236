<template>
  <div class="portal" :class="{ 'expired-link': expiredLink }">
    <consumer-header v-if="!expiredLink" class="portal__header" />

    <div class="portal__container">
      <consumer-expired-link-msg v-if="expiredLink" />

      <div
        v-else-if="showWelcomeSoftPullInitiated"
        class="mt-4"
        data-test="welcomeSPInitScreen">
        <bubble-card class="bubble-card--welcome">
          <template #card-title>
            <h1 data-test="welcomeSPInitHeaderLabel">
              Welcome!
            </h1>
          </template>

          <div>
            <img
              :src="logoUrl"
              class="d-block mx-auto mb-6"
              width="200"
              alt="Momnt Logo"
              data-test="welcomeSPInitLogo">
            <p class="pa-0" data-test="welcomeSPInitGuideLabel">
              You’ve been pre-qualified for a loan offer through Momnt.
              Now, we’ll guide you through the next steps to confirm your details before finalizing your application.
            </p>
            <p class="pa-0 mt-4" data-test="welcomeSPInitTermsLabel">
              Once you review and confirm your information, you’ll have the opportunity to accept your loan terms.
            </p>
          </div>
        </bubble-card>

        <custom-button
          class="mt-4"
          data-test="continueBtn"
          full-width
          @click="goToPersonalInfoStep">
          Continue
        </custom-button>
      </div>

      <div v-else-if="showWelcomeScreen" data-test="welcomeScreen">
        <h1 class="heading-1">
          Welcome!
        </h1>

        <bubble-card
          v-if="cardShown === 1"
          card-color="teal"
          @click="cardShown = 2">
          <template #card-title>
            <h5 class="heading-5" data-test="noWorryHeaderLabel">
              No Worry Shopping
            </h5>
          </template>

          <div class="mb-8">
            <p class="pt-0" data-test="noWorryParagraphLabel">
              Preview loan offers without impacting your credit score.
              We only make a hard credit inquiry, which may impact your credit score,
              if you choose an offer and proceed.
            </p>
          </div>

          <template #card-actions>
            <div class="actions-dot-wrapper">
              <span class="actions-dot-wrapper__dot colored-dot" />
              <span class="actions-dot-wrapper__dot" />
            </div>
          </template>
        </bubble-card>

        <bubble-card
          v-if="cardShown === 2"
          card-color="peach"
          @click="cardShown = 1">
          <template #card-title>
            <h5 data-test="competitiveHeaderLabel">
              Competitive Offerings
            </h5>
          </template>

          <div class="mb-8">
            <ul class="pt-0" data-test="competitiveListLabels">
              <li>Low Interest Rates</li>
              <li>Fixed Monthly Payments</li>
              <li v-if="!has75kFeatureFlag">
                Loan amounts up to $55k
              </li>
            </ul>
          </div>

          <template #card-actions>
            <div class="actions-dot-wrapper">
              <span class="actions-dot-wrapper__dot" />
              <span class="actions-dot-wrapper__dot colored-dot" />
            </div>
          </template>
        </bubble-card>

        <checkbox-input
          v-model="checkESign"
          data-test="checkESign"
          density="compact"
          class="checkbox standard-input-field standard-input-field--checkbox">
          <template #label>
            By checking this box, I have read and agree to:
            <a
              data-test="esignBtn"
              :href="eSignDisclosureUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop>
              E-Sign Disclosure and Consent.
            </a>
          </template>
        </checkbox-input>

        <checkbox-input
          v-model="checkTCPA"
          data-test="checkTCPA"
          density="compact"
          class="checkbox standard-input-field standard-input-field--checkbox">
          <template #label>
            By checking this box, I have read and agree to:
            <a
              data-test="tcpaBtn"
              :href="tcpaUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop>
              TCPA Consent</a>,
            <a
              data-test="privacyBtn"
              :href="privacyPolicyUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop>
              Privacy Policy</a>,
            and
            <a
              data-test="operatingBtn"
              :href="termsOfUseUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop>
              Terms of Use
            </a>
          </template>
        </checkbox-input>

        <custom-button
          class="next-btn"
          data-test="nextBtn"
          :disabled="nextDisabled"
          full-width
          @click="goToLoanApply">
          Next
        </custom-button>
      </div>

      <alert-modal
        :dialog="showErrorAlertModal"
        :hide-close="true"
        type="error">
        {{ errorResponseAlertModal }}
      </alert-modal>
    </div>

    <consumer-footer />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import CheckboxInput from '@/components/Inputs/Checkbox.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerFooter from '@/components/Consumer/Footer.vue';
import ConsumerHeader from '@/components/Consumer/Header.vue';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import GetErrors from '@/mixins/GetErrors';
import GotoRouteName from '@/mixins/GotoRouteName';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import LocalStorageConstants from '@/constants/LocalStorageConstants';
import FullStoryLogging from '@/logging/FullStory';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import ConsumerExpiredLinkMsg from '@/components/Consumer/ConsumerExpiredLinkMsg.vue';
import { defineComponent } from 'vue';
import InvitationApplicationStatusDetails from '@/enums/Consumer/InvitationApplicationStatusDetails';
import { PageTypesShorthand } from '@/enums/PageTypes';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useConsumerOnboarding } from '@/composables/useConsumerOnboarding';

export default defineComponent({
  name: 'WelcomeConsumer',

  components: {
    BubbleCard,
    CheckboxInput,
    CustomButton,
    ConsumerHeader,
    ConsumerExpiredLinkMsg,
    ConsumerFooter,
    AlertModal,
  },

  mixins: [
    GotoRouteName,
    GetErrors,
    ReEntryMixin,
    HoneypotTrackMixin,
  ],

  setup() {
    const { getInvitation } = useConsumerOnboarding();
    const { isFeatureEnabled, FeatureFlagsConstants } = useFeatureFlag();
    const has75kFeatureFlag = isFeatureEnabled(FeatureFlagsConstants.CREDIT_LIMIT_75K);
    const isSoftPullApiProcessEnabled = isFeatureEnabled(FeatureFlagsConstants.ENABLE_SOFT_PULL_API_PROCESS);

    return {
      getInvitation,
      has75kFeatureFlag,
      isSoftPullApiProcessEnabled,
    };
  },

  data() {
    return {
      checkESign: false,
      checkTCPA: false,
      cardShown: 1,
      hpEventName: 'Welcome Page Visit',
      showWelcomeScreen: false,
      showWelcomeSoftPullInitiated: false,
    };
  },

  computed: {
    nextDisabled(): boolean {
      return !this.checkESign || !this.checkTCPA;
    },

    invitation(): any {
      return this.$store.getters['Consumer/getInvitation'];
    },

    expiredLink(): boolean {
      return this.$store.getters['Consumer/getExpiredLink'] ?? false;
    },

    tcpaUrl(): string {
      return this.$store.getters['Ui/getBrandingObject'].tcpa_url;
    },

    privacyPolicyUrl(): string {
      return this.$store.getters['Ui/getBrandingObject'].privacy_policy_url;
    },

    termsOfUseUrl(): string {
      return this.$store.getters['Ui/getBrandingObject'].terms_of_use_url;
    },

    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },

    eSignDisclosureUrl(): string {
      return this.$store.getters['Ui/getBrandingESignDisclosureUrl'];
    },
  },

  async created(): Promise<void> {
    await this.getConsumerInvitation();
    localStorage.setItem(
      LocalStorageConstants.CURRENTLY_ONBOARDING,
      LocalStorageConstants.ONBOARDING.COB,
    );

    FullStoryLogging.identify(this.invitation.uuid, {
      email: this.invitation.email,
      merchantUuid: this.$store.getters['Consumer/getMerchantId'],
    });
  },

  mounted() {
    this.trackEvent(this.hpEventName, PageTypesShorthand.COB);
  },

  methods: {
    async getConsumerInvitation() {
      await this.getInvitation();
      if (!this.invitation || !this.invitation.uuid) return;

      const { app_status_detail, is_soft_pull_api_initiated } = this.invitation;

      if (!app_status_detail) {
        this.showWelcomeScreen = true;
      } else if (app_status_detail !== InvitationApplicationStatusDetails.PRE_WELCOME_SCREEN) {
        this.goToLoanApply();
      } else if (is_soft_pull_api_initiated) {
        if (this.isSoftPullApiProcessEnabled) {
          this.showWelcomeSoftPullInitiated = true;
        } else {
          this.gotoRouteName('error-page');
        }
      }
    },

    goToLoanApply() {
      this.$store.commit('Consumer/setApplicationCanceledData', false);
      this.gotoRouteName('consumer-loan-apply');
    },

    goToPersonalInfoStep() {
      this.$store.commit('Consumer/setApplicationCanceledData', false);
      this.$store.commit('Consumer/setLoanApplyWizardStep', 2);
      this.gotoRouteName('consumer-loan-apply');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/standard-input-field";
@import "@/assets/scss/standard-consent-and-input-notation-text";

@include portal-page-layout;

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.actions-dot-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    &__dot {
      display: flex;
      height: 1rem;
      width: 1rem;
      background-color: var(--grayscale-color-3);
      border-radius: 50%;
      margin: 0 1rem;
    }
  }

.colored-dot {
  background-color: $momnt-navy;
}

.portal {
  text-align: left;

  &__container {
    margin: 0 auto;
    max-width: 28.25rem;
    padding: 0 1rem 1rem 1rem;

    .bubble-card {
      margin-bottom: 2rem;
    }
  }

  p, ul {
    padding: 1.5rem 0;
    margin-bottom: 0 !important;
    color: $momnt-navy !important;
    font-size: 1rem;
    animation: fadeIn 0.3s;
  }

  h1 {
    text-align: center;
    margin: 0 !important;
    padding: 2rem 0 1rem;

    @include mobile {
      font-size: 2rem;
    }
  }

  ul {
    @include list-style-bullet;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }

  .checkbox {
    color: var(--grayscale-color-1);

    + .checkbox {
      margin-top: 1rem;
    }

    :deep(.v-label) {
      padding-bottom: 0;
      font-size: 0.75rem;
      line-height: 150%;
    }

    :deep(.v-selection-control__wrapper) {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  .next-btn {
    margin-top: 2.5rem;
  }
}
</style>
