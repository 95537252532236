import { defineComponent } from 'vue';
import {
  AddressDataInterface,
  ValidatedAddressDataInterface,
} from '@/interfaces/addressValidation/AddressDataInterface';
import validateAddress from '@/validators/address';

export default defineComponent({
  data: () => ({
    showAddressConfirmation: false,
    addressValidationError: '',
    propertyAddressValidationError: '',
    validatedAddress: {
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      countyName: '',
      zipCode: '',
      housing_status: '',
      address_verification_response: {
        analysis: {},
        metadata: {},
        components: {},
      },
    } as ValidatedAddressDataInterface,
    validatedPropertyAddress: {
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      countyName: '',
      zipCode: '',
      housing_status: '',
      address_verification_response: {
        analysis: {},
        metadata: {},
        components: {},
      },
    } as ValidatedAddressDataInterface,
  }),

  methods: {
    async validateAddress(address: AddressDataInterface): Promise<boolean> {
      this.addressValidationError = '';
      const { validatedAddress, errorMsg } = await validateAddress(address);

      if (!validatedAddress) {
        this.addressValidationError = errorMsg as string;
        return false;
      }

      this.validatedAddress = validatedAddress;
      return true;
    },

    async validatePropertyAddress(address: AddressDataInterface): Promise<boolean> {
      this.propertyAddressValidationError = '';
      const { validatedAddress, errorMsg } = await validateAddress(address);

      if (!validatedAddress) {
        this.propertyAddressValidationError = errorMsg as string;
        return false;
      }

      this.validatedPropertyAddress = validatedAddress;
      return true;
    },
  },
});
