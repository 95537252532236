<template>
  <custom-dialog
    dialog
    width="420"
    hide-header-close
    class="success-dialog">
    <template #title>
      <div class="success-title">
        <v-icon size="24" class="success-icon">
          mdi-check-circle
        </v-icon>
        <span>{{ title }}</span>
      </div>
    </template>

    <template #body>
      <p class="pt-4">
        If a consumer is not eligible for the products in an Offer Code,
        they will see the products they qualify for from your Rate Sheet.
      </p>
    </template>

    <template #actions>
      <custom-button
        full-width
        data-test="closeSuccessDialog"
        @click="emit('close')">
        Close
      </custom-button>
    </template>
  </custom-dialog>
</template>

<script setup lang="ts">
import CustomDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

defineProps({
  title: { type: String, required: true },
});

const emit = defineEmits(['close']);
</script>
