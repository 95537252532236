<template>
  <CustomButton
    :disabled="informational"
    class="optimization-button"
    size="small">
    Optimization
    <InfoTriangle fill="var(--error-color)" :size="iconSize" />
  </CustomButton>
</template>

<script setup lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import InfoTriangle from '@/components/Icons/InfoTriangle.vue';
import { useDisplay } from 'vuetify';
import { computed } from 'vue';

defineProps({
  informational: { type: Boolean, default: false },
});

const { xs } = useDisplay();
const iconSize = computed(() => (xs.value ? '16' : '24'));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.custom-button.optimization-button.v-theme--light {
  background-color: #FAE0E0 !important;
  color: var(--error-color) !important;
  border-radius: 0.5rem;

  &.v-btn--disabled:not(.v-btn--variant-text):not(.v-btn--variant-outlined):not(.button-link) {
    background-color: #FAE0E0 !important;
    color: var(--error-color) !important;
  }

  svg {
    margin-left: 0.5rem;
  }

  @include mobile {
    font-size: 0.75rem !important;
  }
}
</style>
