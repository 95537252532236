import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_consumer_loan_apply_wizard_complete_application_later_msg = _resolveComponent("consumer-loan-apply-wizard-complete-application-later-msg")!
  const _component_consumer_loan_apply_wizard_application_canceled_msg = _resolveComponent("consumer-loan-apply-wizard-application-canceled-msg")!
  const _component_consumer_invalid_link_msg = _resolveComponent("consumer-invalid-link-msg")!
  const _component_consumer_expired_link_msg = _resolveComponent("consumer-expired-link-msg")!
  const _component_consumer_loan_apply_wizard_reentry_step = _resolveComponent("consumer-loan-apply-wizard-reentry-step")!
  const _component_request_loan_amount_step = _resolveComponent("request-loan-amount-step")!
  const _component_contact_information_step = _resolveComponent("contact-information-step")!
  const _component_otp_step = _resolveComponent("otp-step")!
  const _component_name_step = _resolveComponent("name-step")!
  const _component_address_step = _resolveComponent("address-step")!
  const _component_personal_information_step = _resolveComponent("personal-information-step")!
  const _component_employment_information_step = _resolveComponent("employment-information-step")!
  const _component_individual_income_step = _resolveComponent("individual-income-step")!
  const _component_docv_verification_step = _resolveComponent("docv-verification-step")!
  const _component_review_information_step = _resolveComponent("review-information-step")!
  const _component_offers_step = _resolveComponent("offers-step")!
  const _component_capture_id_step = _resolveComponent("capture-id-step")!
  const _component_process_loan_step = _resolveComponent("process-loan-step")!
  const _component_loan_acceptance_step = _resolveComponent("loan-acceptance-step")!
  const _component_payment_portal_setup_step = _resolveComponent("payment-portal-setup-step")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_alert_modal = _resolveComponent("alert-modal")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "widgetContentContainer",
    class: _normalizeClass(_ctx.classList)
  }, [
    _createVNode(_component_v_row, {
      justify: "center",
      class: "fs-unmask"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "12",
          sm: "10",
          class: "cob-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              mode: "out-in",
              name: "slide"
            }, {
              default: _withCtx(() => [
                (_ctx.isCompleteLaterClicked)
                  ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_complete_application_later_msg, {
                      key: 0,
                      email: _ctx.email
                    }, null, 8, ["email"]))
                  : (_ctx.applicationCanceled)
                    ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_application_canceled_msg, { key: 1 }))
                    : (_ctx.isInvalidLink)
                      ? (_openBlock(), _createBlock(_component_consumer_invalid_link_msg, { key: 2 }))
                      : (_ctx.expiredLink)
                        ? (_openBlock(), _createBlock(_component_consumer_expired_link_msg, { key: 3 }))
                        : (_ctx.reEntryActivated)
                          ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_reentry_step, {
                              key: 4,
                              onCodeSent: _ctx.navigateToStepThree
                            }, null, 8, ["onCodeSent"]))
                          : (_ctx.wizardStep === 1)
                            ? (_openBlock(), _createBlock(_component_request_loan_amount_step, {
                                key: 5,
                                data: _ctx.data,
                                onNextStep: _ctx.navigateStep
                              }, null, 8, ["data", "onNextStep"]))
                            : (_ctx.wizardStep === 2)
                              ? (_openBlock(), _createBlock(_component_contact_information_step, {
                                  key: 6,
                                  data: _ctx.data,
                                  onNextStep: _ctx.navigateStep
                                }, null, 8, ["data", "onNextStep"]))
                              : (_ctx.wizardStep === 3)
                                ? (_openBlock(), _createBlock(_component_otp_step, {
                                    key: 7,
                                    data: _ctx.data,
                                    "show-header": _ctx.showHeader,
                                    onNextStep: _ctx.navigateStep
                                  }, null, 8, ["data", "show-header", "onNextStep"]))
                                : (_ctx.wizardStep === 4)
                                  ? (_openBlock(), _createBlock(_component_name_step, {
                                      key: 8,
                                      onNextStep: _ctx.navigateStep
                                    }, null, 8, ["onNextStep"]))
                                  : (_ctx.wizardStep === 5)
                                    ? (_openBlock(), _createBlock(_component_address_step, {
                                        key: 9,
                                        onNextStep: _ctx.navigateStep
                                      }, null, 8, ["onNextStep"]))
                                    : (_ctx.wizardStep === 6)
                                      ? (_openBlock(), _createBlock(_component_personal_information_step, {
                                          key: 10,
                                          onNextStep: _ctx.navigateStep
                                        }, null, 8, ["onNextStep"]))
                                      : (_ctx.wizardStep === 7)
                                        ? (_openBlock(), _createBlock(_component_employment_information_step, {
                                            key: 11,
                                            onNextStep: _ctx.navigateStep
                                          }, null, 8, ["onNextStep"]))
                                        : (_ctx.wizardStep === 8 && _ctx.isNewEmploymentFlowEnabled)
                                          ? (_openBlock(), _createBlock(_component_individual_income_step, {
                                              key: 12,
                                              onNextStep: _ctx.navigateStep
                                            }, null, 8, ["onNextStep"]))
                                          : (_ctx.wizardStep === 9)
                                            ? (_openBlock(), _createBlock(_component_docv_verification_step, {
                                                key: 13,
                                                data: _ctx.data,
                                                onNextStep: _ctx.navigateStep
                                              }, null, 8, ["data", "onNextStep"]))
                                            : (_ctx.wizardStep === 10)
                                              ? (_openBlock(), _createBlock(_component_review_information_step, {
                                                  key: 14,
                                                  data: _ctx.data,
                                                  onNextStep: _ctx.navigateStep
                                                }, null, 8, ["data", "onNextStep"]))
                                              : (_ctx.wizardStep === 11)
                                                ? (_openBlock(), _createBlock(_component_offers_step, {
                                                    key: 15,
                                                    data: _ctx.data,
                                                    onNextStep: _ctx.navigateStep
                                                  }, null, 8, ["data", "onNextStep"]))
                                                : (_ctx.wizardStep === 12)
                                                  ? (_openBlock(), _createBlock(_component_capture_id_step, {
                                                      key: 16,
                                                      onNextStep: _ctx.navigateStep
                                                    }, null, 8, ["onNextStep"]))
                                                  : (_ctx.wizardStep === 13)
                                                    ? (_openBlock(), _createBlock(_component_process_loan_step, {
                                                        key: 17,
                                                        onNextStep: _ctx.navigateStep
                                                      }, null, 8, ["onNextStep"]))
                                                    : (_ctx.wizardStep === 14)
                                                      ? (_openBlock(), _createBlock(_component_loan_acceptance_step, {
                                                          key: 18,
                                                          onNextStep: _ctx.navigateStep
                                                        }, null, 8, ["onNextStep"]))
                                                      : (_ctx.wizardStep === 15)
                                                        ? (_openBlock(), _createBlock(_component_payment_portal_setup_step, { key: 19 }))
                                                        : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_alert_modal, {
      dialog: _ctx.showErrorAlertModal,
      "hide-close": true,
      type: "error"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorResponseAlertModal), 1)
      ]),
      _: 1
    }, 8, ["dialog"])
  ], 2))
}