<template>
  <address class="aan-address">
    <p class="mb-0 font-italic">
      <strong>{{ brandData.brand_name }}</strong>
    </p>
    <p
      v-for="(address, i) in serviceAddress"
      :key="i"
      class="mb-0 font-italic">
      <strong>{{ address }}</strong>
    </p>
    <p class="mb-0">
      <a
        v-fs-exclude
        :href="`mailto:${brandData.footer_object.support_email}`"
        class="aan-address__link">
        {{ brandData.footer_object.support_email }}
      </a>
    </p>
    <p>
      <a
        v-fs-exclude
        :href="`tel:${brandData.footer_object.support_number}`"
        class="aan-address__link">
        {{ brandData.footer_object.support_number }}
      </a>
    </p>
  </address>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AANAddress',
  props: {
    brandData: { default: null, type: Object },
  },
  data() {
    return {
      serviceAddress: null,
    };
  },
  watch: {
    brandData: {
      handler() {
        this.serviceAddress = this.brandData.servicing_address.split(',');
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/standard-aan";
</style>
