<template>
  <div class="fs-unmask standard-wizard__step" data-test="COB-individualIncomeStep">
    <bubble-card>
      <template #card-title>
        <h5 data-test="employmentInfoHeaderLabel">
          Annual Household Income
        </h5>
      </template>

      <p>
        Please tell us how much you make in a year, before taxes.
        You can include wage income, bonus, commissions, and all other income.
      </p>

      <span class="d-block mb-6">
        <strong>This information may be verified later.</strong>
      </span>

      <annual-gross-input
        v-model="annualIncome"
        :disabled="processing"
        hint="Alimony, child support, or separate maintenance income need not be revealed if
        you do not wish to have it considered as a basis for repaying this loan obligation."
        class="household-income"
        initial-modal
        show-icon />

      <custom-button
        :disabled="isDisabled"
        class="mt-6"
        full-width
        data-test="continueBtn"
        @click="sendAnnualIncome">
        Continue
      </custom-button>
    </bubble-card>

    <complete-later-button />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useHoneypot } from '@/composables/useHoneypot';
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import AnnualGrossInput from '@/components/Consumer/LoanApplyWizard/AnnualGrossInput/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { PageTypesShorthand } from '@/enums/PageTypes';
import { skipDocumentVerification } from '@/api/consumer';

const emit = defineEmits<{(event: 'nextStep', nextStep: number): void;}>();

const store = useStore();
const { trackEvent } = useHoneypot();

const ownStep = 8;
const hpStep = 8;
const hpEventName = 'Individual Income Page Visit';
const annualIncome = ref<string | number>('');
const disabled = ref(false);
const processing = ref(false);

const stepData: LoanApplyDataInterface = {
  gross_income: '',
};

const isGrossIncomeValid = computed(() => Number(annualIncome.value) > 0);
const isDisabled = computed(() => !isGrossIncomeValid.value || disabled.value);
const applicationId = computed(() => store.getters['Consumer/getConsumerApplicationId']);

const setAnnualIncome = async (): Promise<number> => {
  const { status } = await store.dispatch('Consumer/setAnnualIncome', Number(annualIncome.value));
  return status;
};

const sendAnnualIncome = async () => {
  processing.value = true;
  disabled.value = true;

  stepData.gross_income = annualIncome.value;
  const status = await setAnnualIncome();
  store.commit('Consumer/setLoanApplyWizardData', stepData);

  if (status === 200) {
    const skipDocvResponse = await skipDocumentVerification(applicationId.value);
    if (skipDocvResponse.data.skip) {
      emit('nextStep', 10);
    } else {
      emit('nextStep', ownStep + 1);
    }
  } else {
    processing.value = false;
    disabled.value = false;
  }
};

onMounted(() => {
  trackEvent(hpEventName, PageTypesShorthand.COB, hpStep);
});
</script>

<style lang="scss" scoped>
.income-input :deep(.standard-input-field__input-group) {
  height: 3rem;
}

:deep(.household-income) {
  .standard-input-field {
    margin-bottom: .5rem;
  }
}

p, span {
  color: var(--grayscale-color-1);
}
</style>
