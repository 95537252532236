import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_currency_input = _resolveComponent("currency-input")!
  const _component_info_dialog = _resolveComponent("info-dialog")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_currency_input, _mergeProps({ ref: "amountInput" }, _ctx.$attrs, {
      "append-inner-icon": _ctx.disabled ? '' : 'mdi-help-circle',
      disabled: _ctx.disabled,
      "data-test": "householdAnnualInput",
      label: "Annual Household Income",
      required: _ctx.isRequired,
      "error-messages": _ctx.errorMessages,
      "model-value": _ctx.modelValue,
      hint: _ctx.hint,
      onBlur: _ctx.checkAmount,
      "onClick:appendInner": _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfoDialog = true)),
      "onUpdate:modelValue": _ctx.onAmountInput
    }), null, 16, ["append-inner-icon", "disabled", "required", "error-messages", "model-value", "hint", "onBlur", "onUpdate:modelValue"]),
    (_ctx.showInfoDialog)
      ? (_openBlock(), _createBlock(_component_info_dialog, {
          key: 0,
          show: true,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showInfoDialog = false))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_confirmation_dialog, {
      show: _ctx.showConfirmationDialog,
      value: _ctx.modelValue,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showConfirmationDialog = false)),
      onConfirm: _ctx.confirmAmount,
      onEdit: _ctx.selectAmountField
    }, null, 8, ["show", "value", "onConfirm", "onEdit"])
  ]))
}