import { MerchantPortalStateInterface } from '@/interfaces/states/MerchantPortalStateInterface';
import { MerchantUserInterface } from '@/interfaces/states/MerchantUserInterface';
import { NewMerchantUserDataInterface } from '@/interfaces/merchantPortal/NewMerchantUserDataInterface';
import { NewMerchantUserInterface } from '@/interfaces/merchantPortal/NewMerchantUserInterface';
import { PhoneNumberSetInterface } from '@/interfaces/PhoneNumberSetInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { OfferCode } from '@/interfaces/merchantPortal/OfferCode';
import { TransactionsDescriptionsInterface } from '@/interfaces/merchantPortal/TransactionsDescriptionsInterface';
import { StatementDetailsInterface } from '@/interfaces/statements/StatementDetailsInterface';
import { StatementSummaryInterface } from '@/interfaces/statements/StatementSummaryInterface';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import { MerchantLocation } from '@/interfaces/merchant/MerchantLocation';
import { SponsorPortalStateInterface } from '@/interfaces/merchantPortal/Sponsor';
import { RateSheetOptimizationSuggestion } from '@/interfaces/rates/RateSheetOptimization';
import { useChargeRequestLabel } from '@/composables/useChargeRequestLabel';

type State = MerchantPortalStateInterface;

export function setTransactionsDescriptions(
  state: State,
  payload: Array<TransactionsDescriptionsInterface>,
) {
  state.transactionsDescriptions = payload;
}

export function addFullName(payload: any) {
  payload.results.forEach((el: any) => {
    el.full_name = `${el.submitted_by_first_name} ${el.submitted_by_last_name}`;
    return el;
  });
}

export function setNewLoanApplications(state: State, payload: any) {
  // Concatenate user's first and last name
  addFullName(payload);

  state.newLoanApplications = payload.results;
  state.newLoanApplicationsCount = payload.count;
}

export function setAvailableTransactions(state: State, payload: any) {
  // Concatenate user's first and last name
  addFullName(payload);

  state.availableTransactions = payload.results;
  state.availableTransactionsCount = payload.count;
}

export function setNewLoanInvitations(state: State, payload: any) {
  state.newLoanInvitations = payload.results;
  state.newLoanInvitationsCount = payload.count;
}

export function setSelectedLoanApplication(state: State, payload: any) {
  state.selectedLoanApplication = null;

  if (payload) {
    state.selectedLoanApplication = payload;
  }

  if (!state.selectedLoanApplication || state.selectedLoanApplication.consumer_account === null) {
    state.selectedLoanApplication.consumer_account = {
      is_valid_for_transaction: false,
      not_valid_for_transaction_message: 'Customer does not have an approved and accepted loan at this time',
    };
  }
}

export function deSelectLoanApplicationFromStore(state: State) {
  state.selectedLoanApplication = {};
}

export function setRecentTransactions(state: State, payload: any) {
  // Concatenate user's first and last name
  addFullName(payload);

  state.recentTransactionsCount = payload.count;
  state.recentTransactions = payload.results;
}

export function setPaymentRequests(state: State, payload: any) {
  state.paymentRequests = payload;
}

export function setRequestsTransactions(state: State, payload: any) {
  let data: Array<any> = [];
  data = payload.paymentRequests.results;
  const { chargeRequestLabel } = useChargeRequestLabel();

  data.forEach(el => {
    if (!el.date_time) {
      el.full_name = el.requested_by;
      if (typeof el.transaction_description === 'number') {
        el.transaction_description = chargeRequestLabel.value;
      }
      el.date_time = el.request_date;
      el.status = el.payment_request_status;
      el.transaction_amount = el.consumer_charge_amount;
    }
    return el;
  });
  state.paymentRequestsCount = payload.paymentRequests.count;
  state.paymentRequests = data;
}

export function setRateSheets(state: State, payload: Array<RateSheetData>) {
  state.rateSheets = payload;
}

export function enableRateSheetSave(state: State, payload: boolean) {
  state.rateSheetSave = payload;
}

export function setMerchantUsers(state: State, payload: Array<MerchantUserInterface>) {
  state.merchantUsers = payload;
}

export function setNewMerchantUserData(state: State, payload: NewMerchantUserDataInterface) {
  state.newMerchantUserData = payload;
}

export function setNewMerchantUser(state: State, payload: NewMerchantUserInterface) {
  state.newMerchantUserData.newUser = payload;
}

export function setNewMerchantUserPhoneNumber(state: State, payload: PhoneNumberSetInterface) {
  state.newMerchantUserData.newPhoneNumber = payload;
}

export function setRefundableTransactions(state: State, payload: any) {
  state.refundableTransactions = payload;
}

export function setSearchedItems(state: State, payload: any) {
  state.searchedItems = payload;
}

export function setSearchInput(state: State, payload: string) {
  state.searchInput = payload;
}

export function resetSearchInput(state: State) {
  state.searchInput = '';
}

export function setStatementDetails(state: State, payload: StatementDetailsInterface) {
  state.statementDetails = payload;
}

export function setStatementSummary(state: State, payload: Array<StatementSummaryInterface>) {
  state.statements = payload;
}

export function setMerchantData(state: State, payload: any) {
  state.merchantData = payload;
}

export function setRateSheetExpandableCategory(state: State, payload: number) {
  state.rateSheetExpandableCategory = payload;
}

export function showResetPwMsg(state: State, payload: boolean) {
  state.showResetPasswordMsg = payload;
}

export function setMerchantApplicationUuid(state: State, uuid: string) {
  state.merchantApplicationUuid = uuid;
}

export function setRecentlyApprovedConsumerAppId(state: State, payload: string) {
  state.recentlyApprovedConsumerAppId = payload;
}

export function setShowProductAndPricingDialogJuly(state: State, payload: boolean) {
  state.showProductAndPricingDialogJuly = payload;
}

export function setShowProductAndPricingDialogSeptember(state: State, payload: boolean) {
  state.showProductAndPricingDialogSeptember = payload;
}

export function setShowHealthCareDialogOctober(state: State, payload: boolean) {
  state.showHealthCareDialogOctober = payload;
}

export function setAprCapComplianceDialog(state: State, payload: boolean) {
  state.showAprCapComplianceDialog = payload;
}

export function setShowHomeImprovementDialog(state: State, payload: boolean) {
  state.showHomeImprovementDialog = payload;
}

export function setPaymentProviders(state: State, payload: PaymentProvidersInterface) {
  state.paymentProviders = payload;
}

export function setBankName(state: State, payload: string) {
  state.bankName = payload;
}

export function setAlertData(state: State, payload: any) {
  state.alertData = payload;
}

export function setMerchantLocation(state: State, payload: MerchantLocation) {
  // `false` indicates that locations are loaded but user does not have one
  state.location = payload || false;
}

export function setMerchantPrimaryLocation(state: State, payload: MerchantLocation) {
  state.primaryLocation = payload;
}

export function setMerchantLocations(state: State, payload: MerchantLocation[]) {
  state.locations = payload;
}

export function setSponsorData(state: State, payload: SponsorPortalStateInterface) {
  state.merchantPortalAccess = payload.merchantPortalAccess;
  state.isIC1Sponsored = payload.isIC1Sponsored;
}
export function setRateSheetOptimizationSuggestions(
  state: State,
  payload: RateSheetOptimizationSuggestion[],
) {
  state.rateSheetOptimizationSuggestions = payload;
  const rsProductNames = new Set<string>();
  const offerCodeProductNames: { [key: string]: Set<string> } = {};

  for (const suggestion of payload) {
    if (suggestion.offer_code) {
      const key = suggestion.offer_code.id.toString();
      if (!offerCodeProductNames[key]) {
        offerCodeProductNames[key] = new Set<string>();
      }
      for (const product of suggestion.products) {
        offerCodeProductNames[key].add(product.name);
      }
    } else {
      for (const product of suggestion.products) {
        rsProductNames.add(product.name);
      }
    }
  }
  state.rateSheetOptimizationSuggestionsProductNames = rsProductNames;
  state.offerCodeOptimizationSuggestionsProductNames = offerCodeProductNames;
  state.rateSheets.forEach(rateSheet => {
    rateSheet.has_optimization = rsProductNames.has(rateSheet.product_name);
  });
}

export function setOfferCodes(state: State, payload: Array<OfferCode>) {
  state.offerCodes = payload;
}
