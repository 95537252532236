import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75c79e7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "offer-code-table" }
const _hoisted_2 = { class: "body-text-large" }
const _hoisted_3 = { class: "product-attributes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_actions = _resolveComponent("header-actions")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_header_with_tooltip = _resolveComponent("header-with-tooltip")!
  const _component_FallbackTooltip = _resolveComponent("FallbackTooltip")!
  const _component_SeventyFiveKTooltip = _resolveComponent("SeventyFiveKTooltip")!
  const _component_InfoTriangle = _resolveComponent("InfoTriangle")!
  const _component_preferences = _resolveComponent("preferences")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_custom_dialog = _resolveComponent("custom-dialog")!
  const _component_products_form_dialog = _resolveComponent("products-form-dialog")!
  const _component_name_form_dialog = _resolveComponent("name-form-dialog")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_alert_modal = _resolveComponent("alert-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "offer-code-table--title" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
            _createVNode(_component_header_actions, {
              active: _ctx.is_active,
              "onToggle:active": _ctx.updateActive,
              "onClick:editProducts": _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditProductsDialog = true)),
              "onClick:editName": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEditNameDialog = true)),
              "onClick:delete": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDeleteDialog = true))
            }, null, 8, ["active", "onToggle:active"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.rows,
              headers: _ctx.headers,
              "row-props": _ctx.getRowProps,
              mobile: _ctx.isMobile,
              "hide-default-footer": ""
            }, {
              "header.product_name": _withCtx(({ column }) => [
                _createVNode(_component_header_with_tooltip, {
                  title: column.title,
                  align: column.align,
                  tooltip: column.tooltip,
                  onClick: ($event: any) => (_ctx.activeTooltip = column)
                }, null, 8, ["title", "align", "tooltip", "onClick"])
              ]),
              "header.full_term": _withCtx(({ column }) => [
                _createVNode(_component_header_with_tooltip, {
                  title: column.title,
                  align: column.align,
                  tooltip: column.tooltip,
                  onClick: ($event: any) => (_ctx.activeTooltip = column)
                }, null, 8, ["title", "align", "tooltip", "onClick"])
              ]),
              "header.apr": _withCtx(({ column }) => [
                _createVNode(_component_header_with_tooltip, {
                  title: column.title,
                  align: column.align,
                  tooltip: column.tooltip,
                  onClick: ($event: any) => (_ctx.activeTooltip = column)
                }, null, 8, ["title", "align", "tooltip", "onClick"])
              ]),
              "header.merchant_fee": _withCtx(({ column }) => [
                _createVNode(_component_header_with_tooltip, {
                  title: column.title,
                  align: column.align,
                  tooltip: column.tooltip,
                  onClick: ($event: any) => (_ctx.activeTooltip = column)
                }, null, 8, ["title", "align", "tooltip", "onClick"])
              ]),
              "item.product_attributes": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_3, [
                  (item.has_fallback)
                    ? (_openBlock(), _createBlock(_component_FallbackTooltip, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.is75kProduct(item))
                    ? (_openBlock(), _createBlock(_component_SeventyFiveKTooltip, { key: 1 }))
                    : _createCommentVNode("", true),
                  (item.has_optimization)
                    ? (_openBlock(), _createBlock(_component_InfoTriangle, {
                        key: 2,
                        fill: "var(--error-color)"
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              "item.customer_preferences": _withCtx(({ item }) => [
                _createVNode(_component_preferences, {
                  class: "text-center",
                  "customer-preferences": item.customer_preferences
                }, null, 8, ["customer-preferences"])
              ]),
              _: 1
            }, 8, ["items", "headers", "row-props", "mobile"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.activeTooltip)
      ? (_openBlock(), _createBlock(_component_custom_dialog, {
          key: 0,
          dialog: true,
          "show-actions": false,
          title: _ctx.activeTooltip.text,
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeTooltip = null))
        }, {
          body: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.activeTooltip.tooltip), 1)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.showEditProductsDialog)
      ? (_openBlock(), _createBlock(_component_products_form_dialog, {
          key: 1,
          editing: "",
          name: _ctx.name,
          "rate-sheets": _ctx.offerRateSheet,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showEditProductsDialog = false)),
          onSave: _ctx.updateSelection
        }, null, 8, ["name", "rate-sheets", "onSave"]))
      : _createCommentVNode("", true),
    (_ctx.showEditNameDialog)
      ? (_openBlock(), _createBlock(_component_name_form_dialog, {
          key: 2,
          editing: "",
          "existing-name": _ctx.name,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showEditNameDialog = false)),
          onSave: _ctx.updateName
        }, null, 8, ["existing-name", "onSave"]))
      : _createCommentVNode("", true),
    (_ctx.showDeleteDialog)
      ? (_openBlock(), _createBlock(_component_custom_dialog, {
          key: 3,
          "content-class": "delete-offer-code-dialog",
          dialog: true,
          title: "Please Confirm",
          width: "21.25rem",
          onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showDeleteDialog = false))
        }, {
          body: _withCtx(() => [
            _createElementVNode("span", null, "Would you like to delete offer code \"" + _toDisplayString(_ctx.name) + "\"?", 1)
          ]),
          actions: _withCtx(() => [
            _createVNode(_component_custom_button, {
              size: "small",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDeleteDialog = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Cancel ")
              ]),
              _: 1
            }),
            _createVNode(_component_custom_button, {
              size: "small",
              onClick: _ctx.deleteCode
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Delete ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_alert_modal, {
      type: "error",
      dialog: _ctx.showErrorAlert,
      "on-close": _ctx.hideErrorAlert
    }, {
      default: _withCtx(() => [
        _createTextVNode(" We’re sorry. An error occurred while trying to perform this function. Please try again later. ")
      ]),
      _: 1
    }, 8, ["dialog", "on-close"])
  ]))
}