<template>
  <div>
    <div class="standard-wizard__step" data-test="COB-captureIdStep">
      <div>
        <h6 class="identification-title">
          Add Identification
        </h6>
        <p v-if="!stepData.id_type" class="identification-text">
          One form of non-expired government ID is required to proceed.
        </p>

        <select-input
          v-model="stepData.id_type"
          :items="identificationTypes"
          label="Identification Type"
          item-title="name"
          item-value="ordinal"
          hide-dropdown-icon
          :error-messages="errors.id_type"
          @update:model-value="onSelectInput" />

        <div v-if="shouldInputDetails">
          <autocomplete-input
            v-if="stateRequired"
            v-model="stepData.id_state"
            autoselect-match
            :filter="customStateFilter"
            hide-dropdown-icon
            :items="USA_STATES"
            label="State"
            :required="stateRequired"
            :attach="false"
            :error-messages="errors.id_state"
            @input="clearErrors" />

          <text-input
            v-model="stepData.id_number"
            required
            label="ID Number"
            maxlength="50"
            :error-messages="errors.id_number"
            @input="clearErrors"
            @keydown="checkIfAlphaNumeric" />

          <date-input
            v-model="stepData.id_issue_date"
            :min="minIssueDate"
            :max="currentDate"
            required
            label="ID Issue Date"
            :error-messages="errors.id_issue_date"
            @input="clearErrors" />

          <custom-alert v-show="showExpiredIDMessages" type="error">
            Your ID is expired. If your expiration date is incorrect,
            please correct the date. You can also select a new ID Type above.
          </custom-alert>

          <date-input
            v-model="stepData.id_expiration_date"
            :rules="expirationRules"
            required
            label="ID Expiration Date"
            :error-messages="errors.id_expiration_date"
            @input="onExpirationInput(); clearErrors()" />

          <checkbox-input v-if="showExpiredIDMessages" v-model="hasNoValidIDChecked">
            <template #label>
              I don't have a valid ID
            </template>
          </checkbox-input>
        </div>

        <custom-button
          data-test="continueBtn"
          :disabled="!canContinue"
          class="mt-4"
          full-width
          label="Continue"
          @click="onContinue" />
      </div>

      <confirm-dialog
        :show-confirm-dialog="showNoIDConfirmationDialog"
        :on-confirm="nextStep"
        :on-cancel="hideNoIdConfirmDialog"
        title-text="Please confirm">
        <template #body>
          Are you sure you don’t have a valid id?<br>
          If you proceed, your application will be canceled.
        </template>
      </confirm-dialog>
    </div>

    <complete-later-button class="ma-0" />
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import TextInput from '@/components/Inputs/Text.vue';
import DateInput from '@/components/Inputs/Date.vue';
import GetProcessing from '@/mixins/GetProcessing';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import SelectInput from '@/components/Inputs/Select.vue';
import AutocompleteInput from '@/components/Inputs/Autocomplete.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import USA_STATES from '@/constants/UsaStatesConstants';
import validateDate from '@/validators/date';
import DATE_CONSTANTS from '@/constants/DateConstants';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import CheckboxInput from '@/components/Inputs/Checkbox.vue';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog.vue';
import { saveIdSensitive } from '@/api/consumer';
import ID_TYPES from '@/constants/IdentificationTypesConstants';
import {
  IdentificationTypeInterface,
} from '@/interfaces/consumer/loanApply/IdentificationTypeInterface';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'CaptureIdStep',
  components: {
    TextInput,
    DateInput,
    SelectInput,
    AutocompleteInput,
    CustomButton,
    CustomAlert,
    CheckboxInput,
    ConfirmDialog,
    CompleteLaterButton,
  },
  mixins: [
    GetProcessing,
    IdleTimeoutMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
  ],
  data() {
    return {
      ownStep: 12,
      stepData: {
        id_type: '',
        id_state: '',
        id_number: '',
        id_issue_date: '',
        id_expiration_date: '',
      },
      hasNoValidIDChecked: false,
      showNoIDConfirmationDialog: false,
      errors: {
        id_type: [],
        id_state: [],
        id_number: [],
        id_issue_date: [],
        id_expiration_date: [],
      },
      hpEventName: 'Capture ID Page Visit',
      hpStep: 12,
    };
  },
  computed: {
    currentDate(): string {
      return moment().format(DATE_CONSTANTS.dateFormat);
    },
    minIssueDate(): string {
      return moment().subtract(30, 'years').format(DATE_CONSTANTS.dateFormat);
    },
    USA_STATES() {
      return USA_STATES;
    },
    identificationTypes() {
      return this.$store.getters['Consumer/getIdTypes'];
    },
    canContinue(): boolean {
      if (Number(this.stepData.id_type) === ID_TYPES.NO_VALID_ID) return true;

      return !!(this.stepData.id_type
        && (this.stateRequired ? this.stepData.id_state : true)
        && this.stepData.id_number
        && validateDate(this.stepData.id_issue_date!, { minDate: this.minIssueDate }).isValid
        && this.isExpirationDateValid());
    },
    stateRequired(): boolean {
      return Number(this.stepData.id_type) === ID_TYPES.STATE_DRIVERS_LICENCE
      || Number(this.stepData.id_type) === ID_TYPES.STATE_ID_CARD;
    },
    hasValidID(): boolean {
      const { id_type } = this.stepData;
      return !!id_type && Number(id_type) !== ID_TYPES.NO_VALID_ID && !this.hasNoValidIDChecked;
    },
    shouldInputDetails(): boolean {
      const { id_type } = this.stepData;
      return !!id_type && Number(id_type) !== ID_TYPES.NO_VALID_ID;
    },
    showExpiredIDMessages(): boolean {
      if (!this.isExpirationEntered()) return false;

      return this.validateExpirationDate(this.stepData.id_expiration_date) === true
        && this.isExpirationBeforeToday(this.stepData.id_expiration_date) !== true;
    },
    brandData(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    expirationRules() {
      if (!this.hasValidID) {
        return [this.validateExpirationDate];
      }

      return [this.validateExpirationDate, this.isExpirationBeforeToday];
    },
  },
  created() {
    this.$store.dispatch('Consumer/getAvailableIdTypes');
  },
  mounted() {
    this.$emit('hideCompleteLaterBtn', false);

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    customStateFilter(item: any, query: string): boolean {
      const { label, value } = item;
      const searchTerm = query.toLowerCase();
      return label.toLowerCase().includes(searchTerm) || value.toLowerCase().includes(searchTerm);
    },
    isExpirationDateValid(): boolean {
      if (this.validateExpirationDate(this.stepData.id_expiration_date) === true) {
        if (this.hasValidID) {
          return this.isExpirationBeforeToday(this.stepData.id_expiration_date) === true;
        }

        return true;
      }

      return false;
    },
    onContinue() {
      if (!this.hasValidID) {
        this.showNoIdConfirmDialog();
      } else {
        this.nextStep();
      }
    },
    showNoIdConfirmDialog() {
      this.showNoIDConfirmationDialog = true;
    },
    hideNoIdConfirmDialog() {
      this.showNoIDConfirmationDialog = false;
    },
    isExpirationEntered(): boolean {
      if (!this.stepData.id_expiration_date) return false;

      return !this.stepData.id_expiration_date.includes('_');
    },
    canAcceptNonExpirableId(): boolean {
      const noExpirationIdTypes = [
        ID_TYPES.STATE_ID_CARD,
        ID_TYPES.STATE_DRIVERS_LICENCE,
      ];

      return noExpirationIdTypes.includes(Number(this.stepData.id_type));
    },
    validateExpirationDate(value: string) {
      if (this.canAcceptNonExpirableId() && value === '00-00-0000') return true;

      const tomorrow = moment(this.stepData.id_issue_date, DATE_CONSTANTS.dateFormat)
        .add(1, 'day')
        .format(DATE_CONSTANTS.dateFormat);

      const isAfterIssue = validateDate(value, { minDate: tomorrow });

      if (!isAfterIssue.isValid) return isAfterIssue.message;

      return true;
    },
    isExpirationBeforeToday(value: string) {
      if (this.canAcceptNonExpirableId() && value === '00-00-0000') return true;

      const isExpired = validateDate(value, { minDate: this.currentDate });

      if (!isExpired.isValid) return isExpired.message;

      return true;
    },
    onExpirationInput() {
      if (!this.isExpirationEntered()) {
        this.hasNoValidIDChecked = false;
      }
    },
    checkIfAlphaNumeric(event: KeyboardEvent) {
      const { key } = event;
      const alphaNumeric = /^[0-9a-zA-Z]+$/;

      if (!key.match(alphaNumeric)) {
        event.preventDefault();
      }
    },
    changedItem() {
      this.stepData.id_state = '';
      this.stepData.id_number = '';
      this.stepData.id_issue_date = '';
      this.stepData.id_expiration_date = '';
      this.hideNoIdConfirmDialog();
      this.hasNoValidIDChecked = false;
    },
    async nextStep() {
      this.hideNoIdConfirmDialog();

      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);

      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];

      try {
        await saveIdSensitive(appId, {
          id_type: this.identificationTypes
            .find((idt: IdentificationTypeInterface) => {
              return idt.ordinal === Number(this.stepData.id_type);
            }).id,
          id_state: this.stepData.id_state,
          id_number: this.stepData.id_number,
          id_issue_date: this.stepData.id_issue_date,
          id_expiration_date: this.stepData.id_expiration_date !== '00-00-0000'
            ? this.stepData.id_expiration_date
            : '01-01-2100',
        });

        if (this.hasValidID) {
          this.goToStep(this.ownStep + 1);
        } else {
          this.cancelApplication();
        }
      } catch (error: any) {
        if (error.response.status === 400) {
          this.errors = error.response.data;
        } else {
          this.$router.push({ name: 'error-page' });
        }
      }
    },
    cancelApplication() {
      const merchantName = this.$store.getters['Consumer/getInvitation'].merchant_name;
      this.$store.commit('Consumer/setApplicationCanceledData', { canceled: true, merchantName });
    },
    clearErrors() {
      this.errors = {
        id_type: [],
        id_state: [],
        id_number: [],
        id_issue_date: [],
        id_expiration_date: [],
      };
    },
    onSelectInput() {
      this.changedItem();
      this.clearErrors();
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/inline-inputs";
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/_custom-transitions";

.standard-wizard__step {
  margin-bottom: 3rem !important;

  .identification-title {
    margin: 1rem 0;
  }

  .identification-text {
    font-size: 1rem;
  }
}
</style>
