<template>
  <div class="fs-unmask portal-header__dropdown">
    <v-btn
      class="menu-icon"
      icon="mdi-menu"
      variant="plain"
      height="1.25rem"
      :ripple="false"
      @click="menuVisible = true" />

    <transition name="nested-slide-left">
      <div
        v-if="menuVisible"
        class="mobile-menu-dialog"
        @click="menuVisible = false">
        <div class="mobile-menu-content transition-inner" @click.stop>
          <v-btn
            icon="mdi-close"
            variant="plain"
            aria-label="Close"
            class="close-btn"
            @click.stop="menuVisible = false" />

          <ul>
            <li v-for="(item, index) in merchantHeaderItems" :key="index">
              <custom-button
                class="header-link"
                :data-test="getDataTestAttribute(item.to)"
                :disabled="!item.to"
                :to="getRouteData(item.to)"
                variant="link"
                @click.stop="menuVisible = false">
                <div v-if="item.name === 'Admin' && cannotTransact">
                  <v-badge
                    location="left"
                    class="warning-badge"
                    color="var(--error-color)"
                    icon="mdi-exclamation-thick">
                    {{ item.name }}
                  </v-badge>
                </div>
                <div v-else-if="item.name === 'Rate Sheet' && hasRSOptimizationSuggestions">
                  <v-badge
                    location="left"
                    class="warning-badge rate-sheet-badge"
                    color="var(--error-color)"
                    icon="mdi-exclamation-thick">
                    {{ item.name }}
                  </v-badge>
                </div>
                <div v-else>
                  {{ item.name }}
                </div>
              </custom-button>
            </li>
            <li>
              <thinkific-link class="header-link" />
            </li>
          </ul>

          <portal-header-actions-mobile-footer />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import PortalHeaderActionsMobileFooter
  from '@/components/Merchant/Portal/Header/ActionsMobileFooter.vue';
import PortalHeaderActionsMixin from '@/mixins/PortalHeaderActionsMixin';
import ThinkificLink from '@/components/Merchant/Portal/Header/ThinkificLink.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActionsMobile',
  components: {
    ThinkificLink,
    CustomButton,
    PortalHeaderActionsMobileFooter,
  },
  mixins: [PortalHeaderActionsMixin],

  data() {
    return {
      menuVisible: false,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/transitions/nested-slide-left";
@import "@/assets/scss/components/custom-badge";

:deep(.menu-icon) {
  .v-icon {
    font-size: 2rem !important;
  }
}

.mobile-menu-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.5);

  .mobile-menu-content {
    position: absolute;
    right: 0;
    width: 18rem;
    background-color: #FFFFFF;

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }

    ul {
      padding: 0.75rem;
    }
  }
}
</style>
