<template>
  <div class="section-header simple-card__header">
    <div class="section-header__title simple-card__header-text-wrapper">
      <div>
        <strong>
          {{ headerTitle }}
        </strong>
      </div>

      <div class="simple-card__header__info">
        <span data-test="allProductsLabel">
          *All products have a 5 month purchase window starting from approval
        </span>
      </div>
    </div>

    <div class="actions">
      <template v-if="showOptimizationButton">
        <OptimizationButton @click.stop="openOptimizationBar" />
        <v-divider class="divider" vertical />
      </template>
      <rate-sheet-options-header-info-modal
        :show="show"
        :on-click="showModal"
        :on-cancel="closeModal"
        class="simple-card__header__button" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RateSheetOptionsHeaderInfoModal from '@/components/RateSheetOptions/HeaderInfoModal.vue';
import OptimizationButton from '@/components/Merchant/Portal/RateSheets/Optimizations/OptimizationButton.vue';

export default defineComponent({
  name: 'RateSheetOptionsHeader',

  components: { OptimizationButton, RateSheetOptionsHeaderInfoModal },

  props: {
    headerTitle: { type: String, default: '' },
  },

  data: () => ({
    show: false,
  }),

  computed: {
    showOptimizationButton(): boolean {
      return !!this.$store.getters['MerchantPortal/getOptimizationSuggestionsProductNames'].size;
    },
  },

  methods: {
    showModal(): void {
      this.show = true;
    },

    closeModal(): void {
      this.show = false;
    },

    openOptimizationBar() {
      this.$emit('openOptimizationBar');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-data-table";
@import "@/assets/scss/portal-section";
@import '@/assets/scss/mixins/media_queries';

.simple-card__header {
  white-space: normal !important;
  background: var(--grayscale-color-5) !important;

  .actions {
    display: flex;
    align-items: center;
    .divider {
      align-self: center;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      width: 1px;
      height: 1.5rem;
      opacity: 1;
      color: var(--grayscale-color-2);
    }
  }

  .simple-card__header-text-wrapper {
    width: 97%;

    .simple-card__header__info {
     margin: auto;

     @include mobile {
       font-size: 0.75rem;
     }
    }
  }
}

@include up-to-desktop {
  .section-header {
    flex-wrap: wrap;

    .actions {
      margin-top: 1rem;
    }
  }
}

@include mobile {
  .section-header__title {
    align-items: flex-start;

    .actions {
      margin-top: 1rem;
    }
  }
}
</style>
