import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2952971c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-unmask portal-header__dropdown" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_thinkific_link = _resolveComponent("thinkific-link")!
  const _component_portal_header_actions_mobile_footer = _resolveComponent("portal-header-actions-mobile-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      class: "menu-icon",
      icon: "mdi-menu",
      variant: "plain",
      height: "1.25rem",
      ripple: false,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuVisible = true))
    }),
    _createVNode(_Transition, { name: "nested-slide-left" }, {
      default: _withCtx(() => [
        (_ctx.menuVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "mobile-menu-dialog",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.menuVisible = false))
            }, [
              _createElementVNode("div", {
                class: "mobile-menu-content transition-inner",
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_v_btn, {
                  icon: "mdi-close",
                  variant: "plain",
                  "aria-label": "Close",
                  class: "close-btn",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.menuVisible = false), ["stop"]))
                }),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.merchantHeaderItems, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createVNode(_component_custom_button, {
                        class: "header-link",
                        "data-test": _ctx.getDataTestAttribute(item.to),
                        disabled: !item.to,
                        to: _ctx.getRouteData(item.to),
                        variant: "link",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.menuVisible = false), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          (item.name === 'Admin' && _ctx.cannotTransact)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_v_badge, {
                                  location: "left",
                                  class: "warning-badge",
                                  color: "var(--error-color)",
                                  icon: "mdi-exclamation-thick"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : (item.name === 'Rate Sheet' && _ctx.hasRSOptimizationSuggestions)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createVNode(_component_v_badge, {
                                    location: "left",
                                    class: "warning-badge rate-sheet-badge",
                                    color: "var(--error-color)",
                                    icon: "mdi-exclamation-thick"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.name), 1))
                        ]),
                        _: 2
                      }, 1032, ["data-test", "disabled", "to"])
                    ]))
                  }), 128)),
                  _createElementVNode("li", null, [
                    _createVNode(_component_thinkific_link, { class: "header-link" })
                  ])
                ]),
                _createVNode(_component_portal_header_actions_mobile_footer)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}