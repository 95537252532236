<template>
  <div class="offer-codes" data-test="offerCodes">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="offer-codes--title">
            <h3 class="subheader">
              Offer Codes
            </h3>
            <div v-if="rsOptimizationsEnabled" class="actions">
              <template v-if="hasOptimizationSuggestions">
                <OptimizationButton @click.stop="$emit('openOptimizationBar')" />
                <v-divider class="divider" vertical />
              </template>
              <HelpButton @click.stop="showInfoDialog = true" />
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <custom-button
            variant="secondary"
            class="offer-codes--create-btn"
            data-test="createOfferCodeBtn"
            @click="showCreateDialog = true">
            Create New Offer Code
          </custom-button>

          <offer-code-table
            v-for="offerCode in offerCodes"
            :key="offerCode.id"
            v-bind="offerCode"
            class="offer-codes--table"
            @updated="onUpdate"
            @deactivated="onChange"
            @deleted="onChange" />
          <div
            v-if="!offerCodes.length"
            class="offer-codes-text text-center">
            <span class="font-italic" data-test="noOfferCodeLabel">You have no offer codes.</span>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <create-offer-code
      v-if="showCreateDialog"
      @created="onUpdate(true)"
      @close="showCreateDialog = false" />

    <info-dialog
      :dialog="showInfoDialog"
      :on-cancel="closeInfoDialog"
      @close="closeInfoDialog" />

    <SuccessDialog
      v-if="successDialogTitle"
      :title="successDialogTitle"
      @close="successDialogTitle = ''" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import OptimizationButton from '@/components/Merchant/Portal/RateSheets/Optimizations/OptimizationButton.vue';
import { useMerchantRateSheet } from '@/composables/useMerchantRateSheet';
import InfoDialog from '@/components/Merchant/Portal/RateSheets/OfferCodes/InfoDialog.vue';
import HelpButton from '@/components/Buttons/HelpButton.vue';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import CreateOfferCode from './CreateOfferCode.vue';
import OfferCodeTable from './OfferCodeTable/index.vue';
import SuccessDialog from './SuccessDialog.vue';

const showCreateDialog = ref(false);
const showInfoDialog = ref(false);
const successDialogTitle = ref('');
const store = useStore();

const { hasOfferCodeOptimizationSuggestions } = useMerchantRateSheet(false);

const hasOptimizationSuggestions = computed(() => hasOfferCodeOptimizationSuggestions());

onMounted(() => fetchOfferCodes());

const { isFeatureEnabled, FeatureFlagsConstants } = useFeatureFlag();
const rsOptimizationsEnabled = computed(() => isFeatureEnabled(FeatureFlagsConstants.RATE_SHEET_OPTIMIZATION));

const offerCodes = computed(() => store.getters['MerchantPortal/getOfferCodes']);

async function fetchOfferCodes() {
  store.dispatch('MerchantPortal/fetchOfferCodes');
}

function getRSOptimizationSuggestions() {
  if (!rsOptimizationsEnabled.value) return;
  store.dispatch('MerchantPortal/getRSOptimizationSuggestions');
}

async function onChange() {
  await fetchOfferCodes();
  // In case there are optimization suggestions for the new changes
  getRSOptimizationSuggestions();
}

function onUpdate(isCreated = false) {
  successDialogTitle.value = isCreated
    ? 'Offer Code Created!'
    : 'Offer Code Updated!';
  return onChange();
}

function closeInfoDialog() {
  showInfoDialog.value = false;
}
</script>

<style lang="scss" scoped>
.offer-codes {
  width: 100%;

  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .actions {
      display: flex;
      align-items: center;
      margin-right: 1.5rem;

      .divider {
        align-self: center;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        width: 1px;
        height: 1.5rem;
        opacity: 1;
        color: var(--grayscale-color-2);
      }
    }
  }

  &--create-btn {
    margin: 1.5rem 0;
  }

  :deep(.v-expansion-panel-text__wrapper) {
    text-align: right;

    .offer-code-table {
      text-align: left;
    }
  }

  &--table {
    margin-bottom: 2rem;
  }

  .v-expansion-panel-title {
    height: 3rem;
    min-height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--grayscale-color-1);

    &--active {
      background-color: var(--grayscale-color-5);
    }
  }

  .offer-codes-text {
    color: var(--grayscale-color-1);
  }
}

.actions {
  :deep(.help-button) {
    background-color: var(--grayscale-color-5);

    .v-icon {
      background-color: var(--grayscale-color-5);
    }
  }
}
</style>
