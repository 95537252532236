import { defineComponent } from 'vue';
import { AxiosError } from 'axios';

export default defineComponent({
  methods: {
    redirectToSponsorPortal(redirectUrl: string) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 2000);

      this.$store.dispatch(
        'Ui/addGlobalTimedWarning',
        'We are now redirecting you to the merchant portal.',
      );
    },

    shouldRedirectToSponsorPortal(error: AxiosError) {
      return (error.response?.data as any).error === 'sponsor_type_not_allowed';
    },
  },
});
