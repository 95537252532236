<template>
  <div class="rate-sheets-options">
    <simple-card class="rate-sheets-options__data-table">
      <template v-if="showHeader" #header>
        <div v-if="simpleHeader" class="rate-sheet-simple-header">
          <h5>{{ headerTitle }}</h5>
        </div>

        <rate-sheet-options-data-table-header
          v-else
          ref="header"
          :header-title="headerTitle" />
      </template>

      <template #body>
        <div class="simple-card__body-text" :class="rateSheetTable ? 'ratesheet-table' : ''">
          <rate-sheet-options-data-table-legend v-if="showLegend" />

          <v-data-table
            :headers="headers"
            :items="rateSheetOptions"
            :loading="processing"
            :mobile="isMobile"
            :data-test="customDataTest"
            :items-per-page="hidePagination ? 0 : 10"
            :class="tableClasses"
            item-key="rate_sheet_merchant_product_price_id"
            :row-props="toggleRowCustomClass"
            no-data-text="No products available"
            :hide-default-footer="hidePagination">
            <template v-slot:header.product_name="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template v-slot:header.full_term="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template v-slot:header.apr="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template v-slot:header.merchant_fee="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template #item.product_name="{ item }">
              <div :class="{ 'has-fallback': item.has_fallback }">
                {{ item.product_name }}
                <v-icon
                  v-if="!has75kFeatureFlag && item.has_fallback"
                  size="1.5rem"
                  color="var(--grayscale-color-1)"
                  @click="$emit('show-fallback-modal')">
                  mdi-information-outline
                </v-icon>
              </div>
            </template>
            <template #item.product_attributes="{ item }">
              <div class="product-attributes">
                <FallbackTooltip v-if="item.has_fallback" />
                <SeventyFiveKTooltip v-if="is75kProduct(item)" />
                <InfoTriangle v-if="item.has_optimization && !isFromOfferCodes" fill="var(--error-color)" />
              </div>
            </template>
            <template #item.merchant_fee="{ item }">
              {{ item.merchant_fee }}%
            </template>
            <template #item.customer_preferences="{ item }">
              <preferences :customer-preferences="item.customer_preferences" />
            </template>
            <template #item.select="{ item }">
              <rate-sheet-options-more-data
                v-if="!additionalProductOfferings && !switchToggle"
                :merchant-onboarding="merchantOnboarding"
                :rate-sheet-option="item"
                :is-mobile="isMobile"
                @toggle="removeRipple" />
              <div
                v-if="additionalProductOfferings || switchToggle"
                :class="{ 'd-flex more-data-action': !isMobile }">
                <rate-sheet-options-more-data
                  :merchant-onboarding="merchantOnboarding"
                  :rate-sheet-option="item"
                  rate-sheet-action="Add To Rate Sheet"
                  @toggle="selectRateSheetOption(item)" />

                <selector
                  class="button-full-width"
                  :button-toggle="buttonToggle"
                  :switch-toggle="switchToggle"
                  :disabled-add="disableAddBtns"
                  :selected="item.is_selected"
                  :additional-product-offerings="additionalProductOfferings"
                  @toggle="selectRateSheetOption(item)" />
              </div>
            </template>
          </v-data-table>
        </div>
      </template>
    </simple-card>

    <custom-dialog
      v-if="activeTooltip"
      dialog
      :show-cancel-cta="false"
      :title="activeTooltip.title"
      button-label="OK"
      button-size="small"
      width="420"
      :on-cta="closeTooltip"
      @cancel="closeTooltip">
      <template #body>
        {{ activeTooltip.tooltip }}
      </template>
    </custom-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import CustomDialog from '@/components/Dialogs/index.vue';
import Preferences from '@/components/RateSheetOptions/DataTable/CustomerPreferences.vue';
import RateSheetOptionsDataTableHeader from '@/components/RateSheetOptions/DataTable/Header.vue';
import RateSheetOptionsDataTableLegend from '@/components/RateSheetOptions/DataTable/Legend.vue';
import RateSheetOptionsMoreData from '@/components/RateSheetOptions/MoreData.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import GetProcessing from '@/mixins/GetProcessing';
import StandardInputMixin from '@/mixins/StandardInputMixin';
import HeaderWithTooltip from '@/components/Tables/TableHeaderWithTooltip.vue';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import { useMerchantRateSheet } from '@/composables/useMerchantRateSheet';
import FallbackTooltip from '@/components/Merchant/Portal/RateSheets/FallbackTooltip.vue';
import InfoTriangle from '@/components/Icons/InfoTriangle.vue';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import SeventyFiveKTooltip from '@/components/Merchant/Portal/RateSheets/SeventyFiveKTooltip.vue';
import Selector from './Selector.vue';

export default defineComponent({
  name: 'RateSheetOptionsDataTable',

  components: {
    CustomDialog,
    HeaderWithTooltip,
    RateSheetOptionsDataTableHeader,
    RateSheetOptionsDataTableLegend,
    Preferences,
    RateSheetOptionsMoreData,
    SimpleCard,
    Selector,
    FallbackTooltip,
    SeventyFiveKTooltip,
    InfoTriangle,
  },

  mixins: [GetProcessing, StandardInputMixin],

  props: {
    showLegend: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    showHeaderInfoOnMount: { type: Boolean, default: false },
    rateSheetOptions: { type: Array as PropType<RateSheetData[]>, default: () => [] },
    rateSheetTable: { type: Boolean, default: false },
    buttonToggle: { type: Boolean, default: false },
    switchToggle: { type: Boolean, default: false },
    additionalProductOfferings: { type: Boolean, default: false },
    disableAddBtns: { type: Boolean, default: false },
    mobileBreakpoint: { type: Number, default: 600 },
    isFromOfferCodes: { type: Boolean, default: false },
    headerTitle: { type: String, default: '' },
    merchantOnboarding: { type: Boolean, default: false },
    tableDataTest: { type: String, default: '' },
    simpleHeader: { type: Boolean, default: false },
    pagination: { type: String as PropType<'minimal' | 'fast' | 'off'>, default: 'fast' },
  },

  setup(props) {
    const { headers: commonHeaders, is75kProduct } = useMerchantRateSheet(
      props.merchantOnboarding,
      props.isFromOfferCodes,
    );
    const headers = computed(() => [
      ...commonHeaders.value,
      {
        title: props.switchToggle ? 'Active' : '',
        sortable: false,
        align: 'end',
        width: `${props.switchToggle ? '120' : '60'}px`,
        value: 'select',
      },
    ]);

    const hidePagination = computed(() => props.pagination === 'off' || props.rateSheetOptions.length < 10);

    const tableClasses = computed(() => ({
      'minimal-pagination': props.pagination === 'minimal',
      'fast-pagination': props.pagination === 'fast',
    }));

    const { isFeatureEnabled, FeatureFlagsConstants } = useFeatureFlag();
    const has75kFeatureFlag = isFeatureEnabled(FeatureFlagsConstants.CREDIT_LIMIT_75K);

    return {
      headers, has75kFeatureFlag, is75kProduct, hidePagination, tableClasses,
    };
  },

  data: () => ({
    selectedRates: [] as Array<RateSheetData>,
    activeTooltip: null as TableHeaderInterface | null,
  }),

  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    customDataTest() {
      return this.tableDataTest ? this.tableDataTest : 'mainRateSheetTable';
    },

    rateSheetOptionIds(): Array<number> {
      return (this.rateSheetOptions as RateSheetData[]).map(it => {
        return it.rate_sheet_merchant_product_price_id;
      });
    },
  },

  mounted() {
    if (this.$props.showHeaderInfoOnMount) {
      this.showHeaderInfo();
    }
  },

  methods: {
    selectRateSheetOption(rateSheet: RateSheetData) {
      if (this.processing) return;
      if (!this.isFromOfferCodes) rateSheet.has_ripple = true;

      rateSheet.is_selected = !rateSheet.is_selected;
      this.$emit('toggle', rateSheet);
    },

    removeRipple() {
      (this.rateSheetOptions as RateSheetData[]).forEach(element => {
        if (element.is_selected) element.has_ripple = false;
      });
    },

    toggleRowCustomClass({ item }: { item: RateSheetData }) {
      return {
        class: {
          'row-not-selected-bg': !item.is_selected,
          'ripple-animation': item.has_ripple && !this.isFromOfferCodes,
          'has-optimization': item.has_optimization && !this.isFromOfferCodes,
        },
      };
    },

    showHeaderInfo() {
      const { header } = this.$refs;

      if (header) {
        (header as any).showModal();
      }
    },

    showModal() {
      (this.$refs.modal as any).showModal();
    },

    closeModal() {
      (this.$refs.modal as any).closeModal();
    },

    closeTooltip() {
      this.activeTooltip = null;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-data-table";
@import '@/assets/scss/mixins/media_queries';
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";
@import "@/assets/scss/mixins/rate-sheet-table";

.simple-card__body-text {
  width: 100%;
  padding: 0 1rem !important;

  @include mobile {
    padding: 1rem 0 !important;
  }
}

.rate-sheet-simple-header {
  width: 100%;
  height: 3rem;
  padding: 1rem;
  background-color: var(--grayscale-color-5);

  h5 {
    margin: 0;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 800;
    color: $momnt-navy;
  }
}

:deep() {
  .v-table {
    &__wrapper {
      overflow-y: hidden;

      table {
        th div {
          font-family: $font-family-roboto-condensed;
          letter-spacing: 0.02em;
          display: flex;
          align-items: center;

          @include tablet {
            span {
              line-height: normal;
            }
          }
        }

        tr {
          &.has-optimization {
            color: var(--error-color);

            .v-data-table__td-value {
              color: var(--error-color);
            }

            i.mdi-dots-vertical.mdi.v-icon {
              color: var(--grayscale-color-1);
            }
          }

          .product-attributes {
            @include product-attributes;
          }
        }
      }

      @include tablet {
        table > tbody > tr > td,
        table > thead > tr > th {
          margin: 0 .5rem;
        }
      }

      @include media-breakpoint-down-xs {
        table > thead {
          display: none;
        }
        table tbody td div.v-data-table__td-title {
          font-family: $font-family-roboto-condensed;
          letter-spacing: 0.02em;
        }
      }
    }

    .more-data-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }

    .v-data-footer {
      justify-content: end;
    }

    &__wrapper table .v-data-table__tr--mobile {
      padding: 1rem !important;
      box-shadow: inset 0px -1px 0px var(--grayscale-color-3);

      .v-data-table__td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0;
        color: var(--grayscale-color-1);

        .v-data-table__td__title {
          font-weight: 700;
          color: var(--grayscale-color-1);
        }
      }
    }
  }
}

.standard-form-button--link:active {
  background-color: transparent !important;
  color: var(--color-primary) !important;
}

.custom-checkbox {
  width: 1.5rem;
}

:deep(.row-not-selected-bg.v-table__selected) {
  background: var(--grayscale-color-4)!important;
  background-color: var(--grayscale-color-4)!important;
}

:deep(.button-full-width .v-theme--light.v-btn.custom-button.button-small) {
  width: 100%;
}

:deep(.button-full-width) {
  .custom-switch .v-input--is-label-active .v-input__control .v-switch__track {
    color: var(--grayscale-color-1) !important;
    opacity: 1 !important;
  }
}

.ripple-animation {
  animation-name: rippleBg;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.7s;
  background-image: linear-gradient(#eee, #eee);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size .5s, color .5s;
}

@keyframes rippleBg {
  0% {
    background-size: 0 100%;
  }

  100% {
    background-size: 100% 100%;
  }
}

@include up-to-desktop {
  :deep() {
    .v-theme--light.v-table > .v-table {
      &__wrapper > table > tbody >
        tr:hover:not(.v-table__expanded__content):not(.v-table__empty-wrapper) {
          background: #fff;
        }
    }
  }
}

.ratesheet-table {
  :deep() {
    .v-table-header tr th span {
      color: var(--grayscale-color-1);
    }

    tbody tr td {
      color: var(--grayscale-color-1);
    }

    .v-data-table__tr--mobile .v-data-table__td .v-data-table__td__value {
      color: var(--grayscale-color-1);
    }
  }
}
</style>
