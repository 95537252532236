import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69dad9b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "font-weight-bold mr-2" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_application_summary_popup = _resolveComponent("application-summary-popup")!
  const _component_table_skeleton_loader = _resolveComponent("table-skeleton-loader")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    page: _ctx.page,
    "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
    "sort-desc": _ctx.orderDesc,
    "onUpdate:sortDesc": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderDesc) = $event)),
    mobile: _ctx.isMobile,
    "sort-by": [{ key: 'purchase_window_end_date', order: _ctx.orderDesc ? 'desc' : 'asc' }],
    headers: _ctx.headers,
    items: _ctx.results,
    "items-per-page": 5,
    loading: _ctx.loading,
    "items-length": _ctx.count,
    class: "fs-unmask minimal-pagination",
    "must-sort": ""
  }, {
    "item.sent_to": _withCtx(({ item }) => [
      _createElementVNode("button", {
        class: "consumer-activity-interactable",
        onClick: ($event: any) => (_ctx.seeConsumerSummary(item.consumer_application_uuid))
      }, _toDisplayString(item.sent_to), 9, _hoisted_1)
    ]),
    "item.id_number": _withCtx(({ item }) => [
      _createVNode(_component_application_summary_popup, {
        "id-number": item.id_number,
        "invitation-id": item.consumer_invitation_uuid
      }, null, 8, ["id-number", "invitation-id"])
    ]),
    "item.available_spend": _withCtx(({ item }) => [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.CurrencyFormatLong(item.available_spend)), 1)
    ]),
    "item.amount_spent": _withCtx(({ item }) => [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.CurrencyFormatLong(item.amount_spent)), 1)
    ]),
    "item.purchase_window_end_date": _withCtx(({ item }) => [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatDate(item.purchase_window_end_date)), 1)
    ]),
    loading: _withCtx(() => [
      _createVNode(_component_table_skeleton_loader, {
        rows: 5,
        cols: _ctx.colWidths
      }, null, 8, ["cols"])
    ]),
    _: 1
  }, 8, ["page", "sort-desc", "mobile", "sort-by", "headers", "items", "loading", "items-length"]))
}