<template>
  <div class="rate-sheet-options-data-table-description-tooltip">
    <template v-if="showHelpButton">
      <HelpButton @click="onClick" />

      <InfoDialogNew
        :dialog="show"
        :on-cta="onCancel"
        :on-cancel="onCancel" />
    </template>
    <template v-else>
      <custom-button variant="icon" @click="onClick">
        <v-icon size="20" color="var(--grayscale-color-2)">
          mdi-help-circle-outline
        </v-icon>
      </custom-button>

      <info-dialog
        :dialog="show"
        :on-cta="onCancel"
        :on-cancel="onCancel" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
// RSO: Rename to InfoDialog when the feature flag is removed
import InfoDialogNew from '@/components/Merchant/Portal/RateSheets/InfoDialog.vue';
import InfoDialog from '@/components/RateSheetOptions/InfoDialog.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import HelpButton from '@/components/Buttons/HelpButton.vue';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

defineProps({
  show: { type: Boolean, default: false },
  onCancel: { type: Function as PropType<() => void>, default: () => () => null },
  onClick: { type: Function as PropType<() => void>, default: () => () => null },
});

const { isFeatureEnabled, FeatureFlagsConstants } = useFeatureFlag();
const showHelpButton = computed(() => isFeatureEnabled(FeatureFlagsConstants.RATE_SHEET_OPTIMIZATION));
</script>

<style lang="scss" scoped>
.rate-sheet-options-data-table-description-tooltip {
  margin-left: .25rem;
  display: inline-block;
}
</style>
