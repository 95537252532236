<template>
  <div class="inline-inputs step-twelve-wrapper" data-test="COB-processLoanStep">
    <div>
      <consumer-loan-apply-wizard-credit-engine-hard-decline-msg
        v-if="hardDeclined || hardHardDeclined" />

      <consumer-loan-apply-wizard-credit-engine-hard-approved-for-less-msg
        v-if="showHardApprovedForLessAan"
        approved-type="hard"
        :on-consent="nextStep" />

      <consumer-loan-apply-credit-freeze-error v-else-if="creditFrozen" />

      <div>
        <h6 class="mt-4">
          Please Review and click below to proceed.
        </h6>
        <v-card elevation="4" class="consumer-edit-data">
          <v-card-text>
            <p class="mb-0">
              Loan Details
            </p>

            <p v-if="selectedLoan.product_description">
              <strong>
                {{ selectedLoan.product_description }}
              </strong>
            </p>

            <p v-else>
              <strong>
                {{ selectedLoan?.json_description?.offer }}
              </strong>
            </p>

            <p class="mb-0">
              Credit Limit Amount
            </p>

            <p>
              <strong v-private>
                {{ approvedAmountSoftPullFormatted }}
              </strong>
            </p>

            <div v-html="selectedLoan.long_description" />
          </v-card-text>
        </v-card>

        <div class="mt-4 standard-consent-and-input-notation-text">
          By clicking continue, you consent to our
          <a
            data-test="creditBtn"
            :href="craUrl || ''"
            rel="noopener noreferrer"
            target="_blank">
            Credit Report Authorization
          </a>
          and we make a hard pull of credit that may impact your credit score.
        </div>

        <div class="submit-my-application">
          <custom-button
            data-test="proceedBtn"
            :disabled="processingData"
            class="mt-4"
            full-width
            label="Proceed"
            @click="processLoan" />
        </div>
      </div>
    </div>

    <complete-later-button class="mt-12 mx-0" />
  </div>
</template>

<script lang="ts">
import GetProcessing from '@/mixins/GetProcessing';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CreditEngineCheckMixin from '@/mixins/Consumer/LoanApply/CreditEngineCheckMixin';
import CreditEngineDecisions from '@/enums/CreditEngine/Decisions';
import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import CreditEngineHardPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullApprovedAmountMixin';
import CreditEngineSoftPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullApprovedAmountMixin';
import ConsumerLoanApplyWizardCreditEngineHardApprovedForLessMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/ApprovedForLessMsg.vue';
import ConsumerLoanApplyWizardCreditEngineHardDeclineMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/HardDeclineMsg.vue';
import ConsumerLoanApplyCreditFreezeError
  from '@/components/Consumer/LoanApplyWizard/Messages/CreditFreezeError.vue';
import { get } from 'lodash';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import InvitationApplicationStatusDetails
  from '@/enums/Consumer/InvitationApplicationStatusDetails';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'ProcessLoanStep',
  components: {
    CustomButton,
    CompleteLaterButton,
    ConsumerLoanApplyCreditFreezeError,
    ConsumerLoanApplyWizardCreditEngineHardDeclineMsg,
    ConsumerLoanApplyWizardCreditEngineHardApprovedForLessMsg,
  },
  mixins: [
    CreditEngineSoftPullApprovedAmountMixin,
    CreditEngineHardPullApprovedAmountMixin,
    GetProcessing,
    CreditEngineCheckMixin,
    IdleTimeoutMixin,
    ReEntryMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
  ],
  data() {
    return {
      ownStep: 13,
      show: false,
      processingData: false,
      craUrl: '',
      hpEventName: 'Loan Offer Selected Page Visit',
      hpStep: 13,
    };
  },
  computed: {
    hardDeclined(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.DECLINED;
    },
    hardHardDeclined(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.HARD_DECLINED;
    },
    hardApproved(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.APPROVED;
    },
    hardApprovedForLess(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.APPROVED_FOR_LESS;
    },
    creditFrozen(): boolean {
      const { decline_type } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decline_type === 'frozen_file';
    },
    approvedAmountNotChanged(): boolean {
      return this.approvedAmountSoftPull === this.approvedAmountHardPull;
    },
    approvedAmountSoftPullFormatted() {
      return CurrencyFormatLong(this.approvedAmountSoftPull);
    },
    showHardApprovedForLessAan(): boolean {
      return this.hardApprovedForLess && !this.approvedAmountNotChanged;
    },
    selectedLoan(): any {
      return this.$store.getters['Consumer/getSelectedLoan'];
    },
    brandData(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
  },
  watch: {
    brandData: {
      handler(newVal: any) {
        if (newVal.credit_report_authorization_url) {
          this.craUrl = newVal.credit_report_authorization_url;
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (this.isReEntry && this.appStatusDetail
      === InvitationApplicationStatusDetails.HARD_PULL_FROZEN_CREDIT_FILE) {
      await this.initiateHardPull();
    }
  },

  async mounted() {
    await this.presentConsent();

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },

  methods: {
    async nextStep(): Promise<void> {
      const { error } = await this.$store.dispatch('Consumer/saveSelectedLoan');

      this.$emit('hideCompleteLaterBtn', true);

      if (!error) {
        this.show = false;

        this.$emit('hideCompleteLaterBtn', false);

        this.goToStep(this.ownStep + 1);
      }
    },
    async updateConsents(consents: Array<number>): Promise<number> {
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      return patchAvailableConsents(consents, appId, ConsentEntityTypes.CONSUMER);
    },
    async presentConsent(): Promise<void> {
      const consumer_application_uuid = this.$store.getters['Consumer/getConsumerApplicationId'];

      const consentTypes = [
        ConsentTypesEnum.CREDIT_REPORT_AUTHORIZATION,
        ConsentTypesEnum.HARD_PULL_CONSENT,
      ];
      await postConsentTypes({
        consentTypes,
        consumer_application_uuid,
        entity: ConsentEntityTypes.CONSUMER,
        consentedOn: true,
      });
    },
    hardPull() {
      return this.$store.dispatch('Consumer/creditEnginePull', CreditEnginePullTypes.HARD);
    },
    async processLoan() {
      this.show = false;

      this.processingData = true;

      this.$emit('hideCompleteLaterBtn', true);

      await this.initiateHardPull();

      this.processingData = false;

      this.$emit('hideCompleteLaterBtn', false);
    },
    async initiateHardPull() {
      const { error } = await this.hardPull();

      await this.updateConsents(
        [ConsentTypesEnum.HARD_PULL_CONSENT, ConsentTypesEnum.CREDIT_REPORT_AUTHORIZATION],
      );

      if (!error) {
        if (this.hardApproved || (this.hardApprovedForLess && this.approvedAmountNotChanged)) {
          await this.nextStep();
        }
      }
    },
  },
});
</script>
