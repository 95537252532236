<template>
  <CustomButton
    v-bind="$attrs"
    class="help-button"
    :class="`help-button--${props.variant}`"
    variant="text"
    size="small">
    <span>Help</span>
    <v-icon :size="iconSize" color="var(--grayscale-color-1)">
      mdi-help-circle-outline
    </v-icon>
  </CustomButton>
</template>

<script setup lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { useDisplay } from 'vuetify';
import { computed } from 'vue';

const props = defineProps({
  variant: { type: String, default: 'light' },
});

const { xs } = useDisplay();
const iconSize = computed(() => (xs.value ? '16' : '24'));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.help-button {
  margin-left: .25rem;
  padding: 7px 0.5rem;
  display: inline-block;
  color: var(--grayscale-color-1) !important;

  &--light {
    color: var(--grayscale-color-2);
    background-color: white;

    &:hover {
      background-color: white !important;
    }
  }

  .v-icon {
    margin-left: .5rem;
  }

  @include mobile {
    font-size: 0.75rem !important;
  }
}
</style>
