<template>
  <div class="start-verification-desktop">
    <img
      :src="logoUrl"
      class="mb-8"
      width="200"
      alt="Logo">
    <p class="mb-8">
      <strong>Do Not Refresh This Screen.</strong>It will refresh
      automatically once you complete your verification.
    </p>
    <p class="mb-8">
      Check your mobile device for a text message to begin ID verification.
    </p>
    <p class="mb-8">
      After 15 minutes your session will expire and you will need to restart
      the process.
    </p>
    <p class="mb-8">
      If you have not received the text message you can scan
      the QR code or click the link below to resend.
    </p>

    <img
      :src="qrCode"
      class="mb-4"
      width="150"
      alt="QR Code">

    <custom-button
      data-test="resendBtn"
      full-width
      variant="text"
      @click="resendActivationLink">
      <span class="no-link-message">I did not receive a link</span>
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { startDocumentVerification } from '@/api/consumer';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import SocureDocVMixin from '@/mixins/SocureDocVMixin';

export default defineComponent({
  name: 'StartVerificationDesktop',

  components: { CustomButton },

  mixins: [SocureDocVMixin],

  props: {
    qrCodeImage: { type: String, default: '' },
  },

  data() {
    return {
      qrCode: this.qrCodeImage,
    };
  },

  computed: {
    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },

    applicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },

    consumerPhone(): string {
      return this.$store.getters['Consumer/getLoanApplyWizardData'].phone_number || '';
    },
  },

  mounted() {
    const redirectToStep = 10;
    this.startPolling(redirectToStep);
  },

  methods: {
    async resendActivationLink() {
      const alertMessage = `Verification link has been sent successfully to ${this.consumerPhone}.`;
      try {
        const res = await startDocumentVerification(this.applicationId);
        this.qrCode = res.data.qr_code;
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: alertMessage,
          type: GlobalAlertTypes.SUCCESS,
          timed: true,
        });
      } catch (err: any) {
        this.$store.dispatch('Ui/addGlobalTimedError');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.start-verification-desktop {

  img {
    display: block;
    margin: 0 auto;
  }

  p {
    font-size: 1rem;
  }

  .no-link-message {
    text-transform: none;
  }
}
</style>
