import LocalStorageConstants from '@/constants/LocalStorageConstants';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export function useConsumerOnboarding(): any {
  const router = useRouter();
  const store = useStore();

  async function getInvitation(uuid?: string): Promise<number> {
    if (!uuid) {
      const route = useRoute();

      const { id } = (route.params as any);
      uuid = id;
    }

    if (!uuid) return -1;

    const { status, error } = await store.dispatch('Consumer/getInvitation', uuid);

    if (error) {
      // Most likely the JWT token that we send became invalid
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        return 401;
      }

      // Redirect all errors to error page except expired invitation
      if (error.response.status !== 400) {
        router.push({ name: 'error-page' });
        return 400;
      }

      store.commit('Consumer/setExpiredLink', true);
      return error.response.status;
    }

    sessionStorage.setItem(LocalStorageConstants.COB_INVITATION_UUID, uuid);
    return status;
  }

  return { getInvitation };
}
