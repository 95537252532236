// Deprecated. Use useHumanReadableApplicationId composable instead.

import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HumanReadableApplicationId',
  mixins: [GetHumanReadableIdLabelMixin],
  computed: {
    /**
     * Formatting Merchant readable Uuid label,
     * for displaying in template
     */
    merchantHumanReadableIdLabel() {
      return this.getHumanReadableIdLabel(this.merchantHumanReadableId, false);
    },
    /**
     * Store getter for Merchant human readable Uuid
     */
    merchantHumanReadableId(): any {
      return this.$store.getters['Auth/getMerchantHumanReadableId'];
    },
  },
});
