import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6da177f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "fs-unmask standard-wizard__step standard-wizard__step--otp",
  "data-test": "COB-otpStep"
}
const _hoisted_2 = { "data-test": "sixDigitHeaderLabel" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_input_otp = _resolveComponent("standard-input-otp")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_consumer_loan_apply_wizard_existing_applications_modal = _resolveComponent("consumer-loan-apply-wizard-existing-applications-modal")!
  const _component_credit_freeze_re_entry = _resolveComponent("credit-freeze-re-entry")!
  const _component_bubble_card = _resolveComponent("bubble-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bubble_card, null, {
      "card-title": _withCtx(() => [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.cardTitle), 1)
      ]),
      default: _withCtx(() => [
        (!_ctx.showCreditFrozenMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_standard_input_otp, {
                    "invitation-uuid": _ctx.invitationUuid,
                    "is-soft-pull-api-initiated": _ctx.isSoftPullApiInitiated,
                    "next-step": _ctx.nextStep,
                    "on-phone-number-updated": _ctx.updatePhoneNumber,
                    "phone-number": _ctx.phoneNumber,
                    "session-token": _ctx.otpRegisterSessionToken,
                    "single-input": true,
                    "verify-code": _ctx.setSecurityCode,
                    "consumer-otp": true,
                    "otp-message": _ctx.otpMessage,
                    "show-header": _ctx.showHeader,
                    "autofocus-enabled": ""
                  }, null, 8, ["invitation-uuid", "is-soft-pull-api-initiated", "next-step", "on-phone-number-updated", "phone-number", "session-token", "verify-code", "otp-message", "show-header"])
                ]),
                _: 1
              }),
              _createVNode(_component_consumer_loan_apply_wizard_existing_applications_modal, {
                dialog: _ctx.show,
                "on-cta": _ctx.verifyCode,
                "on-cancel": _ctx.closeModal
              }, null, 8, ["dialog", "on-cta", "on-cancel"])
            ]))
          : (_openBlock(), _createBlock(_component_credit_freeze_re_entry, {
              key: 1,
              onContinue: _ctx.onCreditUnfrozen,
              onCreditFreezeReEntry: _ctx.setCardTitle
            }, null, 8, ["onContinue", "onCreditFreezeReEntry"]))
      ]),
      _: 1
    })
  ]))
}