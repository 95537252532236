<template>
  <div :class="!isMobile ? 'full-width' : ''">
    <custom-expansion-panel
      class="fs-unmask gray-border section-card"
      :class="{ 'height-fit-content': hideConsumerChargeRequest && isValidForTransaction }"
      :expanded="true"
      :can-collapse="!isMobile"
      show-overflow>
      <template #title>
        <div v-if="!isMobile" class="full-width">
          <customer-search :key="componentKey" @forceUpdate="updateComponent" />
        </div>
      </template>
      <template #text>
        <customer-search
          v-if="isMobile"
          :key="componentKey"
          @forceUpdate="updateComponent" />

        <component :is="wrapperComponent">
          <div v-if="show">
            <customer-summary-details
              v-if="merchantCanTransactWithConsumer"
              @showRefundsDialog="openRefundsDialog" />

            <div class="simple-card__body-action">
              <custom-alert
                v-if="merchantHasNoPaymentProviders"
                class="summary-card-alert"
                type="error">
                You will not be able to transact until an account admin connects a bank account in
                <span class="font-weight-bold">Admin > Payment Settings</span>.
              </custom-alert>

              <custom-alert
                v-else-if="showUnableTransactError"
                class="summary-card-alert"
                type="error">
                In order to transact with this consumer, an account admin must complete
                the steps in <span class="font-weight-bold">Admin > Payment Settings</span>.
              </custom-alert>

              <div v-else-if="showInvitationSection" class="consumer-details-invite-section">
                <p>
                  Once this consumer creates their Payment Portal Account,
                  you will be able to transact with them.
                </p>

                <custom-button
                  v-if="!customerAccount.transaction_restriction.invitation_message"
                  class="mt-4 responsive-button"
                  full-width
                  label="Send Link To Create Account"
                  variant="secondary"
                  :size="isTablet ? 'small' : 'large'"
                  :disabled="disableButton"
                  @click="inviteConsumerToPortal" />
                <div v-else class="invitation-sent-message">
                  {{ customerAccount.transaction_restriction.invitation_message }}
                </div>
              </div>

              <template v-else-if="!hideConsumerChargeRequest && isValidForTransaction">
                <customer-summary-card-send-new v-if="isStagedFunding" :selected-loan="selectedLoanApplication" />
                <customer-summary-card-send v-else :selected-loan="selectedLoanApplication" />
              </template>

              <div
                v-else
                class="message"
                data-test="unableToTransactLabel">
                {{ customerAccount.no_transaction_message }}
              </div>
            </div>
          </div>
        </component>
      </template>
    </custom-expansion-panel>

    <refunds-dialog
      v-if="showDialog"
      :dialog="showDialog"
      :title="dialogTitle"
      :on-close="closeDialog"
      :consumer-data="selectedLoanApplication"
      :width="'1000'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomExpansionPanel from '@/components/CustomExpansionPanel.vue';
import CustomerSummaryCardSend from '@/components/CustomerSummaryCard/Send.vue';
import CustomerSummaryCardSendNew from '@/components/CustomerSummaryCard/SendNew.vue';
import CustomerSummaryDetails from '@/components/CustomerSummaryCard/Details.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import RefundsDialog from '@/components/CustomerSummaryCard/RefundsDialog/index.vue';
import { get } from 'lodash';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import { VFadeTransition } from 'vuetify/components';
import CustomerSearch from '@/components/CustomerSummaryCard/Search.vue';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'CustomerSummaryCard',

  components: {
    CustomExpansionPanel,
    SimpleCard,
    CustomerSummaryDetails,
    CustomerSummaryCardSend,
    RefundsDialog,
    CustomerSearch,
    CustomAlert,
    CustomButton,
    CustomerSummaryCardSendNew,
  },

  data() {
    return {
      showDialog: false,
      dialogTitle: '',
      show: true,
      componentKey: 0,
      disableButton: false,
    };
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    isTablet(): boolean {
      return ((this.$vuetify.display.width > 599) && (this.$vuetify.display.width < 1024));
    },

    selectedLoanApplication() {
      return this.$store.getters['MerchantPortal/getSelectedLoanApplication'];
    },

    customerAccount() {
      return get(this.selectedLoanApplication, 'consumer_account', 0);
    },

    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },

    merchantCanTransactWithConsumer(): boolean {
      return this.selectedLoanApplication.can_transact_with_the_merchant;
    },

    merchantHasNoPaymentProviders(): boolean {
      return this.paymentProviders?.moov?.can_transact === false
        && this.paymentProviders?.repay?.can_transact === false;
    },

    showUnableTransactError() {
      return this.selectedLoanApplication.can_transact_with_the_merchant === false;
    },

    isValidForTransaction(): boolean {
      return this.customerAccount.is_valid_for_transaction
        && !this.customerAccount.transaction_restriction.cp_account_restriction;
    },

    hideConsumerChargeRequest(): boolean {
      return !this.selectedLoanApplication.is_eligible_for_charge_requests;
    },

    showInvitationSection(): boolean {
      return this.customerAccount.is_valid_for_transaction
        && this.customerAccount.transaction_restriction.cp_account_restriction;
    },

    wrapperComponent() {
      return this.$vuetify.display.xs
        ? 'div'
        : VFadeTransition;
    },

    isStagedFunding(): boolean {
      return this.selectedLoanApplication?.staged_funding?.apply_staged_funding;
    },
  },

  watch: {
    selectedLoanApplication: {
      immediate: true,
      handler(val: any, oldVal: any) {
        if (val.user) {
          const { first_name, last_name } = val.user;
          this.dialogTitle = `Select The Transaction You Would Like To Refund To ${first_name} ${last_name}`;
        }

        if (oldVal?.human_readable_id !== val.human_readable_id) {
          this.show = false;
          setTimeout(() => {
            this.show = !this.show;
          }, 250);
        } else {
          this.show = true;
        }
      },
    },
  },

  methods: {
    updateComponent() {
      this.componentKey += 1;
    },

    openRefundsDialog() {
      this.showDialog = true;
    },

    closeDialog() {
      this.showDialog = false;
    },

    async inviteConsumerToPortal() {
      this.disableButton = true;

      const response = await this.$store.dispatch(
        'Consumer/getInviteToConsumerPortal',
        this.selectedLoanApplication.consumer_application,
      );

      if (response.status === 200) {
        this.$store.dispatch(
          'MerchantPortal/selectLoanApplication',
          this.selectedLoanApplication.consumer_application,
        );
      }

      this.disableButton = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import '@/assets/scss/mixins/media_queries';
@import '@/assets/scss/portal-section';

:deep(.expansion-panel) {
  &.height-fit-content {
    height: fit-content;
  }

  .v-expansion-panel-title {
    padding-left: 0;
  }

  .v-expansion-panel-text .simple-card__body-action .message {
    text-align: center;
    color: var(--grayscale-color-1);
  }
}

:deep(.v-card) {
  height: 100%;
}

:deep(.v-expansion-panel-title) {
  border-bottom: none !important;
  padding: 0.5rem !important;

  @include mobile {
    padding: 0 1.5rem !important;
    border-bottom: 1px solid var(--grayscale-color-2) !important;
  }

  @include tablet {
    padding: 0.5rem !important;
  }
}

.full-width {
  width: 100%;
  display: flex;
}

.menu-item {
  cursor: pointer;
  text-align: left;
}

.summary-card-alert {
  margin: 1.55rem 1rem;
}

.consumer-details-invite-section {
  margin: 0 1rem 1rem 1rem;
  padding: 0 1rem 1rem;
  border-radius: 8px;
  background-color: var(--grayscale-color-5);
  border: 1px solid var(--grayscale-color-3);

  :deep(.responsive-button) {
    display: flex;
    white-space: nowrap;

    @media only screen and (min-width: 599px) and (max-width: 1024px) {
      white-space: normal;
      display: block;
    }

    @media only screen and (min-width: 599px) and (max-width: 875px) {
      height: 2.25rem !important;
    }
  }

  p {
    padding: 1rem 0 0.5rem;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .invitation-sent-message {
    background-color: #fff;
    color: var(--grayscale-color-1);
    padding: 0.5rem 1rem;
    text-align: left;
    word-break: break-word;
  }
}

@include mobile {
  :deep(.expansion-panel) {
    border-radius: 0;

    .v-expansion-panel-title {
      display: none;
    }

    .v-expansion-panel-text .simple-card__body-action .message {
      padding-bottom: 2rem;
      color: var(--grayscale-color-1);
    }
  }
}

@include small-tablet {
  .table-header-customer-summary {
    text-align: left;

    &__title {
      word-break: break-word;
      padding-left: .25rem;
    }
  }
}
</style>
