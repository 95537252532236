<template>
  <div class="optimization-suggestion">
    <RemovalSuggestions
      v-if="suggestion.type === 'removal'"
      :suggestion="suggestion"
      @success="emit('success')"
      @hide="emit('hide')" />
  </div>
</template>

<script setup lang="ts">
import { RateSheetOptimizationSuggestion } from '@/interfaces/rates/RateSheetOptimization';
import RemovalSuggestions from './RemovalSuggestion.vue';

defineProps({
  suggestion: {
    type: Object as () => RateSheetOptimizationSuggestion,
    required: true,
  },
});

const emit = defineEmits(['hide', 'success']);
</script>
