import { FraudCheckInterface } from '@/interfaces/consumer/FraudCheckInterface';
import {
  EmploymentInformation,
  LoanApplyDataInterface,
} from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { ConsumerStateInterface } from '@/interfaces/states/ConsumerStateInterface';
import { filter, isEmpty } from 'lodash';
import {
  IdentificationTypeInterface,
} from '@/interfaces/consumer/loanApply/IdentificationTypeInterface';
import {
  PortalOnboardingDetailsResponse,
} from '@/interfaces/consumer/loanApply/PortalOnboardingInterface';

export function setFraudCheckError(state: ConsumerStateInterface, payload: boolean) {
  state.fraudCheckError = payload;
}

export function setBorrowerAgreementSpecificStateError(state: ConsumerStateInterface, payload: boolean) {
  state.borrowerAgreementSpecificStateError = payload;
}

export function setLoanOptions(state: ConsumerStateInterface, payload: Array<RateSheetData>) {
  state.loanOptions = payload;
}

export function setRemoveExistingApplication(state: ConsumerStateInterface, applicationIn: string) {
  state.existingApplications = filter(state.existingApplications, applicationIn);
}

export function setExistingApplications(state: ConsumerStateInterface, payload: Array<string>) {
  state.existingApplications = payload;
}

export function setSelectedLoan(state: ConsumerStateInterface, selectedLoan: RateSheetData) {
  state.selectedLoan = selectedLoan;
}

export function setApplicationCanceledData(state: ConsumerStateInterface, payload: any) {
  state.applicationCanceled = payload;
}

export function setExpiredLink(state: ConsumerStateInterface, payload: any) {
  state.expiredLink = payload;
}

export function setFetchingLoans(state: ConsumerStateInterface, payload: boolean) {
  state.fetchingLoans = payload;
}

export function setMerchantId(state: ConsumerStateInterface, merchantId: any) {
  state.merchantId = merchantId;
}

export function setMerchantName(state: ConsumerStateInterface, merchantName: any) {
  state.merchantName = merchantName;
}

export function setInvitation(state: ConsumerStateInterface, invitation: any) {
  state.invitation = invitation;
}

export function setLoanApplyWizardStep(state: ConsumerStateInterface, step: number) {
  state.loanApplyWizardStep = step;
}

export function setLoanApplyWizardReEntryStep(state: ConsumerStateInterface, step: number) {
  state.loanApplyWizardReEntryStep = step;
  state.loanApplyReEntry = true;
}

export function setConsumerApplicationId(state: ConsumerStateInterface, id: string) {
  state.consumerApplicationId = id;
}

export function setConsumerHumanReadableApplicationId(state: ConsumerStateInterface, id: string) {
  state.consumerHumanReadableApplicationId = id;
}

export function setProcessingCreditEngine(state: ConsumerStateInterface, payload: boolean) {
  state.processingCreditEngine = payload;
}

export function setCreditEngineCheck(state: ConsumerStateInterface, payload: any) {
  state.creditEngineCheck[payload.typeOfPull] = payload.data;
}

export function setLoanAgreement(state: ConsumerStateInterface, payload: any) {
  state.loanAgreement = payload;
}

export function setFraudCheck(state: ConsumerStateInterface, payload: FraudCheckInterface) {
  state.fraudCheck = payload;
}

export function setLoanApplyWizardData(
  state: ConsumerStateInterface,
  payload: LoanApplyDataInterface,
) {
  if (isEmpty(payload)) {
    state.loanApplyWizardData = {};
  } else {
    state.loanApplyWizardData = {
      ...state.loanApplyWizardData,
      ...payload,
    };
  }
}

export function setConsumerApplicationValid(state: ConsumerStateInterface, valid: boolean) {
  state.applicationValid = valid;
}

export function setLenderName(state: ConsumerStateInterface, lenderName: string) {
  state.lenderName = lenderName;
}

export function setLenderPrivacyPolicyUrl(
  state: ConsumerStateInterface,
  lenderPrivacyPolicyUrl: string,
) {
  state.lenderPrivacyPolicyUrl = lenderPrivacyPolicyUrl;
}

export function setAreLendersAvailable(state: ConsumerStateInterface, lenderAvailable: boolean) {
  state.areLendersAvailable = lenderAvailable;
}

export function setDisclaimerPage(state: ConsumerStateInterface, showDisclaimerPage: boolean) {
  state.showDisclaimerPage = showDisclaimerPage;
}

export function setApplicationLoading(state: ConsumerStateInterface, isLoading: boolean) {
  state.isLoading = isLoading;
}

export function setIdTypes(
  state: ConsumerStateInterface,
  idTypes: Array<IdentificationTypeInterface>,
) {
  state.idTypes = idTypes;
}

/**
 * Marks reentry as completed and resets state to default
 * @param state
 */
export function completeReEntry(
  state: ConsumerStateInterface,
) {
  state.loanApplyReEntry = false;
  state.loanApplyWizardReEntryStep = 1;
}

export function saveLenderInfo(state: ConsumerStateInterface, payload: any) {
  state.lenderInfo = payload;
}

export function setOnboardingDetails(
  state: ConsumerStateInterface,
  data: PortalOnboardingDetailsResponse,
) {
  state.portalOnboarding.portalUrl = data.url;
  state.portalOnboarding.hasAccount = data.is_cp_user;
  state.portalOnboarding.shouldCreateAccount = data.new_cob;
}

export function setApplicationCompleted(state: ConsumerStateInterface, isComplete: boolean) {
  state.applicationCompleted = isComplete;
}

export function setCompleteLaterClicked(
  state: ConsumerStateInterface,
  isCompleteLaterClicked: boolean,
) {
  state.completeLaterClicked = isCompleteLaterClicked;
}

export function setEmploymentInformation(
  state: ConsumerStateInterface,
  data: EmploymentInformation | any,
) {
  state.loanApplyWizardData.employment_information = {
    gross_income: data.annual_income,
    employment_length: data.employment_length,
    employment_status: data.employment_status,
    phone_number: data.phone_number,
    extension: data.extension,
  };
}

export function setAnnualIncome(
  state: ConsumerStateInterface,
  data: number,
) {
  state.loanApplyWizardData.gross_income = data;
}
