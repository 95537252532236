import { honeypot } from 'honeypot-run';
import { PageTypes, PageTypesShorthand } from '@/enums/PageTypes';
import { useStore } from 'vuex';

const store = useStore();

export function useHoneypot() {
  async function trackEvent(eventName: string, page: PageTypesShorthand, step: number = -1) {
    let appIdToUse: string | undefined;
    let overallProcessName: string = '';

    if (!window.honeypot?.didInit) return;

    switch (page) {
      case PageTypesShorthand.MOB:
        appIdToUse = store.getters['MerchantOnboarding/getUserVerifyData']?.merchantUid;
        overallProcessName = PageTypes.MOB;
        break;
      case PageTypesShorthand.MP:
        appIdToUse = store.getters['MerchantPortal/getMerchantData'].merchant.uuid;
        overallProcessName = PageTypes.MP;
        break;
      case PageTypesShorthand.COB:
        appIdToUse = store.getters['Consumer/getConsumerApplicationId'];
        overallProcessName = PageTypes.COB;
        break;
      case PageTypesShorthand.BMOB:
        overallProcessName = PageTypes.BMOB;
        break;
      default:
        break;
    }

    const flow = await honeypot.flow(overallProcessName, appIdToUse || '');
    const eventData: any = { page };

    if (step !== -1) {
      eventData.step = step;
    }
    flow.step(eventName).complete(eventData);
  }

  return { trackEvent };
}
