<template>
  <div class="fs-unmask">
    <CustomButton
      :disabled="disableResend"
      data-test="resendOtpSoftPullBtn"
      full-width
      variant="text"
      @click="handleResendCode">
      <span class="otp-message">Resend Code</span>
    </CustomButton>

    <StandardDialog
      :dialog="show"
      :hide-header-close="true"
      :show-cancel-cta="false"
      :on-cta="closeModal"
      width="420"
      button-label="Close"
      class="success-dialog"
      data-test="resendOtpSoftPullModal">
      <template #title>
        <div class="success-title">
          <v-icon
            icon="mdi-check-circle"
            size="24"
            class="success-icon" />
          <span>Code Resent</span>
        </div>
      </template>
      <template #body>
        <p class="mt-3 mb-6">
          The six digit code has been resent to
          <span v-private>{{ props.phoneNumber }}</span>.
        </p>
      </template>
    </StandardDialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { OtpRegisterRequestDataInterface } from '@/interfaces/otp/OtpRegisterRequestDataInterface';

const props = defineProps({
  invitationUuid: { type: String, default: '' },
  phoneNumber: { type: String, required: true },
});

const store = useStore();

const show = ref(false);
const disableResendBtn = ref(true);
const isLoading = ref(false);

const phoneResendEnabled = computed(() => {
  return store.getters['Otp/getPhoneResendEnabled'];
});
const disableResend = computed(() => {
  return !phoneResendEnabled.value || disableResendBtn.value || isLoading.value;
});

onMounted(() => {
  disableResendTimer();
});

function disableResendTimer() {
  disableResendBtn.value = true;

  setTimeout(() => {
    disableResendBtn.value = false;
  }, 10 * 1000);
}

async function handleResendCode() {
  if (!phoneResendEnabled.value) return;

  isLoading.value = true;

  const { status } = await store.dispatch('Consumer/otpRegister', props.phoneNumber);

  const payload: OtpRegisterRequestDataInterface = {
    phone_number: props.phoneNumber,
  };

  if (props.invitationUuid) {
    payload.invitation_uuid = props.invitationUuid;
  }

  await store.dispatch('Otp/otpRegister', payload);

  isLoading.value = false;

  if (status === 200) {
    show.value = true;
  }

  disableResendTimer();
}

function closeModal() {
  show.value = false;
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.v-btn.custom-button.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):not(.button-link) {
  color: var(--grayscale-color-2) !important;
  background-color: transparent !important;
}
</style>
