import { computed } from 'vue';
import { useStore } from 'vuex';
import GetHumanReadableIdLabel from '@/utils/GetHumanReadableIdLabel';

export default function useHumanReadableApplicationId() {
  const store = useStore();

  const merchantHumanReadableId = computed(() => {
    return store.getters['Auth/getMerchantHumanReadableId'];
  });

  /**
   * Formatting Merchant readable Uuid label,
   * for displaying in template
   */
  const merchantHumanReadableIdLabel = computed(() => {
    return getHumanReadableIdLabel(merchantHumanReadableId.value, false);
  });

  /**
   * Utility function to format human readable ID
   */
  const getHumanReadableIdLabel = (hrid: string, prefix = true): string => {
    return GetHumanReadableIdLabel(hrid, prefix);
  };

  /**
   * Adds hyphens to a numeric string, equivalent to above method but does not cause problems
   * @param humanReadableId
   * @param prefix
   */
  const formatHRID = (humanReadableId: string, prefix = true): string => {
    return GetHumanReadableIdLabel(humanReadableId, prefix);
  };

  return {
    merchantHumanReadableId,
    merchantHumanReadableIdLabel,
    getHumanReadableIdLabel,
    formatHRID,
  };
}
