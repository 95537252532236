<template>
  <standard-dialog
    :dialog="dialog"
    :show-progress-indicator="processing"
    hide-header-close
    header-style="background-color: #A64040"
    :persistent="true">
    <template #title>
      <div class="title-wrap">
        <v-icon>mdi-information-outline</v-icon>
        <span>Please Review</span>
      </div>
    </template>
    <template #body>
      <div class="body-text">
        The property address matches your home address.
        Please update the address or confirm the work
        will be done at your home address.
      </div>
    </template>
    <template #actions>
      <div class="button-wrapper">
        <custom-button
          full-width
          data-test="confirmBtn"
          :disabled="processing || disabled"
          @click="onCta">
          Work to be completed at home address
        </custom-button>
        <custom-button
          full-width
          variant="secondary"
          class="cancel-btn"
          data-test="cancelBtn"
          :disabled="processing || disabled"
          @click="onCancel">
          Update Property Address
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetProcessing from '@/mixins/GetProcessing';

export default defineComponent({
  name: 'ReviewAddressModal',

  components: {
    StandardDialog,
    CustomButton,
  },

  mixins: [GetProcessing],

  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    disabled: false,
  }),

  methods: {
    onCta() {
      this.disabled = true;
      this.$emit('confirmed');
    },

    onCancel() {
      this.$emit('declined');
    },
  },
});
</script>

<style lang="scss" scoped>
.title-wrap {
    color: #fff;
    display: flex;
    align-items: center;

    span {
        margin-left: 2rem;
    }
}

.body-text {
    padding: 1.5rem 1rem;
}

.button-wrapper {
    .cancel-btn {
        margin: 0 !important;
        margin-top: 2rem !important;
    }
}
</style>
