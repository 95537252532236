<template>
  <div>
    <div
      class="customer-summary-charge-section"
      :class="[
        transactionStatusErrored ? 'errored-transaction' : '',
        transactionStatusPending ? 'pending-transaction' : '',
        showTransactions ? 'clear-border' : '']">
      <div v-if="isInitialCharge && !showInitialForm">
        <div v-if="!canTransactFullAmount">
          <div v-if="!paymentConfirmation">
            <charge-header
              :initial-charge="isInitialCharge"
              :charge-name="chargeName"
              :max-charge-value="chargeMaxAmount"
              :max-charge-percentage="chargeMaxPercentage"
              :formatted-max-charge="formattedMaxAmount"
              :show-percentages="true"
              @helpIcon="showHelpModal" />

            <charge-body
              :initial-charge="isInitialCharge"
              :max-charge-value="chargeMaxAmount"
              @showConfirmation="showSendPaymentRequestConfirmation" />
          </div>

          <div v-if="paymentConfirmation">
            <charge-header
              :payment-confirmation="paymentConfirmation"
              @goBack="showInitialTransactionAgain" />

            <charge-body
              :payment-confirmation="paymentConfirmation"
              :charge-name="chargeName"
              :charge-value="amount"
              :consumer-name="consumerName"
              :disable-send-button="buttonDisabled"
              @sendPaymentRequest="sendPaymentRequest" />
          </div>
        </div>

        <div v-if="canTransactFullAmount">
          <div v-if="!finalConfirmation">
            <charge-header
              :final-charge-question="true"
              :charge-name="chargeName" />

            <charge-body
              :final-charge-question="true"
              @notFinalCharge="notFinalCharge"
              @yesFinalCharge="yesFinalCharge" />
          </div>

          <div v-if="finalConfirmation">
            <charge-header
              :second-confirmation="true" />

            <charge-body
              :second-confirmation="true"
              :confirmation-text="finalConfirmationText"
              @cancelTransaction="cancelFinalCharge"
              @proceed="proceedWithFinalCharge" />
          </div>
        </div>
      </div>

      <div v-if="showInitialForm">
        <div v-if="!paymentConfirmation && !calculatedFinalCharge && !calculatedMiddleToFinal">
          <charge-header
            :initial-charge="true"
            :charge-name="chargeName"
            :max-charge-value="chargeMaxAmount"
            :max-charge-percentage="chargeMaxPercentage"
            :formatted-max-charge="formattedMaxAmount"
            @helpIcon="showHelpModal" />

          <charge-body
            :initial-charge="true"
            :second-charge="true"
            :max-charge-value="chargeMaxAmount"
            @cancelTransaction="cancelFinalCharge"
            @showConfirmation="showSendPaymentRequestConfirmation"
            @sendPaymentRequest="sendPaymentRequest"
            @sendFinalCharge="middleToFinal" />
        </div>

        <div v-if="paymentConfirmation && !calculatedFinalCharge && !calculatedMiddleToFinal">
          <charge-header
            :payment-confirmation="true"
            @goBack="showInitialTransactionAgain" />

          <charge-body
            :payment-confirmation="true"
            :charge-name="chargeName"
            :charge-value="amount"
            :consumer-name="consumerName"
            :disable-send-button="buttonDisabled"
            @sendPaymentRequest="sendPaymentRequest" />
        </div>

        <div v-if="calculatedFinalCharge && !calculatedMiddleToFinal">
          <charge-header
            :second-confirmation="true" />

          <charge-body
            :second-confirmation="true"
            :confirmation-text="manualFinal"
            :disable-send-button="buttonDisabled"
            @cancelTransaction="cancelCalculatedFinalCharge"
            @proceed="sendPaymentRequest" />
        </div>

        <div v-if="calculatedMiddleToFinal && !calculatedFinalCharge">
          <charge-header
            :second-confirmation="true" />

          <charge-body
            :second-confirmation="true"
            :confirmation-text="finalConfirmationText"
            @cancelTransaction="cancelFinalCharge"
            @proceed="proceedWithFinalCharge" />
        </div>
      </div>

      <div v-if="transactionPending">
        <div v-if="!secondConfirmation && !showInitialForm && !pendingFinalQuestion">
          <charge-header
            :transaction-pending="true"
            :transaction-status="transactionStatus"
            :transaction-status-errored="transactionStatusErrored"
            @helpIconExpired="showExpiredHelpModal" />

          <charge-body
            :transaction-pending="true"
            :transaction-status-errored="transactionStatusErrored"
            :transaction-status="transactionStatus"
            :charge-name="pendingChargeName"
            :charge-value="pendingChargeAmount"
            :charge-date="pendingChargeDate"
            :formatted-max-charge="formattedAmount"
            @showInitialForm="resetPendingTransaction"
            @sendPaymentRequest="sendPaymentRequest" />
        </div>

        <div v-if="secondConfirmation && !showInitialForm && !pendingFinalQuestion">
          <charge-header
            :second-confirmation="secondConfirmation" />

          <charge-body
            :second-confirmation="secondConfirmation"
            :confirmation-text="secondConfirmationText"
            @cancelTransaction="cancelPendingConfirmation"
            @proceed="showPendingFinal" />
        </div>

        <div v-if="secondConfirmation && pendingFinalQuestion && !showInitialForm">
          <charge-header
            :final-charge-question="true"
            :charge-name="chargeName" />

          <charge-body
            :final-charge-question="true"
            @notFinalCharge="notPendingFinalCharge"
            @yesFinalCharge="yesPendingFinalCharge" />
        </div>
      </div>

      <div v-if="completedTransaction && !customInitialCharge && !transactionPending">
        <div v-if="!finalCharge && !showInitialForm">
          <charge-header
            :completed-transaction="completedTransaction"
            :show-pending-date="showPendingDate"
            :next-charge-date="nextChargeDate" />

          <charge-body
            :completed-transaction="completedTransaction"
            :all-completed-transactions="allCompletedTransactions" />
        </div>

        <div v-if="finalCharge && !finalConfirmation && !showInitialForm">
          <charge-header
            :final-charge-question="true"
            :charge-name="chargeName" />

          <charge-body
            :final-charge-question="true"
            @notFinalCharge="notFinalCharge"
            @yesFinalCharge="yesFinalCharge" />
        </div>

        <div v-if="finalCharge && finalConfirmation && !showInitialForm">
          <charge-header
            :second-confirmation="true" />

          <charge-body
            :second-confirmation="true"
            :confirmation-text="finalConfirmationText"
            @cancelTransaction="cancelFinalCharge"
            @proceed="proceedWithFinalCharge" />
        </div>
      </div>

      <info-modal
        :show="showInfoModal"
        modal-type="info-modal"
        @close="closeInfoModal" />

      <info-modal
        :show="expiredHelpModal"
        modal-type="expired-modal"
        @close="closeInfoModal" />
    </div>

    <div
      v-if="transactionPending && transactionStatus === 'Pending'
        && !secondConfirmation && !showInitialForm"
      class="pending-transaction-button">
      <custom-button
        full-width
        data-test="sendButton"
        size="small"
        variant="secondary"
        @click="showResetPendingConfirmation">
        Send New {{ chargeRequestLabel }}
      </custom-button>
    </div>

    <div
      v-if="completedTransaction
        && canSendTransaction
        && !customInitialCharge
        && !finalCharge
        && !showInitialForm"
      class="mb-4 mx-4"
      style="margin-top: 5rem;">
      <custom-button
        full-width
        data-test="sendButton"
        @click="finalChargeQuestion">
        Send New {{ chargeRequestLabel }}
      </custom-button>
    </div>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import LoanApplicationStatusesEnum from '@/enums/Consumer/LoanApplicationStatusesEnum';
import { PaymentRequestData } from '@/interfaces/merchantPortal/PaymentRequestData';
import GetProcessing from '@/mixins/GetProcessing';
import StagedFundingEnum from '@/enums/Merchant/StagedFundingEnum';
import ChargeHeader from '@/components/CustomerSummaryCard/ChargeHeader.vue';
import ChargeBody from '@/components/CustomerSummaryCard/ChargeBody.vue';
import InfoModal from '@/components/CustomerSummaryCard/Modals/ChargeTransactionInfoModal.vue';
import { defineComponent, computed, ref } from 'vue';
import { useChargeRequestLabel } from '@/composables/useChargeRequestLabel';

export default defineComponent({
  name: 'SendNew',
  components: {
    CustomButton,
    ChargeHeader,
    ChargeBody,
    InfoModal,
  },
  mixins: [GetProcessing],
  props: {
    selectedLoan: { type: Object, default: () => ({}) },
  },
  setup() {
    const amount = ref('');
    const { chargeRequestLabel } = useChargeRequestLabel();

    const secondConfirmationText = `By sending a new ${chargeRequestLabel.value} you will expire `
    + 'the previous pending request that is not yet approved.';

    const finalConfirmationText = 'By clicking “Next” I acknowledge this will be the Final Charge '
    + `and I won't be able to send additional ${chargeRequestLabel.value.toLowerCase()}s once I receive this payment.`;

    const manualFinal = computed(() => {
      return `By requesting the full loan amount of ${CurrencyFormatLong(amount.value)}`
        + ' this will become a Final Charge, and you won\'t be able to send additional '
        + `${chargeRequestLabel.value.toLowerCase()}s once you receive this payment`;
    });

    return {
      amount,
      chargeRequestLabel,
      secondConfirmationText,
      finalConfirmationText,
      manualFinal,
    };
  },
  data() {
    return {
      disableInputs: true,
      showInfoModal: false,
      paymentConfirmation: false,
      expiredHelpModal: false,
      secondConfirmation: false,
      finalCharge: false,
      showInitialForm: false,
      finalConfirmation: false,
      paymentSucess: false,
      buttonDisabled: false,
      isInitialForm: false,
      isFinalCharge: false,
      showInitialCharge: false,
      finalChargeConfirmed: false,
      calculatedFinalCharge: false,
      pendingFinalQuestion: false,
      calculatedMiddleToFinal: false,
      customInitialCharge: false,
    };
  },
  computed: {
    stagedFundingData() {
      return this.selectedLoanApplication?.staged_funding;
    },
    isInitialCharge(): boolean {
      return this.customInitialCharge
      || (!this.transactionPending && this.allCompletedTransactions.length === 0);
    },
    allTransactionsRefunded(): boolean {
      return this.stagedFundingData?.all_transaction_refunded;
    },
    canTransactFullAmount(): boolean {
      return !!this.stagedFundingData?.charges_max_amount.consumer_final_charge;
    },
    canSendTransaction(): boolean {
      return this.stagedFundingData?.can_receive_transaction;
    },
    transactionPending(): boolean {
      const statuses = [
        StagedFundingEnum.PENDING,
        StagedFundingEnum.ERRORED,
        StagedFundingEnum.EXPIRED,
      ];
      return statuses.includes(this.stagedFundingData?.most_recent_cpr.status);
    },
    transactionStatus(): string | undefined {
      if (this.stagedFundingData?.most_recent_cpr.status === StagedFundingEnum.PENDING) {
        return StagedFundingEnum.PENDING;
      }
      if (this.stagedFundingData?.most_recent_cpr.status === StagedFundingEnum.EXPIRED) {
        return StagedFundingEnum.EXPIRED;
      }
      if (this.stagedFundingData?.most_recent_cpr.status === StagedFundingEnum.ERRORED) {
        return StagedFundingEnum.ERRORED;
      }
      if (this.stagedFundingData?.most_recent_cpr.status === StagedFundingEnum.APPROVED) {
        return StagedFundingEnum.APPROVED;
      }
      return undefined;
    },
    completedTransaction(): boolean {
      return this.stagedFundingData?.most_recent_cpr.status === StagedFundingEnum.APPROVED
      || this.stagedFundingData?.most_recent_cpr.status === StagedFundingEnum.REFUND;
    },
    showPendingDate(): boolean {
      return !!this.stagedFundingData?.consumer_successful_transactions.date;
    },
    chargeMaxAmount(): number {
      if (this.chargeName === 'Initial Charge' && !this.canTransactFullAmount) {
        return this.stagedFundingData?.charges_max_amount
          .consumer_deposit_initial_charge.max_amount;
      }

      if (this.chargeName === 'Initial Charge'
          && this.canTransactFullAmount
          && !this.finalChargeConfirmed) {
        return this.stagedFundingData?.charges_max_amount
          .consumer_deposit_initial_charge.max_amount;
      }

      if (this.chargeName === 'Initial Charge'
          && this.canTransactFullAmount
          && this.finalChargeConfirmed) {
        return this.stagedFundingData?.charges_max_amount.consumer_final_charge.max_amount;
      }

      if (this.chargeName !== 'Initial Charge' && !this.finalChargeConfirmed) {
        return this.stagedFundingData?.charges_max_amount.consumer_project_charge.max_amount;
      }

      if (this.chargeName !== 'Initial Charge' && this.finalChargeConfirmed) {
        return this.stagedFundingData?.charges_max_amount.consumer_final_charge.max_amount;
      }

      return 0;
    },
    formattedMaxAmount(): string {
      return CurrencyFormatLong(this.chargeMaxAmount);
    },
    chargeMaxPercentage(): string {
      if (this.chargeName === 'Initial Charge' && !this.canTransactFullAmount) {
        return this.stagedFundingData?.charges_max_amount
          .consumer_deposit_initial_charge.percentages;
      }
      return '';
    },
    chargeName(): string {
      if (this.finalChargeConfirmed) {
        return 'Final Charge';
      }

      if (this.isInitialCharge) {
        return 'Initial Charge';
      }
      return this.stagedFundingData?.charge_name;
    },
    transactionCode(): number {
      if (this.finalChargeConfirmed) {
        return 113;
      }

      if (this.chargeName === 'Initial Charge') {
        return 109;
      }

      return 111;
    },
    transactionStatusErrored() {
      return (this.transactionStatus === StagedFundingEnum.ERRORED
      || this.transactionStatus === StagedFundingEnum.EXPIRED)
      && !this.showInitialForm && !this.secondConfirmation;
    },
    transactionStatusPending() {
      return this.transactionStatus === StagedFundingEnum.PENDING
      && !this.showInitialForm && !this.secondConfirmation;
    },
    pendingChargeName(): string {
      return this.stagedFundingData?.most_recent_cpr.charge_name;
    },
    pendingChargeAmount(): string {
      return this.stagedFundingData?.most_recent_cpr.amount;
    },
    formattedAmount(): string {
      return CurrencyFormatLong(this.pendingChargeAmount);
    },
    pendingChargeDate(): string {
      return this.stagedFundingData?.most_recent_cpr.date;
    },
    consumerName(): string {
      return `${this.selectedLoanApplication.user?.first_name} ${this.selectedLoanApplication.user?.last_name}`;
    },
    nextChargeDate(): string {
      return this.stagedFundingData?.consumer_successful_transactions.date;
    },
    showTransactions(): boolean {
      return this.completedTransaction && !this.finalCharge && !this.showInitialForm;
    },
    allCompletedTransactions(): any {
      return this.stagedFundingData?.consumer_successful_transactions.successful_transaction;
    },
    creditBalance(): number {
      return Number(this.selectedLoanApplication.consumer_account.available_spend || 0);
    },
    requestData(): PaymentRequestData {
      return {
        consumer_application_uuid: this.selectedLoanApplication.consumer_application,
        amount: Number(this.amount),
        transaction_type: this.transactionCode,
        phone: this.selectedLoanApplication.phone_number,
      };
    },
    selectedLoanApplication() {
      return this.$store.getters['MerchantPortal/getSelectedLoanApplication'];
    },
    applicationSelected() {
      return this.selectedLoanApplication.consumer_application;
    },
    consumerApplication() {
      return this.selectedLoanApplication.consumer_application;
    },
    sendDisabled() {
      return this.processing
        || !this.consumerApplication
        || !this.creditBalance
        || Number(this.amount) <= 0
        || Number(this.amount) > this.creditBalance;
    },
  },
  watch: {
    selectedLoan: {
      handler(newVal: any) {
        this.clearData();
        this.disableInputs = newVal.status !== LoanApplicationStatusesEnum.APPROVED_ACCEPTED;
      },
      immediate: true,
    },
  },
  async created() {
    await this.fetchTransactionsDescriptions();
  },
  methods: {
    showHelpModal() {
      this.showInfoModal = true;
    },
    closeInfoModal() {
      this.showInfoModal = false;
      this.expiredHelpModal = false;
    },
    showPendingFinal() {
      if (this.chargeName !== 'Final Charge' && this.chargeName !== 'Initial Charge') {
        if (this.chargeMaxAmount === 0) {
          this.finalChargeConfirmed = true;
          this.showInitialForm = true;
          return;
        }
      }

      if (this.canTransactFullAmount) {
        this.pendingFinalQuestion = true;
      } else {
        this.showInitialForm = true;
      }
    },
    showSendPaymentRequestConfirmation(val: string) {
      if (this.canTransactFullAmount && !this.finalChargeConfirmed) {
        if (Number(val) === this.creditBalance) {
          this.amount = val;
          this.calculatedFinalCharge = true;
          return;
        }
      }
      this.amount = val;
      this.paymentConfirmation = true;
    },
    async sendPaymentRequest() {
      this.buttonDisabled = true;

      const paymentStatus = await this.$store.dispatch('MerchantPortal/dispatchPaymentRequest', this.requestData);

      if (paymentStatus === 201) {
        const formattedAmount = CurrencyFormatLong(this.amount);

        const chargeData: any = {
          chargeName: this.chargeName,
          chargeValue: formattedAmount,
          consumerName: this.consumerName,
        };

        await this.$store.dispatch('MerchantPortal/showSuccessAlert', chargeData);

        this.clearData();
      }

      this.buttonDisabled = false;

      await this.$store.dispatch('MerchantPortal/selectLoanApplication', this.selectedLoanApplication.consumer_application);
    },
    showExpiredHelpModal() {
      this.expiredHelpModal = true;
    },
    showInitialTransactionAgain() {
      this.paymentConfirmation = false;
    },
    cancelTransaction() {
      this.secondConfirmation = false;
    },
    showFinalConfirmation() {
      this.finalConfirmation = true;
    },
    showCompletedTransactions() {
      this.finalCharge = false;
      this.isFinalCharge = false;
      this.showInitialCharge = true;
      this.showInitialForm = true;
    },
    showInitialFormWithFinal() {
      this.finalCharge = false;
      this.isFinalCharge = true;
      this.showInitialCharge = true;
      this.showInitialForm = true;
    },
    cancelCurrentTransaction() {
      this.paymentConfirmation = false;
    },
    yesFinalCharge() {
      this.finalConfirmation = true;
    },
    notFinalCharge() {
      this.showInitialForm = true;
    },
    notPendingFinalCharge() {
      this.showInitialForm = true;
    },
    yesPendingFinalCharge() {
      this.finalChargeConfirmed = true;
      this.showInitialForm = true;
    },
    cancelFinalCharge() {
      this.finalCharge = false;
      this.secondConfirmation = false;
      this.finalConfirmation = false;
      this.finalChargeConfirmed = false;
      this.showInitialForm = false;
      this.pendingFinalQuestion = false;
      this.calculatedMiddleToFinal = false;
    },
    proceedWithFinalCharge() {
      this.finalChargeConfirmed = true;
      this.showInitialForm = true;
      this.calculatedMiddleToFinal = false;
    },
    cancelCalculatedFinalCharge() {
      this.calculatedFinalCharge = false;
    },
    resetPendingTransaction() {
      if (this.chargeName !== 'Final Charge' && this.chargeName !== 'Initial Charge') {
        if (this.chargeMaxAmount === 0) {
          this.finalChargeConfirmed = true;
          this.showInitialForm = true;
          return;
        }
      }

      if (this.canTransactFullAmount) {
        this.secondConfirmation = true;
        this.pendingFinalQuestion = true;
      } else {
        this.showInitialForm = true;
      }
    },
    showResetPendingConfirmation() {
      this.secondConfirmation = true;
    },
    cancelPendingConfirmation() {
      this.secondConfirmation = false;
    },
    finalChargeQuestion() {
      if (this.allTransactionsRefunded) {
        this.customInitialCharge = true;
        return;
      }

      if (this.chargeName !== 'Final Charge' && this.chargeName !== 'Initial Charge') {
        if (this.chargeMaxAmount === 0) {
          this.finalCharge = true;
          this.finalConfirmation = true;
        }
      }
      this.finalCharge = true;
    },
    middleToFinal() {
      this.showInitialForm = true;
      this.calculatedMiddleToFinal = true;
    },
    clearData() {
      this.amount = '';
      this.finalCharge = false;
      this.secondConfirmation = false;
      this.finalConfirmation = false;
      this.finalChargeConfirmed = false;
      this.showInitialForm = false;
      this.paymentConfirmation = false;
      this.isFinalCharge = false;
      this.pendingFinalQuestion = false;
      this.calculatedMiddleToFinal = false;
      this.customInitialCharge = false;
    },
    fetchTransactionsDescriptions(): Promise<any> {
      return this.$store.dispatch('MerchantPortal/dispatchFetchTransactionsTypes');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.pending-transaction-button {
  margin: 2.5rem 1rem 1rem 1rem;
}

.customer-summary-charge-section {
  border-radius: 8px;
  border: 1px solid var(--grayscale-color-3);
  margin: 0.5rem 1rem 1rem 1rem;
}

.pending-transaction {
  border: 1px solid var(--success-color);
}

.errored-transaction {
  border: 1px solid var(--error-color);
}

.clear-border {
  border: none;
}

.customer-summary-card-send {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  &__cta {
    margin-top: auto;
    margin-bottom: 1rem;
  }
}

.amount-input {
  margin: 0 !important;

  :deep(.v-text-field__details) {
    margin-bottom: 1.125rem !important;
  }
}

.transaction-description-input {
  margin: 0 !important;

  :deep(.v-text-field__details) {
    margin-bottom: 0.625rem !important;
  }
}
</style>
