<template>
  <StandardDialog
    :dialog="showDialog"
    :on-cancel="emitClose"
    content-class="standard-aan-msg-dialog"
    width="420px"
  >
    <template #body>
      <div data-test="approvedForLessMsg" class="fs-unmask standard-aan-msg">
        <p class="mb-0">
          Application ID: {{ humanReadableApplicationId }}
        </p>
        <p class="mb-0">
          {{ applicantName }}
        </p>
        <p
          v-private
          v-fs-exclude
          class="mb-0">
          {{ loanApplyWizardData.address_1 || loanApplyWizardData.home_address?.address_1 }}
        </p>
        <p
          v-private
          v-fs-exclude
          class="mb-0">
          {{ loanApplyWizardData.city || loanApplyWizardData.home_address?.city }}
          {{ loanApplyWizardData.state || loanApplyWizardData.home_address?.state }}
        </p>
        <p
          v-private
          v-fs-exclude
          class="mb-0">
          {{ loanApplyWizardData.zip_code || loanApplyWizardData.home_address?.zip_code }}
        </p>
        <p>{{ dateToday }}</p>

        <p>Dear {{ applicantName }}:</p>

        <p>
          Thank you for your applying for a
          <strong v-private>{{ requestedLoanAmountLabel }}</strong> loan through
          {{ brandObject.legal_business_name }} (“{{ brandObject.brand_name }}”).
          Your request for the loan and specific loans terms were carefully considered by the lenders listed below
          and we regret that none of them was able to approve your application.
          <strong>While we are unable to offer you the full amount requested, we have approved you for a
            loan amount of <span v-private>{{ approvedAmountLabel }}</span></strong>.
          If this offer is acceptable to you, please accept the offer in the Momnt mobile application by
          <span class="text-no-wrap">{{ sevenDaysAdvance }}</span> to continue.
        </p>

        <LenderList :lenders="lenders" />

        <p>
          You may request a statement of specific reasons why each lender denied your application
          for credit if you do so within 60 days from the date you receive this letter. We will
          provide you with the statement of reasons within 30 days after receiving your request.
          Please direct your request to
          <a
            v-fs-exclude
            :href="`mailto:${brandObject.footer_object.support_email}`"
            class="aan-address__link"
          >
            {{ brandObject.footer_object.support_email }}
          </a>.
        </p>

        <p><strong>Your Right to Get Your Consumer Report</strong></p>

        <p v-if="approvedType === 'soft'">
          The consumer reporting agency played no part in our decision and is unable to supply the
          specific reasons why we have denied credit to you. You have a right under the Fair Credit
          Reporting Act to know the information contained in your credit file at the consumer
          reporting agency. You also have a right to a free copy of your report from the consumer
          reporting agency if you request it no later than 60 days after you receive this notice. If
          you find that any information contained in the report you receive is inaccurate or
          incomplete, you have the right to dispute the matter with the reporting agency. Any
          questions regarding such information should be directed to TransUnion.
        </p>

        <p v-else>
          The consumer reporting agency {{ brandObject.legal_business_name }} (“{{ brandObject.brand_name }}”)
          contacted that provided
          information that influenced our decision in whole or in part was:
          TransUnion, P.O. Box 1000, Chester, PA 19016,
          telephone number (toll-free) 800-888-4213.
          The consumer reporting agency played no part in our decision and is unable to supply
          the specific reasons why we have denied credit to you. You have a right under the
          Fair Credit Reporting Act to know the information contained in your credit file
          at the consumer reporting agency. You also have a right to a free copy of your
          report from the consumer reporting agency if you request it no later than 60
          days after you receive this notice. If you find that any information contained
          in the report you receive is inaccurate or incomplete, you have the right to
          dispute the matter with the reporting agency. Any questions regarding such
          information should be directed to TransUnion.
        </p>

        <p>
          This notice is being provided by {{ brandObject.legal_business_name }}. If you have any
          questions regarding this notice, you should contact {{ brandObject.brand_name }}
          directly at:
        </p>

        <ConsumerLoanApplyWizardAanAddress :brand-data="brandObject" />

        <p>
          We also obtained your credit score from TransUnion and used it in making our credit
          decision. Your credit score is a number that reflects the information in your consumer
          report. Your credit score can change, depending on how the information in your consumer
          report changes.
        </p>

        <p class="mb-0">
          Your credit score: {{ creditEngineCreditScore }}
        </p>
        <p>
          Date: {{ dateToday }}
        </p>

        <p>Scores range from a low of 300 to a high of 850.</p>

        <ConsumerLoanApplyWizardAanReasons :reasons="creditEngineReasons" />

        <ConsumerLoanApplyWizardAanNotice />

        <p>
          {{ brandObject.legal_business_name }}
        </p>
      </div>
    </template>
    <template #actions>
      <CustomButton
        full-width
        class="mt-4"
        @click="emitClose"
      >
        Close
      </CustomButton>
    </template>
  </StandardDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyWizardAanAddress from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Address.vue';
import ConsumerLoanApplyWizardAanNotice from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Notice.vue';
import ConsumerLoanApplyWizardAanReasons from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Reasons.vue';
import ConsumerLoanApplyMessagesAanMixin from '@/mixins/Consumer/LoanApply/ConsumerLoanApplyMessagesAanMixin';
import CreditEngineSoftPullResultsDataMixin from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullResultsDataMixin';
import CreditEngineSoftPullApprovedAmountMixin from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullApprovedAmountMixin';
import CreditEngineHardPullResultsDataMixin from '@/mixins/Consumer/LoanApply/CreditEngineHardPullResultsDataMixin';
import CreditEngineHardPullApprovedAmountMixin from '@/mixins/Consumer/LoanApply/CreditEngineHardPullApprovedAmountMixin';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import LenderList from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/LenderList.vue';
import { get } from 'lodash';
import moment from 'moment';
import { LenderAddressInterface } from '@/interfaces/consumer/LenderAddressInterface';
import DATE_CONSTANTS from '@/constants/DateConstants';

export default defineComponent({
  name: 'ApprovedForLessMsgDialog',

  components: {
    StandardDialog,
    CustomButton,
    ConsumerLoanApplyWizardAanAddress,
    ConsumerLoanApplyWizardAanReasons,
    ConsumerLoanApplyWizardAanNotice,
    LenderList,
  },

  mixins: [
    CreditEngineSoftPullResultsDataMixin,
    CreditEngineSoftPullApprovedAmountMixin,
    CreditEngineHardPullResultsDataMixin,
    CreditEngineHardPullApprovedAmountMixin,
    ConsumerLoanApplyMessagesAanMixin,
    GetHumanReadableIdLabelMixin,
  ],

  props: {
    showDialog: { type: Boolean, default: false },
    approvedType: {
      type: String,
      required: true,
      validator: (value: string) => ['soft', 'hard'].includes(value),
    },
  },

  data() {
    const lenders: Array<LenderAddressInterface> = [];
    return {
      lenders,
    };
  },

  computed: {
    approvedAmountLabel(): string {
      return this.approvedType === 'soft'
        ? this.approvedAmountSoftPullLabel
        : this.approvedAmountHardPullLabel;
    },
    creditEngineReasons(): string[] {
      return get(this.creditEngineSoftPullResults, 'reasons', []);
    },
    creditEngineCreditScore(): string {
      return get(this.creditEngineSoftPullResults, 'credit_score', 0);
    },
    brandObject(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    applicantName(): string {
      const { first_name, last_name } = this.loanApplyWizardData;
      return `${first_name} ${last_name}`;
    },
    sevenDaysAdvance(): string {
      return moment().add(7, 'd').format(DATE_CONSTANTS.dateFormat);
    },
    humanReadableApplicationId() {
      return this.getHumanReadableIdLabel(this.consumerHumanReadableApplicationId);
    },
    consumerHumanReadableApplicationId(): string {
      return this.$store.getters['Consumer/getConsumerHumanReadableApplicationId'];
    },
  },

  async created() {
    this.lenders = this.approvedType === 'soft'
      ? await this.$store.dispatch('Consumer/getActiveLenders')
      : [this.$store.getters['Consumer/getLenderInfo']];
  },

  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/standard-aan";
</style>
