<template>
  <v-expansion-panels class="expandable-categories-tables" variant="accordion">
    <v-expansion-panel
      v-for="(category, categoryIndex) in expandableCategories"
      :key="categoryIndex"
      class="expandable-categories-tables-title"
      static
      tile
      :data-test="`expansionPanels${categoryIndex}`">
      <v-expansion-panel-title :data-test="`productExpandButton${categoryIndex}`">
        {{ category.label || category }}
        <FallbackTooltip v-if="category.has_fallback" class="ml-2" />
        <SeventyFiveKTooltip v-if="has75kProduct(category)" class="ml-2" />
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <div v-if="has75kProduct(category)" class="d-flex justify-end px-4 py-2">
          <SeventyFiveKFilter
            :active="has75kCategoryFilter(category)"
            @toggle="event => set75kCategoryFilter(category, event)" />
          <v-chip
            v-if="has75kCategoryFilter(category)"
            class="filter-75k-chip"
            closable
            close-icon="mdi-close"
            color="var(--grayscale-color-4)"
            variant="flat"
            @click:close="set75kCategoryFilter(category, false)">
            75K
          </v-chip>
        </div>

        <RateSheetOptionsDataTable
          ref="rateSheetDataTable"
          :rate-sheet-options="getCategoryRateSheets(category)"
          :disabled="disabled"
          :button-toggle="buttonToggle"
          :disable-add-btns="disableAddBtns"
          :mobile-breakpoint="mobileBreakpoint"
          :is-from-offer-codes="isFromOfferCodes"
          :table-data-test="category.dataTest"
          :show-header-info-on-mount="false"
          :items-per-page="10"
          :show-header="false"
          :show-legend="false"
          additional-product-offerings
          pagination="fast"
          :ratesheet-table="true"
          @toggle="$emit('toggle', $event)" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import {
  computed, reactive, ref, watch,
} from 'vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { useStore } from 'vuex';
import RateSheetOptionsDataTable from '@/components/RateSheetOptions/DataTable/index.vue';
import FallbackTooltip from '@/components/Merchant/Portal/RateSheets/FallbackTooltip.vue';
import SeventyFiveKTooltip from '@/components/Merchant/Portal/RateSheets/SeventyFiveKTooltip.vue';
import SeventyFiveKFilter from './SeventyFiveKFilter.vue';

interface RateSheetTablesProps {
  rateSheetOptions: RateSheetData[];
  disabled?: boolean;
  buttonToggle?: boolean;
  disableAddBtns?: boolean;
  mobileBreakpoint?: number;
  isFromOfferCodes?: boolean;
}

// TODO: remove fallback modal when 75k feature flag is permanent
defineEmits(['toggle', 'show-fallback-modal']);
const props = withDefaults(defineProps<RateSheetTablesProps>(), {
  disabled: false,
  buttonToggle: false,
  disableAddBtns: false,
  mobileBreakpoint: 600,
  isFromOfferCodes: false,
});

const { isFeatureEnabled, FeatureFlagsConstants } = useFeatureFlag();
const store = useStore();

const productsByCategory = reactive({
  fixedRateAndPayment: [] as RateSheetData[],
  deferredAndZeroInterest: [] as RateSheetData[],
  riskBasedPricing: [] as RateSheetData[],
  brandingPlus: [] as RateSheetData[],
});

const GROUP_IDS = {
  FIXED_RATE_AND_PAYMENT: 'Fixed Rate and Fixed Payment',
  DEFERRED_AND_ZERO_INTEREST: 'Deferred Interest and Zero Interest',
  RISK_BASED_PRICING: 'Risk Based Pricing',
  BRANDING_PLUS: 'Branding Plus',
};

function sortProductsByCategory() {
  productsByCategory.fixedRateAndPayment = [];
  productsByCategory.deferredAndZeroInterest = [];
  productsByCategory.riskBasedPricing = [];
  productsByCategory.brandingPlus = [];

  if (!props.rateSheetOptions?.length) return;

  props.rateSheetOptions.forEach(product => {
    if (product.is_plus) {
      productsByCategory.brandingPlus.push(product);
    } else if (product.group_id === GROUP_IDS.RISK_BASED_PRICING) {
      productsByCategory.riskBasedPricing.push(product);
    } else if (product.group_id === GROUP_IDS.FIXED_RATE_AND_PAYMENT) {
      productsByCategory.fixedRateAndPayment.push(product);
    } else if (product.group_id === GROUP_IDS.DEFERRED_AND_ZERO_INTEREST) {
      productsByCategory.deferredAndZeroInterest.push(product);
    }
  });
}

const expandableCategories = computed(() => {
  const brandingData = store.getters['Ui/getBrandingObject'];
  return [
    {
      categoryId: 'fixedRateAndPayment',
      label: GROUP_IDS.FIXED_RATE_AND_PAYMENT,
      dataTest: 'rateTable-fixedRateAndPayment',
      has_fallback: false,
    }, {
      categoryId: 'deferredAndZeroInterest',
      label: GROUP_IDS.DEFERRED_AND_ZERO_INTEREST,
      dataTest: 'rateTable-deferredAndZeroInterest',
      has_fallback: isFeatureEnabled(FeatureFlagsConstants.USE_FALLBACK_PRODUCTS),
    }, {
      categoryId: 'riskBasedPricing',
      label: GROUP_IDS.RISK_BASED_PRICING,
      dataTest: 'rateTable-riskBasedPricing',
      has_fallback: false,
    }, {
      categoryId: 'brandingPlus',
      label: `${brandingData.brand_name}+`,
      dataTest: 'rateTable-BrandingPlus',
      has_fallback: false,
    },
  ];
});

function has75kProduct(category: any) {
  if (!isFeatureEnabled(FeatureFlagsConstants.CREDIT_LIMIT_75K)) return false;
  const rateSheets = productsByCategory[category.categoryId as keyof typeof productsByCategory];
  return rateSheets.some(rateSheet => rateSheet.max_credit_limit === 75000);
}

const filtered75KCategories = ref<Map<string, boolean>>(new Map());
const set75kCategoryFilter = (category: any, value: boolean) => filtered75KCategories.value.set(category.categoryId, value);
const has75kCategoryFilter = (category: any) => filtered75KCategories.value.get(category.categoryId) === true;

function getCategoryRateSheets(category: any): Array<RateSheetData> {
  const products = productsByCategory[category.categoryId as keyof typeof productsByCategory];

  if (has75kCategoryFilter(category)) {
    return products.filter(product => product.max_credit_limit === 75000);
  }

  return products;
}

watch(
  () => props.rateSheetOptions?.length,
  () => sortProductsByCategory(),
  { immediate: true },
);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/font-variables";

.expandable-categories-tables {
  background-color: var(--grayscale-color-5);
  padding: 1rem;
  gap: 1rem;

  &-title {
    overflow: hidden;
    font-weight: 600;
    font-size: 1rem;
    color: var(--grayscale-color-1);
  }

  :deep() {
    .v-expansion-panel {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20) !important;

      &-title {
        height: 3.5rem;

        &:hover {
          background-color: var(--grayscale-color-5) !important;
        }
      }

      &-text__wrapper {
        padding: 0;
      }
    }

    .simple-card__body-text {
      padding: 0 !important;
    }
  }
}

.v-expansion-panels--variant-accordion {
  & > :not(:first-child):not(:last-child),
  & > :first-child:not(:last-child),
  & > :last-child:not(:first-child) {
    border-radius: 0.5rem !important;
  }
}

.filter-75k-chip {
  font-family: $font-family-roboto-condensed;
  font-weight: bold;
  color: var(--grayscale-color-1);
  margin-left: 0.5rem;
}
</style>
