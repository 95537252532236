<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--name" data-test="COB-nameStep">
    <bubble-card>
      <template #card-title>
        <h5 data-test="consumerNameHeaderLabel">
          Name
        </h5>
      </template>

      <v-form @submit.prevent="sendCustomerData">
        <text-input
          v-model="firstName"
          label="First Name"
          required
          @keydown="disableSpecialCharacters" />

        <text-input
          v-model="middleName"
          label="Middle Name (If Applicable)"
          @keydown="disableSpecialCharacters" />

        <text-input
          v-model="lastName"
          label="Last Name"
          required
          @keydown="disableSpecialCharacters" />

        <custom-button
          :disabled="isDisabled"
          data-test="continueBtn"
          type="submit">
          Continue
        </custom-button>
      </v-form>

      <div class="standard-consent-and-input-notation-text" data-test="importantInfoLabel">
        Important Information About Procedures for Opening a New Account: To help the government
        fight the funding of terrorism and money-laundering activities, Federal law requires all
        financial institutions, including a loan or finance company,
        to obtain, verify and record information that identifies each person who
        opens an account. What this means for you: When you open an account, we will ask
        for your name, address, date of birth and other information that will allow us to
        identify you. We may also ask to see your driver's license or other
        identifying documents.
      </div>
    </bubble-card>

    <complete-later-button />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import TextInput from '@/components/Inputs/Text.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { saveBasicInfo } from '@/api/consumer';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { computed, defineComponent, reactive } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'NameStep',
  components: {
    BubbleCard,
    CustomButton,
    TextInput,
    CompleteLaterButton,
  },
  mixins: [NavigatesStepsMixin, IdleTimeoutMixin, HoneypotTrackMixin],
  setup() {
    const SPECIAL_CHARACTERS = '!?%#$%*&$';

    const stepData = reactive<LoanApplyDataInterface>({
      first_name: '',
      middle_name: '',
      last_name: '',
    });

    function disableSpecialCharacters(event: KeyboardEvent) {
      if (SPECIAL_CHARACTERS.includes(event.key)) {
        event.preventDefault();
      }
    }

    function removeSpecialCharactersOnPaste(val: string) {
      const regex = new RegExp(`[${SPECIAL_CHARACTERS}]`, 'g');
      return val.replace(regex, '');
    }

    const useDisableSpecialCharactersInput = (property: 'first_name' | 'middle_name' | 'last_name') => computed({
      get() {
        return stepData[property] || '';
      },
      set(val: string) {
        stepData[property] = removeSpecialCharactersOnPaste(val);
      },
    });

    const firstName = useDisableSpecialCharactersInput('first_name');
    const middleName = useDisableSpecialCharactersInput('middle_name');
    const lastName = useDisableSpecialCharactersInput('last_name');

    return {
      stepData, firstName, middleName, lastName, disableSpecialCharacters,
    };
  },

  data() {
    return {
      disabled: false,
      ownStep: 4,
      hpEventName: 'Name Page Visit',
      hpStep: 4,
    };
  },

  computed: {
    isDisabled(): boolean {
      return !this.stepData.first_name || !this.stepData.last_name || this.disabled;
    },
  },
  mounted() {
    const { first_name, middle_name, last_name } = this.$store.getters['Consumer/getInvitation'];

    if (first_name) {
      this.stepData.first_name = first_name;
    }

    if (middle_name) {
      this.stepData.middle_name = middle_name;
    }

    if (last_name) {
      this.stepData.last_name = last_name;
    }

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    async sendCustomerData(): Promise<void> {
      this.disabled = true;
      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];

      try {
        await saveBasicInfo(appId, this.stepData);
        this.goToStep(this.ownStep + 1);
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
      }

      this.disabled = false;
    },

  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

p {
  margin-top: 3em;
  color: var(--grayscale-color-1);
  font-size: 10px;
}

.error {
  color: var(--red-color);
}

button {
  width: 100%;
}

.standard-consent-and-input-notation-text {
  color: var(--grayscale-color-1);
}
</style>
