<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none">
    <rect
      width="56"
      height="56"
      fill="white" />
    <!-- eslint-disable max-len, vue/max-len -->
    <path
      d="M32.5882 3L6.44464 3C5.1112 3 4 4.12002 4 5.46404L4 50.536C4 51.88 5.1112 53 6.44463 53L40.8411 53C42.1745 53 43.2857 51.88 43.2857 50.536V46.9286M32.5882 3L43.2857 13.7327M32.5882 3L32.5882 10.8755C32.5882 12.4535 33.8674 13.7327 35.4453 13.7327L43.2857 13.7327M43.2857 13.7327V24.7857M23.2857 17.2857H31.8571M23.2857 28H34.3571M23.2857 38.7143H31.1429M43.5238 39.0714C44.2381 39.0714 45.4286 38.8036 45.4286 37.4643C45.4286 36.125 44.2381 35.8571 43.5238 35.8571C42.8095 35.8571 41.8571 35.5893 41.8571 34.25C41.8571 33.3115 42.5536 32.6429 43.5238 32.6429M43.5238 39.0714C42.8095 39.0714 42.0952 38.5357 42.0952 38.5357M43.5238 39.0714V40.1429M44.9524 33.1786C44.9524 33.1786 44.4762 32.6429 43.5238 32.6429M43.5238 32.6429V31.5714M11.8571 13.7143H18.2857V20.1429H11.8571V13.7143ZM11.8571 24.4286H18.2857V30.8571H11.8571V24.4286ZM11.8571 35.1429H18.2857V41.5714H11.8571V35.1429ZM51.551 35.7041C51.551 40.1562 47.9419 43.7653 43.4898 43.7653C39.0377 43.7653 35.4286 40.1562 35.4286 35.7041C35.4286 31.252 39.0377 27.6429 43.4898 27.6429C47.9419 27.6429 51.551 31.252 51.551 35.7041Z"
      :stroke="props.stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <!-- eslint-enable max-len, vue/max-len -->
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  stroke: {
    type: String,
    default: 'var(--grayscale-color-1)',
  },
});
</script>
