import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    otpEnabled(): string {
      return this.$store.getters['Otp/getOtpEnabled'];
    },

    phoneResendEnabled(): boolean {
      return this.$store.getters['Otp/getPhoneResendEnabled'];
    },

    otpRegisterSessionToken(): string {
      return this.$store.getters['Otp/getOtpRegisterSessionToken'];
    },
  },
});
