import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56aed210"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_thinkific_link = _resolveComponent("thinkific-link")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: _normalizeClass(["fs-unmask header-link-container", { 'navigation-align': _ctx.isUserAdmin }]),
    "no-gutters": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.merchantHeaderItems, (item, index) => {
        return (_openBlock(), _createBlock(_component_custom_button, {
          key: index,
          class: "header-link",
          disabled: !item.to,
          "data-test": _ctx.getDataTestAttribute(item.to),
          to: _ctx.getRouteData(item.to),
          variant: "link"
        }, {
          default: _withCtx(() => [
            (item.name === 'Admin' && _ctx.cannotTransact)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_badge, {
                    location: "left",
                    class: "warning-badge",
                    color: "var(--error-color)",
                    icon: "mdi-exclamation-thick"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : (item.name === 'Rate Sheet' && _ctx.hasRSOptimizationSuggestions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_badge, {
                      location: "left",
                      class: "warning-badge rate-sheet-badge",
                      color: "var(--error-color)",
                      icon: "mdi-exclamation-thick"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.name), 1))
          ]),
          _: 2
        }, 1032, ["disabled", "data-test", "to"]))
      }), 128)),
      _createVNode(_component_thinkific_link, { class: "header-link" })
    ]),
    _: 1
  }, 8, ["class"]))
}