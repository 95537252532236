enum InvitationApplicationStatusDetails {
  PRE_WELCOME_SCREEN = 'Pre-Welcome Screen',
  POST_OTP_PRE_NAME = 'Post OTP Pre-Name',
  POST_NAME_PRE_ADDRESS = 'Post Name Pre-Address',
  POST_ADDRESS_PRE_SENSITIVES = 'Post Address Pre-Sensitives',
  POST_SENSITIVES_PRE_EMPLOYMENT_INFORMATION = 'Post Sensitives Pre Employment Information',
  POST_EMPLOYMENT_INFORMATION_PRE_INCOME = 'Post Employment Information Pre Income',
  POST_EMPLOYMENT_INFORMATION_PRE_DUPE_CHECK = 'Post Employment Information Pre-Dupe Check',
  POST_INCOME_INFORMATION_PRE_DUPE_CHECK = 'Post Income Information Pre-Dupe Check',
  POST_FRAUD_PRE_CREDIT_EVALUATION = 'Post Fraud Pre-Credit Evaluation',
  POST_OFFER_SELECTION_PRE_ID_CAPTURE = 'Post Offer Selection Pre-ID-Capture',
  POST_ID_CAPTURE_PRE_HARD_PULL = 'Post ID Capture Pre-Hard-Pull',
  POST_HARD_PULL_APPROVED_PRE_ACCEPTED = 'Post Hard-Pull Approved Pre-Accept',
  SOFT_PULL_FROZEN_CREDIT_FILE = 'Soft Pull Frozen Credit File',
  HARD_PULL_FROZEN_CREDIT_FILE = 'Hard Pull Frozen Credit File',
}

export default InvitationApplicationStatusDetails;
