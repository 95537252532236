<template>
  <div class="offer-code-table">
    <v-card>
      <v-card-title class="offer-code-table--title">
        <span class="body-text-large">{{ name }}</span>
        <header-actions
          :active="is_active"
          @toggle:active="updateActive"
          @click:editProducts="showEditProductsDialog = true"
          @click:editName="showEditNameDialog = true"
          @click:delete="showDeleteDialog = true" />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="rows"
          :headers="headers"
          :row-props="getRowProps"
          :mobile="isMobile"
          hide-default-footer>
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:header.product_name="{ column }">
            <header-with-tooltip
              :title="column.title"
              :align="column.align"
              :tooltip="column.tooltip"
              @click="activeTooltip = column" />
          </template>
          <template v-slot:header.full_term="{ column }">
            <header-with-tooltip
              :title="column.title"
              :align="column.align"
              :tooltip="column.tooltip"
              @click="activeTooltip = column" />
          </template>
          <template v-slot:header.apr="{ column }">
            <header-with-tooltip
              :title="column.title"
              :align="column.align"
              :tooltip="column.tooltip"
              @click="activeTooltip = column" />
          </template>
          <template v-slot:header.merchant_fee="{ column }">
            <header-with-tooltip
              :title="column.title"
              :align="column.align"
              :tooltip="column.tooltip"
              @click="activeTooltip = column" />
          </template>
          <template v-slot:item.product_attributes="{ item }">
            <div class="product-attributes">
              <FallbackTooltip v-if="item.has_fallback" />
              <SeventyFiveKTooltip v-if="is75kProduct(item)" />
              <InfoTriangle v-if="item.has_optimization" fill="var(--error-color)" />
            </div>
          </template>
          <template v-slot:item.customer_preferences="{ item }">
            <preferences class="text-center" :customer-preferences="item.customer_preferences" />
          </template>
          <!-- eslint-enable -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <custom-dialog
      v-if="activeTooltip"
      :dialog="true"
      :show-actions="false"
      :title="activeTooltip.text"
      @cancel="activeTooltip = null">
      <template #body>
        {{ activeTooltip.tooltip }}
      </template>
    </custom-dialog>

    <products-form-dialog
      v-if="showEditProductsDialog"
      editing
      :name="name"
      :rate-sheets="offerRateSheet"
      @close="showEditProductsDialog = false"
      @save="updateSelection" />

    <name-form-dialog
      v-if="showEditNameDialog"
      editing
      :existing-name="name"
      @close="showEditNameDialog = false"
      @save="updateName" />

    <custom-dialog
      v-if="showDeleteDialog"
      content-class="delete-offer-code-dialog"
      :dialog="true"
      title="Please Confirm"
      width="21.25rem"
      @cancel="showDeleteDialog = false">
      <template #body>
        <span>Would you like to delete offer code "{{ name }}"?</span>
      </template>
      <template #actions>
        <custom-button size="small" @click="showDeleteDialog = false">
          Cancel
        </custom-button>
        <custom-button size="small" @click="deleteCode">
          Delete
        </custom-button>
      </template>
    </custom-dialog>
    <alert-modal
      type="error"
      :dialog="showErrorAlert"
      :on-close="hideErrorAlert">
      We’re sorry. An error occurred while trying to perform this function. Please try again later.
    </alert-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { deleteOfferCode, updateOfferCode } from '@/api/ratesheets';
import cloneDeep from 'lodash/cloneDeep';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomDialog from '@/components/Dialogs/index.vue';
import Preferences from '@/components/RateSheetOptions/DataTable/CustomerPreferences.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import HeaderWithTooltip from '@/components/Tables/TableHeaderWithTooltip.vue';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import FallbackTooltip from '@/components/Merchant/Portal/RateSheets/FallbackTooltip.vue';
import SeventyFiveKTooltip from '@/components/Merchant/Portal/RateSheets/SeventyFiveKTooltip.vue';
import InfoTriangle from '@/components/Icons/InfoTriangle.vue';
import { useMerchantRateSheet } from '@/composables/useMerchantRateSheet';
import AlertMixin from '../AlertMixin';
import ProductsFormDialog from '../Forms/ProductsDialog.vue';
import NameFormDialog from '../Forms/NameDialog.vue';
import HeaderActions from './HeaderActions.vue';

export default defineComponent({
  name: 'OfferCodeTable',
  components: {
    AlertModal,
    CustomButton,
    CustomDialog,
    Preferences,
    ProductsFormDialog,
    HeaderWithTooltip,
    HeaderActions,
    NameFormDialog,
    InfoTriangle,
    FallbackTooltip,
    SeventyFiveKTooltip,
  },
  mixins: [AlertMixin],
  props: {
    rate_sheet_merchant_product_prices: { type: Array<any>, default: () => [] },
    id: { type: Number, required: true },
    name: { type: String, required: true },
    is_active: { type: Boolean, required: true },
  },
  setup() {
    const { headers, is75kProduct } = useMerchantRateSheet(false);
    return { headers, is75kProduct };
  },
  data() {
    return {
      activeTooltip: null as { text: string; tooltip: string } | null,
      showDeleteDialog: false,
      showEditProductsDialog: false,
      showEditNameDialog: false,
      showErrorAlert: false,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
    rows() {
      const suggestions = this.$store.getters[
        'MerchantPortal/getOfferCodeSuggestionsProductNames'
      ]?.[this.id] || new Set();
      return this.rate_sheet_merchant_product_prices.map((it: any) => {
        return {
          id: it.id,
          short_description: it.product_term_structure.short_description,
          product_name: it.product_term_structure.product_name,
          full_term: it.product_term_structure.term_range,
          apr: it.product_term_structure.apr_range,
          merchant_fee: `${(Number(it.merchant_fee) * 100).toFixed(2)}%`,
          customer_preferences: it.product_term_structure.customer_preferences,
          has_optimization: suggestions.has(it.product_term_structure.product_name),
          has_fallback: it.has_fallback,
          max_credit_limit: it.max_credit_limit,
        };
      });
    },
    offerRateSheet(): Array<RateSheetData> {
      const rateSheets = this.$store.getters['MerchantPortal/getRateSheets'];
      const selectedIds = this.rate_sheet_merchant_product_prices.map((it: any) => it.id);
      return cloneDeep(rateSheets).map((it: RateSheetData) => {
        it.is_selected = selectedIds.includes(it.rate_sheet_merchant_product_price_id);
        return it;
      });
    },
  },
  methods: {
    getSelectedIds(): Array<number> {
      const selectedIds: Array<number> = [];
      this.offerRateSheet.forEach((it: RateSheetData) => {
        if (it.is_selected) selectedIds.push(it.rate_sheet_merchant_product_price_id);
      });
      return selectedIds;
    },
    async patchOfferCode(payload: any, deactivating = false) {
      const merchantUuid = this.$store.getters['Auth/getMerchantUuid'];
      const { status } = await updateOfferCode(merchantUuid, this.id, payload);
      if (status === 200) {
        const emit = deactivating ? 'deactivated' : 'updated';
        this.$emit(emit);
        this.showEditProductsDialog = false;
        this.showEditNameDialog = false;
        if (deactivating) this.addGlobalAlert(true, 'deactivate', this.name);
      } else {
        this.showErrorAlert = true;
      }
    },
    updateSelection() {
      const selectedIds = this.getSelectedIds();
      const payload = { rate_sheet_merchant_product_prices: selectedIds };
      this.patchOfferCode(payload);
    },
    updateName(name: string) {
      this.patchOfferCode({ name });
    },
    updateActive() {
      const previousState = this.is_active;
      this.patchOfferCode({ is_active: !previousState }, previousState);
    },
    async deleteCode() {
      const merchantUuid = this.$store.getters['Auth/getMerchantUuid'];
      const { status } = await deleteOfferCode(merchantUuid, this.id);
      if (status === 204) {
        this.$emit('deleted', this.id);
        this.showDeleteDialog = false;
      }
      this.addGlobalAlert(status === 204, 'delete', this.name);
    },
    hideErrorAlert() {
      this.showErrorAlert = false;
    },
    getRowProps({ item }: { item: any }) {
      return {
        class: {
          'has-optimization': item.has_optimization,
        },
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table-default";
@import "@/assets/scss/variables/font-variables";
@import "@/assets/scss/mixins/rate-sheet-table";

.offer-code-table {
  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 1rem;
    min-height: 3rem;
    background-color: var(--grayscale-color-5);

    .body-text-large {
      margin-right: 1rem;
      text-align: left;
      color: var(--grayscale-color-1);
      font-weight: bold;
      line-height: 1.25rem;
    }
  }

  &--body {
    color: var(--grayscale-color-1);
  }

  .v-card-text {
    padding: 0;

    :deep() {
      .v-table {
        th div {
          font-family: $font-family-roboto-condensed;
        }

        th, td {
          border-bottom: none !important;
        }

        tr {
          &.has-optimization {
            color: var(--error-color);
          }

          .product-attributes {
            @include product-attributes;
          }
        }

        .v-data-table__tr--mobile {
          box-shadow: inset 0px -1px 0px var(--grayscale-color-3);

          .v-data-table__td-title > div {
            justify-content: flex-start !important;
          }

          .v-data-table__td-value > div {
            justify-content: flex-end !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.delete-offer-code-dialog .dialog-wrap {
  .dialog-body-wrap,
  .buttons-wrap {
    padding: 1rem;
  }
}
</style>
