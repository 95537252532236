import { ConsumerInvitationInterface } from '@/interfaces/consumer/ConsumerInvitationInterface';
import get from 'lodash/get';
import InvitationApplicationStatusDetails from '@/enums/Consumer/InvitationApplicationStatusDetails';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import FullStoryLogging from '@/logging/FullStory';
import ConsumerHumanReadableApplicationId
  from '@/mixins/Consumer/LoanApply/ConsumerHumanReadableApplicationId';
import { defineComponent } from 'vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import SocureDocVStatuses from '@/enums/SocureDocVStatuses';

export default defineComponent({
  name: 'ReEntryMixin',
  mixins: [
    FeatureFlagsMixin,
    ConsumerHumanReadableApplicationId,
  ],
  computed: {
    invitation(): ConsumerInvitationInterface {
      return this.$store.getters['Consumer/getInvitation'];
    },
    appStatusDetail(): any {
      return get(this.$store.getters['Consumer/getInvitation'], 'app_status_detail');
    },
    ReEntryStep(): number {
      return this.$store.getters['Consumer/getLoanApplyWizardReEntryStep'];
    },
    isReEntry(): boolean {
      return this.$store.getters['Consumer/getLoanApplyIsReEntry'];
    },
  },
  methods: {
    setupReEntryStep() {
      let step;

      const statusesToCheck = [
        SocureDocVStatuses.WAITING_FOR_USER_REDIRECT,
        SocureDocVStatuses.OPENED,
        SocureDocVStatuses.DOC_FRONT_UPLOADED,
        SocureDocVStatuses.DOC_BACK_UPLOADED,
        SocureDocVStatuses.SELFIE_UPLOADED,
        SocureDocVStatuses.DOCS_UPLOADED,
        SocureDocVStatuses.DOCV_EXPIRED,
        SocureDocVStatuses.RESUBMIT,
      ];

      const isRelevantDocVStatus = statusesToCheck.includes(this.appStatusDetail);

      switch (this.appStatusDetail) {
        case InvitationApplicationStatusDetails.POST_OTP_PRE_NAME:
          step = 4;
          break;
        case InvitationApplicationStatusDetails.POST_NAME_PRE_ADDRESS:
          step = 5;
          break;
        case InvitationApplicationStatusDetails.POST_ADDRESS_PRE_SENSITIVES:
          step = 6;
          break;
        case InvitationApplicationStatusDetails.POST_SENSITIVES_PRE_EMPLOYMENT_INFORMATION:
          step = 7;
          break;
        case InvitationApplicationStatusDetails.POST_EMPLOYMENT_INFORMATION_PRE_INCOME:
          step = 8;
          break;
        case InvitationApplicationStatusDetails.POST_INCOME_INFORMATION_PRE_DUPE_CHECK:
        case InvitationApplicationStatusDetails.POST_EMPLOYMENT_INFORMATION_PRE_DUPE_CHECK:
          step = this.isFeatureEnabled(FeatureFlagsConstants.SKIP_DOCV) ? 10 : 9;
          break;
        case InvitationApplicationStatusDetails.POST_FRAUD_PRE_CREDIT_EVALUATION: {
          const decision = this.$store.getters['Consumer/getInvitation']?.reentry_pull_decision;
          const {
            soft_pull_approved_for_less, soft_pull_accepted,
            hard_pull_approved_for_less, hard_pull_accepted,
          } = decision || {};

          const softApprovedLessNotAccepted = soft_pull_approved_for_less === true
            && !soft_pull_accepted;
          const hardApprovedLessNotAccepted = hard_pull_approved_for_less === true
            && !hard_pull_accepted;
          if (softApprovedLessNotAccepted || hardApprovedLessNotAccepted) {
            this.$store.dispatch('Consumer/setShowDisclaimerPage', true);
            step = 10;
          } else {
            step = 11;
            break;
          }
          break;
        }
        case InvitationApplicationStatusDetails.SOFT_PULL_FROZEN_CREDIT_FILE:
        case SocureDocVStatuses.VERIFICATION_COMPLETE:
        case SocureDocVStatuses.SESSION_COMPLETE:
        case SocureDocVStatuses.DOCV_SKIPPED:
          step = 10;
          break;
        case InvitationApplicationStatusDetails.HARD_PULL_FROZEN_CREDIT_FILE:
          step = this.isFeatureEnabled(FeatureFlagsConstants.CAPTURE_ID) ? 12 : 11;
          break;
        case InvitationApplicationStatusDetails.POST_OFFER_SELECTION_PRE_ID_CAPTURE:
          step = 12;
          break;
        case InvitationApplicationStatusDetails.POST_ID_CAPTURE_PRE_HARD_PULL:
          step = 13;
          break;
        case InvitationApplicationStatusDetails.POST_HARD_PULL_APPROVED_PRE_ACCEPTED:
          step = 14;
          break;
        default:
          if (isRelevantDocVStatus) {
            step = 9;
            break;
          }
          // Not a re entry or we do not have app status detailed mapped.
          return;
      }

      this.setReEntryStep(step);
    },
    setReEntryStep(step: number): void {
      this.$store.commit(
        'Consumer/setLoanApplyWizardReEntryStep',
        step,
      );

      const firstName = this.$store.getters['Consumer/getLoanApplyWizardData'].first_name;
      const lastName = this.$store.getters['Consumer/getLoanApplyWizardData'].last_name;
      FullStoryLogging.identify(this.$store.getters['Consumer/getInvitation'].uuid, {
        displayName: firstName ? `${firstName} ${lastName}` : undefined,
        email: this.$store.getters['Consumer/getInvitation'].email,
        merchantUuid: this.$store.getters['Consumer/getMerchantId'],
        invitationId: this.consumerHumanReadableApplicationIdLabel,
      });
    },
    /**
     * Should be called after completing any work related to re-entry
     * to prevent following steps to do reentry work when it was already completed
     */
    completeReEntry() {
      this.$store.commit('Consumer/completeReEntry');
    },
  },
});
