<template>
  <div v-if="canShowCompleteLater" class="complete-later-wrapper">
    <custom-button
      data-test="ctltrBtn"
      variant="secondary"
      :disabled="completeLaterButtonDisable"
      @click="showModal">
      <span class="mdi mdi-timer" />
      Complete this later
    </custom-button>

    <complete-this-later-dialog
      :dialog="show"
      :on-cta="ctaSubmit"
      :on-cancel="hideModal" />
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CompleteThisLaterDialog
  from '@/components/Dialogs/CompleteThisLaterDialog.vue';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import GetProcessing from '@/mixins/GetProcessing';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompleteLaterButton',

  components: {
    CustomButton,
    CompleteThisLaterDialog,
  },

  mixins: [
    IdleTimeoutMixin,
    GetProcessing,
  ],

  data() {
    return {
      ctaClicked: false,
      show: false,
    };
  },

  computed: {
    canShowCompleteLater(): boolean {
      return !this.processing && this.$store.getters['Consumer/canShowCompleteLater'];
    },

    completeLaterButtonDisable(): boolean {
      return this.show && this.ctaClicked;
    },
  },

  watch: {
    showIdleTimeoutModal: {
      immediate: true,
      handler(newVal: boolean) {
        if (newVal && !this.ctaClicked) {
          this.showModal();
        }
      },
    },
  },

  mounted() {
    this.restartIdleTimeout();
  },

  methods: {
    showModal(): void {
      this.show = true;
    },

    hideModal(): void {
      this.show = false;
      this.restartIdleTimeout();
    },

    async ctaSubmit() {
      this.$emit('completeLaterClicked', true);
      await this.dispatchCompleteThisLater();
      this.ctaClicked = true;
      this.$store.dispatch('Consumer/completeLaterClicked', true);
    },

    async dispatchCompleteThisLater(): Promise<void> {
      this.clearIdleTimeout();
      await this.$store.dispatch('Consumer/reEntry');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/media_queries";

.complete-later-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 29.25rem;
  margin: 0 auto;
  margin-top: 3rem;
  text-align: right;

  @include tablet {
    max-width: 26.25rem;
  }
}
</style>
