import {
  AcceptOptimizationSuggestionPayload,
  RateSheetOptimizationSuggestion,
} from '@/interfaces/rates/RateSheetOptimization';
import { OfferCode } from '@/interfaces/merchantPortal/OfferCode';
import { BASE_PATH, instance, requestErrorHandler } from './index';
import { Response } from './types';

export function createOfferCode(merchantUuid: string, payload: any): Response<any> {
  return instance.post(getOfferCodePath(merchantUuid), payload);
}

export function getOfferCodes(merchantUuid: string, active?: boolean): Response<OfferCode[]> {
  let path = getOfferCodePath(merchantUuid);
  if (active) path = `${path}?active=true`;
  return instance.get(path).catch(requestErrorHandler);
}

export function updateOfferCode(merchantUuid: string, offerCodeId: string | number, payload: any): Response<any> {
  const path = getOfferCodePath(merchantUuid);
  return instance.patch(`${path}${offerCodeId}/`, payload).catch(requestErrorHandler);
}

export function deleteOfferCode(merchantUuid: string, offerCodeId: string | number): Response<any> {
  const path = getOfferCodePath(merchantUuid);
  return instance.delete(`${path}${offerCodeId}/`).catch(requestErrorHandler);
}

function getOfferCodePath(merchantUuid: string) {
  return `${BASE_PATH}/merchant/${merchantUuid}/offer-code/`;
}

export function getRateSheetProducts(merchantUuid: string): Response<any> {
  return instance.get(`${BASE_PATH}/merchant/${merchantUuid}/rates/`);
}

export function getRateSheetOptimizationSuggestions(
  merchantUuid: string,
): Response<RateSheetOptimizationSuggestion[]> {
  return instance.get(`${BASE_PATH}/merchant/${merchantUuid}/rates/optimization/`);
}

export function acceptRateSheetOptimizationSuggestions(
  merchantUuid: string,
  payload: AcceptOptimizationSuggestionPayload,
): Response<any> {
  return instance.post(`${BASE_PATH}/merchant/${merchantUuid}/rates/optimization/`, payload);
}
