import { computed } from 'vue';
import FLAGS from '@/constants/FeatureFlagsConstants';
import { useFeatureFlag } from './useFeatureFlag';

export const useChargeRequestLabel = () => {
  const { isFeatureEnabled } = useFeatureFlag();
  const hasFeatureFlag = computed(() => isFeatureEnabled(FLAGS.ENABLE_STAGED_FUNDING_RISK_TIERING));
  const chargeRequestLabel = computed(() => `${hasFeatureFlag.value ? 'Charge' : 'Payment'} Request`);

  return { chargeRequestLabel };
};
