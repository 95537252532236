<template>
  <div class="fs-unmask standard-wizard__step">
    <BubbleCard data-test="approvedForLessMsgShort">
      <template #card-title>
        <h5 data-test="approvedForLessHeaderLabel">
          Congratulations!
        </h5>
      </template>

      <div class="px-4">
        <p class="mb-5">
          While we are unable to offer you the full amount requested,
          we have pre-qualified you for a loan amount of
          <strong v-private>{{ approvedAmountLabel }}</strong>.
        </p>

        <p class="mb-5">
          <a class="cursor-pointer" @click="showDialog = true">
            <strong>You can read more about the decision here.</strong>
          </a>
        </p>

        <p class="mb-6">
          To continue with the new amount click the button below.
        </p>

        <CustomButton
          full-width
          :disabled="disableConfirmButton"
          @click="confirmConsent"
        >
          Continue
        </CustomButton>
      </div>
    </BubbleCard>

    <ApprovedForLessMsgDialog
      :show-dialog="showDialog"
      :approved-type="approvedType"
      @close="showDialog = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ApprovedForLessMsgDialog from
  '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/ApprovedForLessMsgDialog.vue';
import CreditEngineSoftPullApprovedAmountMixin from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullApprovedAmountMixin';
import CreditEngineHardPullApprovedAmountMixin from '@/mixins/Consumer/LoanApply/CreditEngineHardPullApprovedAmountMixin';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';

export default defineComponent({
  name: 'ApprovedForLessMsg',

  components: {
    BubbleCard,
    CustomButton,
    ApprovedForLessMsgDialog,
  },

  mixins: [
    CreditEngineSoftPullApprovedAmountMixin,
    CreditEngineHardPullApprovedAmountMixin,
  ],

  props: {
    onConsent: { type: Function, default: () => null },
    approvedType: {
      type: String,
      required: true,
      validator: (value: string) => ['soft', 'hard'].includes(value),
    },
  },

  data() {
    const consentTypes: Array<number> = [];
    return {
      consentTypes,
      showDialog: false,
      disableConfirmButton: false,
    };
  },

  computed: {
    approvedAmountLabel(): string {
      return this.approvedType === 'soft'
        ? this.approvedAmountSoftPullLabel
        : this.approvedAmountHardPullLabel;
    },
  },

  async mounted() {
    this.consentTypes = this.approvedType === 'soft'
      ? [ConsentTypesEnum.AAA_SOFT_PULL_APPROVE_FOR_LESS_ONSCREEN]
      : [ConsentTypesEnum.AAA_HARD_PULL_APPROVE_FOR_LESS_ONSCREEN];
    await this.presentConsent();
  },

  methods: {
    async confirmConsent(): Promise<void> {
      this.disableConfirmButton = true;
      const responseStatusConsent = await this.updateConsents();
      this.disableConfirmButton = false;

      if (responseStatusConsent === 201) {
        this.$props.onConsent();
      }
    },

    async updateConsents(): Promise<number> {
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      return patchAvailableConsents(this.consentTypes, appId, ConsentEntityTypes.CONSUMER);
    },

    async presentConsent(): Promise<void> {
      const consumer_application_uuid = this.$store.getters['Consumer/getConsumerApplicationId'];

      await postConsentTypes({
        consentTypes: this.consentTypes,
        consumer_application_uuid,
        entity: ConsentEntityTypes.CONSUMER,
      });
    },
  },
});
</script>
