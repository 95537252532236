<template>
  <div class="portal">
    <consumer-header class="portal__header" />

    <v-container class="portal__container">
      <v-row justify="center">
        <v-col lg="5" md="6">
          <consumer-loan-apply-wizard />
        </v-col>
      </v-row>
    </v-container>

    <consumer-footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ConsumerFooter from '@/components/Consumer/Footer.vue';
import ConsumerHeader from '@/components/Consumer/Header.vue';
import ConsumerLoanApplyWizard from '@/components/Consumer/LoanApplyWizard/index.vue';
import LocalStorageConstants from '@/constants/LocalStorageConstants';

export default defineComponent({
  name: 'LoanApply',

  components: {
    ConsumerHeader,
    ConsumerLoanApplyWizard,
    ConsumerFooter,
  },

  created() {
    localStorage.setItem(
      LocalStorageConstants.CURRENTLY_ONBOARDING,
      LocalStorageConstants.ONBOARDING.COB,
    );
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

@include portal-page-layout;
</style>
