<template>
  <div
    class="fs-unmask standard-wizard__step standard-wizard__step--payment-portal-setup"
    data-test="COB-paymentPortalSetupStep">
    <div v-if="!showMessage">
      <h6 class="loan-approve-congrats mb-6 mt-4">
        Congratulations On Your Loan Approval.
      </h6>

      <p class="loan-approve-congrats--message mb-6">
        Text message is how you will approve payments and transact with your merchant.
      </p>

      <v-card
        key="2"
        elevation="5"
        :ripple="false"
        class="loan-card">
        <v-card-title class="loan-card__title--peach">
          <h5>Make A Payment Portal Account!</h5>
        </v-card-title>
        <v-card-text>
          <ul class="pt-6 px-4">
            <li>Easily review your loan documents</li>
            <li>Manage communication preferences</li>
            <li>Make payments on your loan</li>
          </ul>
        </v-card-text>
        <v-card-actions class="loan-card__actions px-0 py-0">
          <div class="loan-card__button-wrapper pb-4 pt-6 px-4">
            <custom-button
              data-test="submitBtn"
              full-width
              @click="sendEmail">
              Email Me A Link!
            </custom-button>
          </div>
        </v-card-actions>
      </v-card>
    </div>

    <standard-wizard-message
      v-else
      title="Now Check Your Email!"
      :message="checkEmailMessage"
      data-test="checkEmailMessage">
      <template #body>
        <p>We’ve emailed you a link to log into the Payment Portal. </p><br>
        <p>
          The email was sent from {{ supportEmail }}. Please add this email
          to your contacts to receive our communications.
        </p>
      </template>
    </standard-wizard-message>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import SupportEmailMixin from '@/mixins/SupportEmailMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentPortalSetupStep',
  components: {
    CustomButton,
    StandardWizardMessage,
  },
  mixins: [SupportEmailMixin, HoneypotTrackMixin],
  data() {
    return {
      ownStep: 15,
      checkEmailMessage: '',
      showMessage: false,
      hpEventName: 'Loan Approval Page Visit',
      hpStep: 15,
    };
  },
  async mounted() {
    await this.$store.dispatch('Consumer/getInviteToConsumerPortal');
  },
  methods: {
    async sendEmail() {
      this.showMessage = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/mixins/media_queries";

.standard-wizard__step--payment-portal-setup {
  margin: -0.75rem auto 0;
  padding: 0 0.5rem;
  width: 100%;
  max-width: 29.25rem;

  p {
    font-size: 0.875rem;
  }
}

.loan-approve-congrats {
  font-size: 1.25rem;
  margin-top: 0;
  margin-top: 1.5rem;
}

.loan-approve-info {
  @include list-style-bullet;
  margin-top: 2rem;
  margin-bottom: 0;

  li {
    margin-bottom: .75rem;
  }
}

.loan-card {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 1.25rem;
  width: 100%;
  max-width: 29.25rem;
  min-height: 15.875rem;
  margin-bottom: 2.5rem;

  &__title {
    &--peach {
      background: #D5F0F4;
      justify-content: center;
      h5 {
        font-size: 1rem;
        color: var(--secondary-color);
        margin-top: 0;
        margin-bottom: 0 !important;
        animation: fadeIn 0.3s;
      }
    }
    &--teal {
      background: $momnt-soft-teal;
      h5 {
        color: $momnt-turquoise;
        margin-bottom: 0 !important;
        animation: fadeIn 0.3s;
      }
    }
  }

  &__button-wrapper {
    width: 100%;
    @include mobile {
      .custom-button {
        font-size: 0.875rem !important;
      }
    }
  }

  p, ul {
    margin-bottom: 0 !important;
    font-size: 1rem;
    animation: fadeIn 0.3s;
  }

  ul {
    @include list-style-bullet;
    margin-bottom: 1.5rem;

    li {
      padding-bottom: 0.2rem;
    }
  }
}

:deep(.standard-wizard__message) {
  .v-sheet {
    box-shadow: none !important;
  }

  h6 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>
