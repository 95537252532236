import { computed } from 'vue';
import { useStore } from 'vuex';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import {
  AcceptOptimizationSuggestionPayload,
  RateSheetOptimizationSuggestion,
} from '@/interfaces/rates/RateSheetOptimization';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { acceptRateSheetOptimizationSuggestions } from '@/api/ratesheets';

export const useMerchantRateSheet = (isOnboarding: boolean, hideProductAttributes?: boolean) => {
  function is75kProduct(product: RateSheetData): boolean {
    return product.max_credit_limit === 75000;
  }

  const headers = computed(() => {
    const commonHeaders: TableHeaderInterface[] = [
      {
        title: 'Description',
        value: 'short_description',
        sortable: false,
      },
      {
        title: 'Product ID',
        value: 'product_name',
        sortable: false,
        tooltip: `Use this unique identifier when discussing product
          options with merchant support or team members at your company.`,
      },
    ];

    if (!isOnboarding && !hideProductAttributes) {
      commonHeaders.push({
        title: 'Product Attributes',
        value: 'product_attributes',
        sortable: false,
        width: 180,
      });
    }

    commonHeaders.push(
      {
        title: 'Full Term',
        value: 'full_term',
        width: 120,
        sortable: false,
        align: 'end',
        tooltip: `Simply stated, the full term is the purchase window period plus
          payback period of the loan product represented in a total term length.`,
      },
      {
        title: 'APR',
        value: 'apr',
        width: 180,
        sortable: false,
        align: 'end',
        tooltip: `Annual percentage rate of interest applied to a loan product.
          APRs may vary depending on consumer credit score.`,
      },
      {
        title: 'Merchant Fee',
        value: 'merchant_fee',
        width: 140,
        sortable: false,
        align: 'end',
        tooltip: `This is your fee for offering the selected products below.
          Each product may carry a different fee.`,
      },
      {
        title: 'Customer Preferences',
        value: 'customer_preferences',
        sortable: false,
        align: 'center',
      },
    );

    return commonHeaders;
  });

  const store = useStore();

  function getSuggestions() {
    return store.getters['MerchantPortal/getRateSheetOptimizationSuggestions'] as RateSheetOptimizationSuggestion[];
  }

  const rateSheetSuggestionsProductNames = computed(() => {
    const suggestions = getSuggestions();

    const productNames = new Set<string>();
    for (const suggestion of suggestions) {
      if (suggestion.offer_code === null) {
        for (const product of suggestion.products) {
          productNames.add(product.name);
        }
      }
    }

    return productNames;
  });

  function hasOfferCodeOptimizationSuggestions() {
    const suggestions = getSuggestions();
    return suggestions.some(it => it.offer_code !== null);
  }

  function injectSuggestions(ratesheetProducts: RateSheetData[]) {
    const productNames = rateSheetSuggestionsProductNames.value;

    return ratesheetProducts.forEach(product => {
      product.has_optimization = productNames.has(product.product_name);
    });
  }

  async function acceptSuggestion(suggestion: RateSheetOptimizationSuggestion) {
    const merchantUuid = store.getters['MerchantPortal/getMerchantUuid'];
    const payload: AcceptOptimizationSuggestionPayload = {
      type: 'removal',
      product_ids: suggestion.products.map(product => product.id),
    };
    if (suggestion.offer_code) {
      payload.offer_code_id = suggestion.offer_code.id;
    }

    const response = await acceptRateSheetOptimizationSuggestions(merchantUuid, payload);
    if (response.status === 200) {
      // Refresh the suggestions
      await store.dispatch('MerchantPortal/getRSOptimizationSuggestions');
    }

    return response;
  }

  return {
    headers,
    injectSuggestions,
    hasOfferCodeOptimizationSuggestions,
    acceptSuggestion,
    rateSheetSuggestionsProductNames,
    is75kProduct,
  };
};
